import React, { useEffect, useRef, useState } from 'react'
import { getApiCall, postApiCall, postFormApiCall } from '../../utils/api';
import MaterialTable from 'material-table';
import tableIcons from '../component/MaterialIcon';
import { ThemeProvider, createTheme } from '@mui/material';
import { Delete, Edit } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Loader from '../../components/spinner/Loader';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

const chapterCodeList = [
    { "id": 1 },
    { "id": 2 },
    { "id": 3 },
    { "id": 4 },
    { "id": 5 },
    { "id": 6 },
    { "id": 7 },
    { "id": 8 },
    { "id": 9 },
    { "id": 10 },
    { "id": 11 },
    { "id": 12 },
    { "id": 13 },
    { "id": 14 },
    { "id": 15 },
    { "id": 16 },
    { "id": 17 },
    { "id": 18 },
    { "id": 19 },
    { "id": 20 },
    { "id": 21 },
    { "id": 22 },
    { "id": 23 },
    { "id": 24 },
    { "id": 25 },
    { "id": 26 },
    { "id": 27 },
    { "id": 28 },
    { "id": 29 },
    { "id": 30 },
    { "id": 31 },
    { "id": 32 },
    { "id": 33 },
    { "id": 34 },
    { "id": 35 },
    { "id": 36 },
    { "id": 37 },
    { "id": 38 },
    { "id": 39 },
    { "id": 40 },
    { "id": 41 },
    { "id": 42 },
    { "id": 43 },
    { "id": 44 },
    { "id": 45 },
    { "id": 46 },
    { "id": 47 },
    { "id": 48 },
    { "id": 49 },
    { "id": 50 },
]

const Chapter = () => {
    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [chapterID, setChapterID] = useState("")

    const [boardList, setBoardList] = useState([])
    const [languageList, setLanguageList] = useState([])
    const [classList, setClassList] = useState([])
    const [subjectList, setSubjectList] = useState([])

    const [boardName, setBoardName] = useState("")
    const [languageName, setLanguageName] = useState("")
    const [className, setClassName] = useState("")
    const [subjectName, setSubjectName] = useState("")
    const [unitName, setUnitName] = useState("")
    const [scriptName, setScriptName] = useState("")
    const [chapterCode, setChapterCode] = useState("")
    // const [imageFile, setImageFile] = useState(null)

    useEffect(() => {
        fetchData()
    }, [])

    const formRef = useRef();
    const fetchData = async () => {
        setLoading(true)

        const boardRes = await getApiCall("board/get/all")
        setBoardList(boardRes.data);

        const res = await getApiCall("chapter/get/all")
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
        setLoading(false)

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const formData = new FormData();

        formData.append('id', chapterID);
        formData.append('name', unitName);
        formData.append('in_script', scriptName);
        formData.append('chapter_code', chapterCode);
        formData.append('board_id', boardName);
        formData.append('language_id', languageName);
        formData.append('class_id', className);
        formData.append('subject_id', subjectName);
        // formData.append('image', imageFile);


        let res = {}
        if (chapterID === "") {
            res = await postFormApiCall("chapter/add", formData)
        } else {
            res = await postFormApiCall("chapter/update", formData)
            const filterResponse = await postApiCall("Chapter/get/byBoardMediumClassAndSubject", {
                "board_id": boardNameFilter,
                "language_id": languageNameFilter,
                "class_id": classNameFilter,
                "subject_id": subjectNameFilter,
            })
            setData(filterResponse.data);
            filterResponse.data.forEach((data, index) => { data.serial = index + 1; });
        }
        setLoading(false)
        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });
        setChapterID("")

        // setBoardName("")
        // setLanguageName("")
        // setClassName("")
        // setSubjectName("")

        setUnitName("")
        setScriptName("")
        setChapterCode("")

        fetchData()
    }


    const onBoardChange = async (val) => {
        setLoading(true)
        const boardRes = await postApiCall("language/get/byBoard", { "id": val })
        setLanguageList(boardRes.data);
        setLoading(false)
    }
    const onLanguageChange = async (val) => {
        setLoading(true)
        const langResponse = await postApiCall("class/get/byBoardAndMedium", { "board_id": boardName, "medium_id": val, })
        setClassList(langResponse.data);
        setLoading(false)
    }
    const onClassChange = async (val) => {
        setLoading(true)
        const classRes = await postApiCall("subject/get/byBoardClassAndMedium", { "board_id": boardName, "class_id": val, "medium_id": languageName, })
        setSubjectList(classRes.data);
        setLoading(false)
    }


    const columns = [
        { title: "Sl. no", field: "serial" },
        { title: "Code", field: "chapter_code" },
        { title: "Chapter", field: "name" },
        { title: "In Script", field: "in_script" },
    ]

    const [boardNameFilter, setBoardNameFilter] = useState("")
    const [languageNameFilter, setLanguageNameFilter] = useState("")
    const [classNameFilter, setClassNameFilter] = useState("")
    const [subjectNameFilter, setSubjectNameFilter] = useState("")

    const handleFilter = async (e) => {
        e.preventDefault();

        const formData = new FormData(formRef.current);
        const entries = Array.from(formData.entries());
        const formValues = entries.reduce(
            (prev, [name, value]) => ({
                ...prev,
                [name]: value,
            }),
            {}
        );
        setLoading(true)
        const res = await postApiCall("Chapter/get/byBoardMediumClassAndSubject", formValues)
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
        setLoading(false)
    }


    const handleEdit = async (rowData) => {
        setChapterID(rowData.id)

        setBoardName(rowData.board_id)
        setLanguageName(rowData.language_id)
        setClassName(rowData.class_id)
        setSubjectName(rowData.subject_id)

        setUnitName(rowData.name)
        setScriptName(rowData.in_script)
        setChapterCode(rowData.chapter_code)

        inputRef.current.focus();
        const input = document.getElementById('boardInput');
        input.classList.add('glow');
        input.focus();
        setTimeout(() => {
            input.classList.remove('glow');
        }, 1000);
    }


    return (
        <div>
            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
            <div className='content-wrapper'>

                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">Chapter</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Chapter</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* left column */}
                            <div className="col-md-12 mt-4">
                                {/* general form elements */}
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">{chapterID === "" ? "Add" : "Edit"} Chapter</h3>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{chapterID === "" ? "Select" : "Edit"} Board</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setBoardName(e.target.value)
                                                                setLanguageName("")
                                                                setSubjectName("")
                                                                setClassName("")
                                                                onBoardChange(e.target.value)
                                                            }}
                                                            required
                                                            value={boardName}
                                                            name='board_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {boardList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{chapterID === "" ? "Select" : "Edit"} Language</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setLanguageName(e.target.value)
                                                                setSubjectName("")
                                                                setClassName("")
                                                                onLanguageChange(e.target.value)
                                                            }}
                                                            required
                                                            value={languageName}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {languageList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{chapterID === "" ? "Select" : "Edit"} Class</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setClassName(e.target.value)
                                                                setSubjectName("")
                                                                onClassChange(e.target.value)
                                                            }}
                                                            required
                                                            value={className}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {classList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{chapterID === "" ? "Select" : "Edit"} Subject</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setSubjectName(e.target.value)
                                                            }}
                                                            required
                                                            value={subjectName}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {subjectList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 col-sm-12">
                                                    <h3>Add Unit</h3>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{chapterID === "" ? "Add" : "Edit"} Unit Name</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setUnitName(e.target.value)}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Unit Name"
                                                            value={unitName}
                                                            ref={inputRef}
                                                            name='unit'
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{chapterID === "" ? "Add" : "Edit"} Unit Name (In Script)</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setScriptName(e.target.value)}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Script Name"
                                                            value={scriptName}
                                                            ref={inputRef}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{chapterID === "" ? "Select" : "Edit"} Chapter Code</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => setChapterCode(e.target.value)}
                                                            required
                                                            value={chapterCode}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {chapterCodeList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.id}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-12 col-sm-12">
                                                <div class="form-group">
                                                    <label htmlFor="classInput">{chapterID === "" ? "Add" : "Edit"} Image</label>
                                                    <div class="input-group">
                                                        <input type="file" onChange={(e) => setImageFile(e.target.files[0])} required={chapterID === "" ? true : false} />
                                                    </div>
                                                </div>
                                            </div> */}

                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary">{chapterID === "" ? "Submit" : "Update"}</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (right) */}
                    </div>
                    {/* /.row */}
                </section >

                <section className="content mt-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 mt-4">

                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4 >Filter</h4>
                                    </div>

                                    <form onSubmit={handleFilter} ref={formRef}>
                                        <div className="card-body">
                                            <div className='row'>

                                                <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">Select Board</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setBoardNameFilter(e.target.value)
                                                                setBoardName(e.target.value)
                                                                setLanguageNameFilter("")
                                                                setSubjectNameFilter("")
                                                                setClassNameFilter("")
                                                                onBoardChange(e.target.value)
                                                            }}
                                                            required
                                                            value={boardNameFilter}
                                                            name='board_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {boardList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">Select Language</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setLanguageNameFilter(e.target.value)
                                                                setLanguageName(e.target.value)
                                                                setSubjectNameFilter("")
                                                                setClassNameFilter("")
                                                                onLanguageChange(e.target.value)
                                                            }}
                                                            required
                                                            value={languageNameFilter}
                                                            name='language_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {languageList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-2 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">Select Class</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setClassNameFilter(e.target.value)
                                                                setClassName(e.target.value)
                                                                setSubjectNameFilter("")
                                                                onClassChange(e.target.value)
                                                            }}
                                                            required
                                                            value={classNameFilter}
                                                            name='class_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {classList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">Select Subject</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setSubjectNameFilter(e.target.value)
                                                            }}
                                                            required
                                                            value={subjectNameFilter}
                                                            name='subject_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {subjectList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-1 col-sm-12 d-flex justify-content-center align-items-center">
                                                    <button type="submit" className="btn btn-primary">Filter</button>
                                                </div>

                                            </div>
                                        </div>

                                    </form>

                                    <ThemeProvider theme={defaultMaterialTheme}>
                                        <MaterialTable
                                            icons={tableIcons}
                                            title="Chapters"
                                            columns={columns}
                                            data={data}
                                            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                            options={{
                                                exportButton: true,
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    backgroundColor: 'rgb(0 123 255)',
                                                    color: '#FFF',
                                                    fontSize: 18
                                                },
                                                rowStyle: (rowData, index) => ({
                                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                                }),
                                            }}
                                            actions={[
                                                {
                                                    icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                                                    tooltip: 'Edit',
                                                    onClick: (event, rowData) => handleEdit(rowData),
                                                },
                                                // rowData => ({
                                                //     icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                                                //     tooltip: 'Delete',
                                                //     // onClick: (event, rowData) => handleDelete(rowData),
                                                // })
                                            ]}
                                        />
                                    </ThemeProvider>

                                </div>
                            </div>
                        </div>

                    </div>
                </section>


            </div >
        </div >
    )
}

export default Chapter