import React, { useEffect, useRef, useState } from 'react'
import { getApiCall, postApiCall, postFormApiCall } from '../../utils/api';
import MaterialTable from 'material-table';
import tableIcons from '../component/MaterialIcon';
import { ThemeProvider, createTheme } from '@mui/material';
import { Delete, Edit } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Loader from '../../components/spinner/Loader';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

const Language = () => {
    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [languageName, setLanguageName] = useState("")
    const [scriptName, setScriptName] = useState("")
    const [languageID, setLanguageID] = useState("")
    const [imageFile, setImageFile] = useState(null)
    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        setLoading(true)
        const res = await getApiCall("language/get/all")
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
        setLoading(false)
    }
    const imageFileInputRef = useRef(null);

    const handleSubmit = async () => {
        if (languageName === "")
            return Swal.fire({
                title: 'Error',
                text: 'Please enter a Language',
                icon: 'error'
            });
            setLoading(true)

        const formData = new FormData();

        formData.append('id', languageID);
        formData.append('name', languageName);
        formData.append('script', scriptName);
        formData.append('image', imageFile);

        let res = {}
        if (languageID === "") {
            res = await postFormApiCall("language/add", formData)
        } else {
            res = await postFormApiCall("language/update", formData)
        }
        setLoading(false)

        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });
        if (imageFileInputRef.current) {
            imageFileInputRef.current.value = '';
        }
        setImageFile(null)
        setLanguageName("")
        setScriptName("")
        setLanguageID("")
        fetchData()
    }

    const columns = [
        { title: "Sl. no", field: "serial" },
        { title: "Language", field: "name" },
        { title: "Script", field: "script" },
    ]

    const handleEdit = async (rowData) => {
        setLanguageName(rowData.name)
        setScriptName(rowData.script)
        setLanguageID(rowData.id)

        inputRef.current.focus();
        const input = document.getElementById('languageInput');
        input.classList.add('glow');
        input.focus();
        setTimeout(() => {
            input.classList.remove('glow');
        }, 1000);
    }

    const handleDelete = async (rowData) => {
        new Swal({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await postApiCall("language/delete", { "id": rowData.id })
                if (res.error === true) {
                    return Swal.fire({
                        title: 'Error',
                        text: res.message,
                        icon: 'error'
                    });
                }
                Swal.fire({
                    title: 'Successful',
                    text: res.message,
                    icon: 'success'
                });
                fetchData()
            }
        })

    }


    return (
        <div>
            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
            <div className='content-wrapper'>

                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">Language</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Language</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>


                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* left column */}
                            <div className="col-md-12 mt-4">
                                {/* general form elements */}
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">{languageID === "" ? "Add" : "Edit"} Language</h3>
                                    </div>
                                    {/* /.card-header */}
                                    {/* form start */}
                                    {/* <form> */}
                                    <div className="card-body">
                                        <div className="row">

                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="languageInput">{languageID === "" ? "Add" : "Edit"} Language</label>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => setLanguageName(e.target.value)}
                                                        className="form-control"
                                                        id="languageInput"
                                                        placeholder="Language"
                                                        value={languageName}
                                                        ref={inputRef}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="languageInput">{languageID === "" ? "Add" : "Edit"} Script</label>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => setScriptName(e.target.value)}
                                                        className="form-control"
                                                        id="languageInput"
                                                        placeholder="Script"
                                                        value={scriptName}
                                                        ref={inputRef}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-12 col-sm-12">
                                                <div class="form-group">
                                                    <label htmlFor="classInput">{languageID === "" ? "Add" : "Edit"} icon</label>
                                                    <div class="input-group">
                                                        <input type="file" accept="image/*" ref={imageFileInputRef} onChange={(e) => setImageFile(e.target.files[0])} required={languageID === "" ? true : false} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                    <div className="card-footer">
                                        <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary">{languageID === "" ? "Submit" : "Update"}</button>
                                    </div>
                                </div>
                                {/* /.card-body */}

                                {/* </form> */}
                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (right) */}
                    </div>
                    {/* /.row */}
                </section >



                <section className="content mt-4">
                    <div className="container-fluid">
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable
                                icons={tableIcons}
                                title="Languages"
                                columns={columns}
                                data={data}
                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                options={{
                                    exportButton: true,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        backgroundColor: 'rgb(0 123 255)',
                                        color: '#FFF',
                                        fontSize: 18
                                    },
                                    rowStyle: (rowData, index) => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                    }),
                                }}
                                actions={[
                                    rowData => ({
                                        icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                                        tooltip: 'Edit',
                                        onClick: (event, rowData) => handleEdit(rowData),
                                    }),
                                    // rowData => ({
                                    //     icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                                    //     tooltip: 'Delete',
                                    //     onClick: (event, rowData) => handleDelete(rowData),
                                    // })
                                ]}
                            />
                        </ThemeProvider>
                    </div>
                </section>

            </div>
        </div>
    )
}

export default Language