import React, { useEffect, useRef, useState } from 'react'
import { MAIN_URL, getApiCall, getApiCallForVideo, postApiCall, postFormApiCall } from '../../utils/api';
import MaterialTable from 'material-table';
import { ThemeProvider, createTheme } from '@mui/material';
import { Delete, Edit } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import tableIcons from '../component/MaterialIcon';
import moment from 'moment'
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { Modal } from '@material-ui/core';

const typeList = [
  { "id": 1, value: "Text" },
  { "id": 2, value: "Image" },
  { "id": 3, value: "Video" },
]

const Feeds = () => {

  const defaultMaterialTheme = createTheme();
  const [selectedRow, setSelectedRow] = useState(null);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState([])
  const [feedType, setFeedType] = useState("")

  const [feedText, setFeedText] = useState("")
  const imageFileInputRef = useRef(null);
  const [imageFile, setImageFile] = useState(null)
  const fileInputRef = useRef(null);
  const [videoFile, setVideoFile] = useState(null)

  const [feedId, setFeedId] = useState("")

  const [isTypeSelected, setIsTypeSelected] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])
  const fetchData = async () => {
    setLoading(true)
    const res = await getApiCall("feed/get/all")
    setData(res.data);
    res.data.forEach((data, index) => { data.serial = index + 1; });
    setLoading(false)

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const formData = new FormData();

    formData.append('id', feedId);
    formData.append('type', feedType);
    formData.append('text', feedText);
    formData.append('image', imageFile);
    formData.append('video', videoFile);

    let res = {}
    if (feedId === "") {
      res = await postFormApiCall("feed/add", formData)
    } else {
      res = await postFormApiCall("feed/update", formData)
    }
    setLoading(false)

    if (res.error === true) {
      return Swal.fire({
        title: 'Error',
        text: res.message,
        icon: 'error'
      });
    }
    Swal.fire({
      title: 'Successful',
      text: res.message,
      icon: 'success'
    });

    setFeedId("")

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    if (imageFileInputRef.current) {
      imageFileInputRef.current.value = '';
    }

    setIsTypeSelected(false)
    setImageFile(null)
    setVideoFile(null)
    setFeedText("")
    setFeedType("")
    fetchData()
  }

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setImageFile(event.target.files[0]);
    } else {
      alert("Something went wrong")
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const playVideo = async (event, id) => {
    event.preventDefault();
    setLoading(true)
    const res = await getApiCallForVideo(`feed/getById/${id}`)
    setLoading(false)
    const videoBlob = await res.blob();
    const videoUrl = URL.createObjectURL(videoBlob);
    setVideoUrl(videoUrl);
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
    setVideoUrl('');
  };

  const columns = [
    {
      title: "Sl. no", field: "serial",
      cellStyle: {
        width: "10%"
      }
    },
    {
      title: "Type", field: "Type",
      cellStyle: {
        width: "20%"
      },
      render: (rowData) => {
        return (
          <>
            {rowData.type === 1 ? "Text" : rowData.type === 2 ? "Image" : "Video"}
          </>
        )
      }
    },
    {
      title: "Data", field: "Data",
      render: (rowData) => {
        return (
          <>
            {rowData.type === 1 && rowData.text}
            {rowData.type === 2 && <img src={MAIN_URL + rowData.image_path} height={80} width={120} />}
            {rowData.type === 3 && <a href='' onClick={(e) => playVideo(e, rowData.id)}>PLAY</a>}
          </>
        )
      }
    },

  ]

  const handleEdit = async (rowData) => {
    setFeedId(rowData.id)
    setIsTypeSelected(true)
    setFeedType(rowData.type.toString())
    setFeedText(rowData.text)

    inputRef.current.focus();
    const input = document.getElementById('stateInput');
    input.classList.add('glow');
    input.focus();
    setTimeout(() => {
      input.classList.remove('glow');
    }, 1000);
  }


  return (
    <div>
      <CircleSpinnerOverlay
        loading={loading}
        overlayColor="rgba(0,153,255,0.2)"
      />


      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
          setVideoUrl('')
        }}
      >
        {/* <button onClick={closeModal}>Close</button> */}
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          {videoUrl && (
            <video controls width="500" height="300">
              <source src={videoUrl} type="video/mp4" />
            </video>
          )}
        </div>
      </Modal>

      <div className='content-wrapper'>

        <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h4 className="m-0">Feeds</h4>
              </div>{/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item active">Feeds</li>
                </ol>
              </div>{/* /.col */}
            </div>{/* /.row */}
          </div>{/* /.container-fluid */}
        </div>


        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12 mt-4">
                {/* general form elements */}
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">{feedId === "" ? "Add" : "Edit"} Feeds</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <form onSubmit={handleSubmit}>
                    <div className="card-body row">

                      <div className="col-md-4 col-sm-12">
                        <div class="form-group">
                          <label for="selectInput">{feedId === "" ? "Select" : "Edit"} Chapter Code</label>
                          <select
                            class="custom-select form-select rounded-0"
                            id="selectInput"
                            onChange={(e) => {
                              setImageFile(null)
                              setVideoFile(null)
                              setFeedText("")
                              setIsTypeSelected(true)
                              setFeedType(e.target.value)
                            }}
                            required
                            value={feedType}
                          >
                            <option value="" disabled selected>
                              Select an option
                            </option>
                            {typeList.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.value}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      {isTypeSelected && (
                        <>
                          {feedType === '1' &&
                            <div className="col-md-12 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="stateInput">{feedId === "" ? "Add" : "Edit"} Text</label>
                                <input
                                  type="text"
                                  onChange={(e) => setFeedText(e.target.value)}
                                  className="form-control"
                                  id="stateInput"
                                  placeholder="Add Link"
                                  value={feedText}
                                  ref={inputRef}

                                />
                              </div>
                            </div>}
                          {feedType === '2' &&
                            <div className="col-md-12 col-sm-12">
                              <div class="form-group">
                                <label htmlFor="classInput">{feedId === "" ? "Add" : "Edit"} Image</label>
                                <div class="input-group">
                                  <input type="file" accept="image/*" ref={imageFileInputRef} onChange={(e) => handleThumbnailChange(e)} required={feedId === "" ? true : false} />
                                </div>
                              </div>
                            </div>}
                          {feedType === '3' &&
                            <div className="col-md-12 col-sm-12">
                              <div class="form-group">
                                <label htmlFor="classInput">{feedId === "" ? "upload" : "Edit"} video</label>
                                <div class="input-group">
                                  <input type="file" accept="video/*" ref={fileInputRef} onChange={(e) => setVideoFile(e.target.files[0])} required={feedId === "" ? true : false} />
                                </div>
                              </div>
                            </div>
                          }
                        </>
                      )}




                      {/* <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="stateInput">{liveClassID === "" ? "Add" : "Edit"} Title</label>
                          <input
                            type="text"
                            onChange={(e) => setLiveClassName(e.target.value)}
                            className="form-control"
                            id="stateInput"
                            placeholder="Enter title"
                            value={liveClassName}
                            ref={inputRef}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="stateInput">{liveClassID === "" ? "Add" : "Edit"} Link</label>
                          <input
                            type="text"
                            onChange={(e) => setLiveClassLink(e.target.value)}
                            className="form-control"
                            id="stateInput"
                            placeholder="Add Link"
                            value={liveClassLink}
                            ref={inputRef}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="stateInput">{liveClassID === "" ? "Add" : "Edit"} Start Date and Time</label>
                          <input
                            type="datetime-local"
                            onChange={(e) => setLiveClassStarts(e.target.value)}
                            className="form-control"
                            value={liveClassStarts}
                            ref={inputRef}
                            min={new Date().toISOString().slice(0, -8)}
                          />

                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="stateInput">{liveClassID === "" ? "Add" : "Edit"} End Date and Time</label>
                          <input
                            type="datetime-local"
                            onChange={(e) => setLiveClassEnds(e.target.value)}
                            className="form-control"
                            value={liveClassEnds}
                            ref={inputRef}
                          />

                        </div>
                      </div> */}


                    </div>
                    {/* /.card-body */}
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">{feedId === "" ? "Submit" : "Update"}</button>
                    </div>
                  </form>
                </div>
                {/* /.card */}
              </div>
              {/*/.col (right) */}
            </div>
            {/* /.row */}
          </div>{/* /.container-fluid */}
        </section>


        <section className="content">
          <div className="container-fluid">
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                icons={tableIcons}
                title="Liva Classes List"
                columns={columns}
                data={data}
                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                options={{
                  exportButton: true,
                  actionsColumnIndex: -1,
                  headerStyle: {
                    backgroundColor: 'rgb(0 123 255)',
                    color: '#FFF',
                    fontSize: 18
                  },
                  rowStyle: (rowData, index) => ({
                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                  }),
                }}
                actions={[
                  {
                    icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                    tooltip: 'Edit',
                    onClick: (event, rowData) => handleEdit(rowData),
                  },
                  // rowData => ({
                  //     icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                  //     tooltip: 'Delete',
                  //     onClick: (event, rowData) => handleDelete(rowData),
                  // })
                ]}
              />
            </ThemeProvider>
          </div>
        </section>

      </div>
    </div>
  )
}

export default Feeds