import React, { useEffect, useRef, useState } from 'react'
import { MAIN_URL, getApiCall, postApiCall, postFormApiCall } from '../../utils/api';
import MaterialTable from 'material-table';
import tableIcons from '../component/MaterialIcon';
import { ThemeProvider, createTheme } from '@mui/material';
import { Delete, Edit } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

const EBook = () => {
  const defaultMaterialTheme = createTheme();
  const [selectedRow, setSelectedRow] = useState(null);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState([])
  const [ebookID, setEbookID] = useState("")

  const [boardList, setBoardList] = useState([])
  const [languageList, setLanguageList] = useState([])
  const [classList, setClassList] = useState([])
  const [subjectList, setSubjectList] = useState([])
  const [chapterList, setChapterList] = useState([])

  const [boardName, setBoardName] = useState("")
  const [languageName, setLanguageName] = useState("")
  const [className, setClassName] = useState("")
  const [subjectName, setSubjectName] = useState("")
  const [chapterName, setChapterName] = useState("")
  const [ebookTitle, setEbookTitle] = useState("")

  const [imageFile, setImageFile] = useState(null)
  const [ebookFile, setEBookFile] = useState(null)
  const [includeEbook, setIncludeEbook] = useState(true)

  useEffect(() => {
    fetchData()
  }, [])

  const formRef = useRef();
  const fetchData = async () => {
    setLoading(true)
    const boardRes = await getApiCall("board/get/all")
    setBoardList(boardRes.data);

    const res = await getApiCall("ebook/get/all")
    setData(res.data);
    res.data.forEach((data, index) => { data.serial = index + 1; });
    setLoading(false)

  }
  const fileInputRef = useRef(null);
  const imageFileInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const formData = new FormData();

    formData.append('id', ebookID);
    formData.append('title', ebookTitle);
    formData.append('board_id', boardName);
    formData.append('language_id', languageName);
    formData.append('class_id', className);
    formData.append('subject_id', subjectName);
    formData.append('chapter_id', chapterName);
    formData.append('is_ebook', includeEbook);
    formData.append('ebook_path', ebookFile);
    formData.append('thumbnail_path', imageFile);

    let res = {}
    if (ebookID === "") {
      res = await postFormApiCall("ebook/add", formData)
    } else {
      res = await postFormApiCall("ebook/update", formData)

      const filterResponse = await postApiCall("ebook/get/byBoardMediumAndClass", {
        "board_id": boardNameFilter,
        "language_id": languageNameFilter,
        "class_id": classNameFilter,
      })
      setData(filterResponse.data);
      filterResponse.data.forEach((data, index) => { data.serial = index + 1; });
    }
    setLoading(false)

    if (res.error === true) {
      return Swal.fire({
        title: 'Error',
        text: res.message,
        icon: 'error'
      });
    }
    Swal.fire({
      title: 'Successful',
      text: res.message,
      icon: 'success'
    });

    setEbookID("")

    // setBoardName("")
    // setLanguageName("")
    // setClassName("")

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    if (imageFileInputRef.current) {
      imageFileInputRef.current.value = '';
    }

    setEbookTitle("")
    setSubjectName("")
    setChapterName("")

    setImageFile(null)
    setEBookFile(null)

    setIncludeEbook(true)

    fetchData()
  }

  const onBoardChange = async (val) => {
    setLoading(true)
    const boardRes = await postApiCall("language/get/byBoard", { "id": val })
    setLanguageList(boardRes.data);
    setLoading(false)

  }
  const onLanguageChange = async (val) => {
    setLoading(true)
    const langResponse = await postApiCall("class/get/byBoardAndMedium", { "board_id": boardName, "medium_id": val, })
    setClassList(langResponse.data);
    setLoading(false)
  }
  const onClassChange = async (val) => {
    setLoading(true)
    const classRes = await postApiCall("subject/get/byBoardClassAndMedium", { "board_id": boardName, "class_id": val, "medium_id": languageName, })
    setSubjectList(classRes.data);
    setLoading(false)
   }
  const onSubjectChange = async (val) => {
    setLoading(true)
    const chapterRes = await postApiCall("chapter/get/name/byBoardMediumClassAndSubject", { "board_id": boardName, "class_id": className, "language_id": languageName, "subject_id": val })
    setChapterList(chapterRes.data);
    setLoading(false)
  }

  const columns = [
    { title: "Sl. no", field: "serial" },
    {
      title: "Image", field: "thumbnail_path",
      render: (rowData) => {
        return (
          <>
            <img src={MAIN_URL + rowData.thumbnail_path} height={70} width={70} /></>
        )
      }
    },
    // {
    //   title: "Title", field: "title",
    //   render: (rowData) => {
    //     return (
    //       <>
    //         {rowData.is_video === 0 ?
    //           <p>{rowData.title}</p>
    //           :
    //           <a href='' onClick={(e) => playVideo(e, rowData.id)}>{rowData.title}</a>}
    //       </>
    //     )
    //   }
    // },
    { title: "Subject", field: "subject_name" },
    { title: "Unit", field: "chapter_name" },
  ]

  const [boardNameFilter, setBoardNameFilter] = useState("")
  const [languageNameFilter, setLanguageNameFilter] = useState("")
  const [classNameFilter, setClassNameFilter] = useState("")

  const handleFilter = async (e) => {
    e.preventDefault();

    const formData = new FormData(formRef.current);
    const entries = Array.from(formData.entries());
    const formValues = entries.reduce(
      (prev, [name, value]) => ({
        ...prev,
        [name]: value,
      }),
      {}
    );

    const res = await postApiCall("ebook/get/byBoardMediumAndClass", formValues)
    setData(res.data);
    res.data.forEach((data, index) => { data.serial = index + 1; });

  }

  const handleEdit = async (rowData) => {
    setEbookID(rowData.id)

    setBoardName(rowData.board_id)
    setLanguageName(rowData.language_id)
    setClassName(rowData.class_id)

    setEbookTitle(rowData.title)
    setSubjectName(rowData.subject_id)

    setChapterName(rowData.chapter_id)

    inputRef.current.focus();
    const input = document.getElementById('boardInput');
    input.classList.add('glow');
    input.focus();
    setTimeout(() => {
      input.classList.remove('glow');
    }, 1000);
  }

  return (

    <div>
      <CircleSpinnerOverlay
        loading={loading}
        overlayColor="rgba(0,153,255,0.2)"
      />
      <div className='content-wrapper'>

        <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h4 className="m-0">E-Book</h4>
              </div>{/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item active">E-Book</li>
                </ol>
              </div>{/* /.col */}
            </div>{/* /.row */}
          </div>{/* /.container-fluid */}
        </div>


        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12 mt-4">
                {/* general form elements */}
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">{ebookID === "" ? "Add" : "Edit"} E-Book</h3>
                  </div>
                  <form onSubmit={handleSubmit} enctype="multipart/form-data">
                    <div className="card-body">
                      <div className="row">

                        <div className="col-md-4 col-sm-12">
                          <div class="form-group">
                            <label for="selectInput">{ebookID === "" ? "Select" : "Edit"} Board</label>
                            <select
                              class="custom-select form-select rounded-0"
                              id="selectInput"
                              onChange={(e) => {
                                setBoardName(e.target.value)
                                setLanguageName("")
                                setClassName("")
                                setSubjectName("")
                                setChapterName("")
                                onBoardChange(e.target.value)
                              }}
                              required
                              value={boardName}
                              name='board_id'
                            >
                              <option value="" disabled selected>
                                Select an option
                              </option>
                              {boardList.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4 col-sm-12">
                          <div class="form-group">
                            <label for="selectInput">{ebookID === "" ? "Select" : "Edit"} Language</label>
                            <select
                              class="custom-select form-select rounded-0"
                              id="selectInput"
                              onChange={(e) => {
                                setLanguageName(e.target.value)
                                setClassName("")
                                setSubjectName("")
                                setChapterName("")
                                onLanguageChange(e.target.value)
                              }}
                              required
                              value={languageName}
                              name='language_id'
                            >
                              <option value="" disabled selected>
                                Select an option
                              </option>
                              {languageList.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4 col-sm-12">
                          <div class="form-group">
                            <label for="selectInput">{ebookID === "" ? "Select" : "Edit"} Class</label>
                            <select
                              class="custom-select form-select rounded-0"
                              id="selectInput"
                              onChange={(e) => {
                                setClassName(e.target.value)
                                setSubjectName("")
                                setChapterName("")
                                onClassChange(e.target.value)
                              }}
                              required
                              value={className}
                              name='class_id'
                            >
                              <option value="" disabled selected>
                                Select an option
                              </option>
                              {classList.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <h3>Add E-Book</h3>
                        </div>

                        <div className="col-md-4 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="boardInput">{ebookID === "" ? "Add" : "Edit"} Title</label>
                            <input
                              type="text"
                              onChange={(e) => setEbookTitle(e.target.value)}
                              className="form-control"
                              id="boardInput"
                              placeholder="Enter title"
                              ref={inputRef}
                              value={ebookTitle}
                              name='title'
                              required
                            />
                          </div>
                        </div>

                        <div className="col-md-4 col-sm-12">
                          <div class="form-group">
                            <label for="selectInput">{ebookID === "" ? "Select" : "Edit"} Subject</label>
                            <select
                              class="custom-select form-select rounded-0"
                              id="selectInput"
                              onChange={(e) => {
                                setSubjectName(e.target.value)
                                setChapterName("")
                                onSubjectChange(e.target.value)
                              }}
                              required
                              value={subjectName}
                              name='subject_id'
                            >
                              <option value="" disabled selected>
                                Select an option
                              </option>
                              {subjectList.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4 col-sm-12">
                          <div class="form-group">
                            <label for="selectInput">{ebookID === "" ? "Select" : "Edit"} Chapter</label>
                            <select
                              class="custom-select form-select rounded-0"
                              id="selectInput"
                              onChange={(e) => {
                                setChapterName(e.target.value)
                              }}
                              required
                              value={chapterName}
                              name='chapter_id'
                            >
                              <option value="" disabled selected>
                                Select an option
                              </option>
                              {chapterList.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <label>
                            <input
                              type="checkbox"
                              checked={includeEbook}
                              onChange={(e) => setIncludeEbook(e.target.checked)}
                            />
                            <span className='active-status'>Include Ebook?</span>
                          </label>
                        </div>

                        {includeEbook && <div className="col-md-6 col-sm-12">
                          <div class="form-group">
                            <label htmlFor="classInput">{ebookID === "" ? "Add" : "Edit"} E-Book</label>
                            <div class="input-group">
                              <input type="file" ref={fileInputRef} accept=".epub" onChange={(e) => {
                                setEBookFile(e.target.files[0])
                              }} required={ebookID === "" ? true : false} />
                            </div>
                          </div>
                        </div>}

                        <div className="col-md-6 col-sm-12">
                          <div class="form-group">
                            <label htmlFor="classInput">{ebookID === "" ? "Add" : "Edit"} Thumbnail</label>
                            <div class="input-group">
                              <input type="file" ref={imageFileInputRef} accept="image/*" onChange={(e) => setImageFile(e.target.files[0])} required={ebookID === "" ? true : false} />
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">{ebookID === "" ? "Submit" : "Update"}</button>
                    </div>
                  </form>
                </div>

              </div>
              {/* /.card */}
            </div>
            {/*/.col (right) */}
          </div>
          {/* /.row */}
        </section >


        <section className="content mt-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 mt-4">

                <div className="card card-primary">
                  <div className="card-header">
                    <h4 >Filter</h4>
                  </div>

                  <form onSubmit={handleFilter} ref={formRef}>
                    <div className="card-body">
                      <div className='row'>

                        <div className="col-md-1 col-sm-12">
                        </div>

                        <div className="col-md-3 col-sm-12">
                          <div class="form-group">
                            <label for="selectInput">Select Board</label>
                            <select
                              class="custom-select form-select rounded-0"
                              id="selectInput"
                              onChange={(e) => {
                                setBoardName(e.target.value)
                                setBoardNameFilter(e.target.value)
                                setLanguageName("")
                                setClassName("")
                                onBoardChange(e.target.value)
                              }}
                              required
                              value={boardNameFilter}
                              name='board_id'
                            >
                              <option value="" disabled selected>
                                Select an option
                              </option>
                              {boardList.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-3 col-sm-12">
                          <div class="form-group">
                            <label for="selectInput">Select Language</label>
                            <select
                              class="custom-select form-select rounded-0"
                              id="selectInput"
                              onChange={(e) => {
                                setLanguageName(e.target.value)
                                setLanguageNameFilter(e.target.value)
                                setClassName("")
                                onLanguageChange(e.target.value)
                              }}
                              required
                              value={languageNameFilter}
                              name='language_id'
                            >
                              <option value="" disabled selected>
                                Select an option
                              </option>
                              {languageList.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-3 col-sm-12">
                          <div class="form-group">
                            <label for="selectInput">Select Class</label>
                            <select
                              class="custom-select form-select rounded-0"
                              id="selectInput"
                              onChange={(e) => {
                                setClassName(e.target.value)
                                setClassNameFilter(e.target.value)
                                onClassChange(e.target.value)
                              }}
                              required
                              value={classNameFilter}
                              name='class_id'
                            >
                              <option value="" disabled selected>
                                Select an option
                              </option>
                              {classList.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-1 col-sm-12 d-flex justify-content-center align-items-center">
                          <button type="submit" className="btn btn-primary">Filter</button>
                        </div>

                        <div className="col-md-1 col-sm-12">
                        </div>

                      </div>
                    </div>

                  </form>

                  <ThemeProvider theme={defaultMaterialTheme}>
                    <MaterialTable
                      icons={tableIcons}
                      title="E-Books"
                      columns={columns}
                      data={data}
                      onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                      options={{
                        exportButton: true,
                        actionsColumnIndex: -1,
                        headerStyle: {
                          backgroundColor: 'rgb(0 123 255)',
                          color: '#FFF',
                          fontSize: 18
                        },
                        rowStyle: (rowData, index) => ({
                          backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                        }),
                      }}
                      actions={[
                        {
                          icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                          tooltip: 'Edit',
                          onClick: (event, rowData) => handleEdit(rowData),
                        },
                        // rowData => ({
                        //   icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                        //   tooltip: 'Delete',
                        //   onClick: (event, rowData) => handleDelete(rowData),
                        // })
                      ]}
                    />
                  </ThemeProvider>

                </div>
              </div>
            </div>

          </div>
        </section>


      </div>
    </div>
  )
}

export default EBook