import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Header from './Header'
import Content from './Content'
import SideNav from './SideNav'
import Footer from './Footer'

const LoggeIn = () => {
    return (
        <>
            <Header />
            <Content />
            <SideNav />
            <Footer />
        </>
    )
}

export default LoggeIn