import React, { useState } from 'react'
import { FileCopy } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Button, IconButton } from '@material-ui/core';
import { postFormApiCall } from '../../../utils/api';

const LinkComponent = ({ linkValue, setLinkValue}) => {
    const [imageFile, setImageFile] = useState(null)
    const [responseLink, setResponseLink] = useState("")
    const [isLinkAvailable, setIsLinkAvailable] = useState(false)

    const handleLinkChange = (event) => {
        setLinkValue(event.target.value);
    };

    const handleImageUpload = async () => {
        if (imageFile === null) {
            return Swal.fire({
                title: 'Error',
                text: "Select an image first",
                icon: 'error'
            });
        }
        const formData = new FormData();
        formData.append('image', imageFile);
        const res = await postFormApiCall("mcq/upload/mcq/image", formData)

        if (res.error === true) {
            return setResponseLink(res.message);
        }
        setResponseLink(res.data);
        setIsLinkAvailable(true)
        setImageFile(null)

    };

    // copy
    const [copied, setCopied] = useState(false);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(responseLink);
        setCopied(true);
    };

    return (
        <>
            <div className="row">
                <div className="col-md-8 col-sm-12">
                    <div className="form-group">
                        <input
                            type="text"
                            onChange={handleLinkChange}
                            className="form-control"
                            placeholder="Enter Link"
                            value={linkValue}
                        />
                    </div>
                    {isLinkAvailable === true &&
                        <div>
                            <div>{responseLink}</div>
                            <IconButton onClick={copyToClipboard}>
                                <FileCopy />
                            </IconButton>
                            {copied && <span> Copied!</span>}
                        </div>
                    }
                </div>
                <div className="col-md-4 col-sm-12">
                    <div class="form-group">
                        <label htmlFor="classInput">Image(get image link by uploading an image)</label>
                        <div class="input-group">
                            <input type="file" accept="image/*" onChange={(e) => setImageFile(e.target.files[0])} />
                        </div>
                    </div>
                    <br />
                    <Button onClick={() => handleImageUpload()} style={{backgroundColor: "blue", color: 'white', fontSize:12}}>Upload</Button>
                </div>
            </div>
        </>
    )
}

export default LinkComponent