import React, { useEffect, useRef, useState } from 'react'
import MaterialTable from 'material-table';
import { ThemeProvider, createTheme } from '@mui/material';
import { Delete, Edit } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { getApiCall, postApiCall } from '../../utils/api';
import tableIcons from '../component/MaterialIcon';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

const Stream = () => {
    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false)

    const [data, setData] = useState([])
    const [boardList, setBoardList] = useState([])
    const [streamName, setStreamName] = useState("")
    const [boardName, setBoardName] = useState("")
    const [scriptName, setScriptName] = useState("")

    const [streamID, setStreamID] = useState("")

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        setLoading(true)

        const boardres = await getApiCall("board/get/all")
        setBoardList(boardres.data.length !== 0 && boardres.data);

        const res = await getApiCall("stream/get/all")
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });

        setLoading(false)
    }

    const handleSubmit = async () => {
        if (streamName === "" || boardName === "")
            return Swal.fire({
                title: 'Error',
                text: 'Please enter the necessary field',
                icon: 'error'
            });

            setLoading(true)

        let res = {}
        if (streamID === "") {
            res = await postApiCall("stream/add", { "name": streamName, "board_id": boardName, "script": scriptName })
        } else {
            res = await postApiCall("stream/update", { "id": streamID, "name": streamName, "board_id": boardName, "script": scriptName })
        }
        setLoading(false)
        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });
        setStreamName("")
        // setBoardName("")
        setScriptName("")
        setStreamID("")
        fetchData()
    }


    const columns = [
        { title: "Sl. no", field: "serial" },
        { title: "Stream", field: "name" },
        { title: "Board", field: "board_name" },
        { title: "Script", field: "script" },
    ]

    const handleEdit = async (rowData) => {

        setStreamName(rowData.name)
        setStreamID(rowData.id)
        setBoardName(rowData.board_id)
        setScriptName(rowData.script)

        inputRef.current.focus();
        const input = document.getElementById('streamInput');
        input.classList.add('glow');
        input.focus();
        setTimeout(() => {
            input.classList.remove('glow');
        }, 1000);
    }

    const handleDelete = async (rowData) => {
        new Swal({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await postApiCall("stream/delete", { "id": rowData.id })
                if (res.error === true) {
                    return Swal.fire({
                        title: 'Error',
                        text: res.message,
                        icon: 'error'
                    });
                }
                Swal.fire({
                    title: 'Successful',
                    text: res.message,
                    icon: 'success'
                });
                fetchData()
            }
        })

    }

    return (

        <div>
            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
            <div className='content-wrapper'>

                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">Stream</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Stream</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* left column */}
                            <div className="col-md-12 mt-4">
                                {/* general form elements */}
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">{streamID === "" ? "Add" : "Edit"} Board</h3>
                                    </div>
                                    {/* /.card-header */}
                                    {/* form start */}
                                    {/* <form> */}
                                    <div className="card-body">
                                        <div className="row">

                                            <div className="col-md-4 col-sm-12">
                                                <div class="form-group">
                                                    <label for="selectInput">{streamID === "" ? "Select" : "Edit"} Board</label>
                                                    <select
                                                        class="custom-select form-select rounded-0"
                                                        id="selectInput"
                                                        onChange={(e) => setBoardName(e.target.value)}
                                                        required
                                                        value={boardName}
                                                    >
                                                        <option value="" disabled selected>
                                                            Select an option
                                                        </option>
                                                        {boardList.map((option) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="streamInput">{streamID === "" ? "Add" : "Edit"} Stream</label>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => setStreamName(e.target.value)}
                                                        className="form-control"
                                                        id="streamInput"
                                                        placeholder="Enter Stream"
                                                        value={streamName}
                                                        ref={inputRef}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="streamInput">{streamID === "" ? "Add" : "Edit"} Script</label>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => setScriptName(e.target.value)}
                                                        className="form-control"
                                                        id="streamInput"
                                                        placeholder="Enter Stream"
                                                        value={scriptName}
                                                        ref={inputRef}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                    <div className="card-footer">
                                        <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary">{streamID === "" ? "Submit" : "Update"}</button>
                                    </div>
                                </div>
                                {/* /.card-body */}

                                {/* </form> */}
                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (right) */}
                    </div>
                    {/* /.row */}
                </section >

                <section className="content mt-4">
                    <div className="container-fluid">
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable
                                icons={tableIcons}
                                title="Streams"
                                columns={columns}
                                data={data}
                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                options={{
                                    exportButton: true,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        backgroundColor: 'rgb(0 123 255)',
                                        color: '#FFF',
                                        fontSize: 18
                                    },
                                    rowStyle: (rowData, index) => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                    }),
                                }}
                                actions={[
                                    {
                                        icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                                        tooltip: 'Edit',
                                        onClick: (event, rowData) => handleEdit(rowData),
                                    },
                                    // rowData => ({
                                    //     icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                                    //     tooltip: 'Delete',
                                    //     onClick: (event, rowData) => handleDelete(rowData),

                                    // })
                                ]}
                            />
                        </ThemeProvider>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Stream