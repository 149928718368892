import React, { useEffect, useRef, useState } from 'react'
import { getApiCall, postApiCall, postFormApiCall } from '../../utils/api';
import MaterialTable from 'material-table';
import { ThemeProvider, createTheme } from '@mui/material';
import { Delete, Edit } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import tableIcons from '../component/MaterialIcon';
import moment from 'moment'
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

const LiveClass = () => {
    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false)

    const [data, setData] = useState([])
    const [liveClassName, setLiveClassName] = useState("")
    const [liveClassLink, setLiveClassLink] = useState("")
    const [liveClassStarts, setLiveClassStarts] = useState("")
    const [liveClassEnds, setLiveClassEnds] = useState("")

    const [liveClassID, setLiveClassID] = useState("")

    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        setLoading(true)
        const res = await getApiCall("liveclass/get/all")
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
        setLoading(false)

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        let formData = new FormData();

        formData.append('id', liveClassID);
        formData.append('title', liveClassName);
        formData.append('link', liveClassLink);
        formData.append('starts', liveClassStarts);
        formData.append('ends', liveClassEnds);

        let res = {}
        if (liveClassID === "") {
            res = await postFormApiCall("liveclass/add", formData)
        } else {
            res = await postFormApiCall("liveclass/update", formData)
        }
        setLoading(false)
        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });
        setLiveClassName("")
        setLiveClassID("")
        setLiveClassLink("")
        setLiveClassStarts("")
        setLiveClassEnds("")

        setLoading(true)

        fetchData()
    }

    const columns = [
        {
            title: "Sl. no", field: "serial", cellStyle: {
                width: "10%"
            }
        },
        {
            title: "Title", field: "title", cellStyle: {
                width: "10%"
            }
        },
        { title: "Link", field: "link" },
        {
            title: "Starts", field: "starts",
            render: (rowData) => {
                const dateTime = moment(rowData.starts);
                const date = dateTime.format('DD-MM-YYYY');
                const time = dateTime.format('h:mm:ss a');
                return (
                    <>
                        {date}
                        <br />
                        {time}
                    </>
                )
            }
        },
        {
            title: "Ends", field: "ends",
            render: (rowData) => {
                const dateTime = moment(rowData.ends);
                const date = dateTime.format('DD-MM-YYYY');
                const time = dateTime.format('h:mm:ss a');
                return (
                    <>
                        {date}
                        <br />
                        {time}
                    </>
                )
            }
        },
        {
            title: "Status", field: "status",
            render: (rowData) => {
                let data = ""
                const nowDT = moment(new Date());
                const starts = moment(rowData.starts);
                const ends = moment(rowData.ends);
                const now = moment(nowDT, 'DD-MM-YYYY h:mm:ss a');

                // Check if event has started
                if (now.isBefore(starts)) {
                    data = "Pending"
                }

                if (now.isAfter(ends)) {
                    data = "Ended"
                }

                if (now.isBetween(starts, ends)) {
                    data = "Ongoing"
                }

                return (
                    <>
                        {data}
                    </>
                )
            }
        },
    ]

    const handleEdit = async (rowData) => {
        setLiveClassName(rowData.title)
        setLiveClassID(rowData.id)
        setLiveClassLink(rowData.link)
        setLiveClassStarts(rowData.starts)
        setLiveClassEnds(rowData.ends)

        inputRef.current.focus();
        const input = document.getElementById('stateInput');
        input.classList.add('glow');
        input.focus();
        setTimeout(() => {
            input.classList.remove('glow');
        }, 1000);
    }
    const handleDelete = async (rowData) => {
        new Swal({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await postApiCall("state/delete", { "id": rowData.id })
                if (res.error === true) {
                    return Swal.fire({
                        title: 'Error',
                        text: res.message,
                        icon: 'error'
                    });
                }
                Swal.fire({
                    title: 'Successful',
                    text: res.message,
                    icon: 'success'
                });
                fetchData()
            }
        })

    }

    return (
        <div>
            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
        <div className='content-wrapper'>

            <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h4 className="m-0">Live Classes</h4>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Live Classes</li>
                            </ol>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>


            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-12 mt-4">
                            {/* general form elements */}
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">{liveClassID === "" ? "Add" : "Edit"} Live Class</h3>
                                </div>
                                {/* /.card-header */}
                                {/* form start */}
                                <form onSubmit={handleSubmit}>
                                    <div className="card-body row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="stateInput">{liveClassID === "" ? "Add" : "Edit"} Title</label>
                                                <input
                                                    type="text"
                                                    onChange={(e) => setLiveClassName(e.target.value)}
                                                    className="form-control"
                                                    id="stateInput"
                                                    placeholder="Enter title"
                                                    value={liveClassName}
                                                    ref={inputRef}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="stateInput">{liveClassID === "" ? "Add" : "Edit"} Link</label>
                                                <input
                                                    type="text"
                                                    onChange={(e) => setLiveClassLink(e.target.value)}
                                                    className="form-control"
                                                    id="stateInput"
                                                    placeholder="Add Link"
                                                    value={liveClassLink}
                                                    ref={inputRef}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="stateInput">{liveClassID === "" ? "Add" : "Edit"} Start Date and Time</label>
                                                <input
                                                    type="datetime-local"
                                                    onChange={(e) => setLiveClassStarts(e.target.value)}
                                                    className="form-control"
                                                    value={liveClassStarts}
                                                    ref={inputRef}
                                                    min={new Date().toISOString().slice(0, -8)}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="stateInput">{liveClassID === "" ? "Add" : "Edit"} End Date and Time</label>
                                                <input
                                                    type="datetime-local"
                                                    onChange={(e) => setLiveClassEnds(e.target.value)}
                                                    className="form-control"
                                                    value={liveClassEnds}
                                                    ref={inputRef}
                                                />

                                            </div>
                                        </div>


                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary">{liveClassID === "" ? "Submit" : "Update"}</button>
                                    </div>
                                </form>
                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (right) */}
                    </div>
                    {/* /.row */}
                </div>{/* /.container-fluid */}
            </section>


            <section className="content">
                <div className="container-fluid">
                    <ThemeProvider theme={defaultMaterialTheme}>
                        <MaterialTable
                            icons={tableIcons}
                            title="Liva Classes List"
                            columns={columns}
                            data={data}
                            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                            options={{
                                exportButton: true,
                                actionsColumnIndex: -1,
                                headerStyle: {
                                    backgroundColor: 'rgb(0 123 255)',
                                    color: '#FFF',
                                    fontSize: 18
                                },
                                rowStyle: (rowData, index) => ({
                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                }),
                            }}
                            actions={[
                                {
                                    icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                                    tooltip: 'Edit',
                                    onClick: (event, rowData) => handleEdit(rowData),
                                },
                                // rowData => ({
                                //     icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                                //     tooltip: 'Delete',
                                //     onClick: (event, rowData) => handleDelete(rowData),
                                // })
                            ]}
                        />
                    </ThemeProvider>
                </div>
            </section>

        </div>
        </div>
    )
}

export default LiveClass