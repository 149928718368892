import React, { useEffect, useRef, useState } from 'react'
import { MAIN_URL, getApiCall, postApiCall, postFormApiCall } from '../../utils/api';
import MaterialTable from 'material-table';
import tableIcons from '../component/MaterialIcon';
import { ThemeProvider, createTheme } from '@mui/material';
import { Delete, Edit, FileCopy } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

const Initials = () => {

    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);
    const formRef = useRef();
    const [loading, setLoading] = useState(false)

    const [data, setData] = useState([])
    const [initialId, setInititlId] = useState("")

    const [title, setTitle] = useState("")

    const [languageList, setLanguageList] = useState([])
    const [languageName, setLanguageName] = useState("")

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        setLoading(true)
        const langRes = await getApiCall("language/get/all")
        setLanguageList(langRes.data);

        const res = await getApiCall("initials/get/all")
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
        setLoading(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        let res = {}
        if (initialId === "") {
            res = await postApiCall("initials/add", {
                "title": title,
                "language_id": languageName,
            })
        } else {
            res = await postApiCall("initials/update", {
                "id": initialId,
                "title": title,
                "language_id": languageName,
            })
        }
        setLoading(false)
        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });

        setInititlId("")
        setTitle("")

        fetchData()
    }


    const columns = [
        { title: "Sl. no", field: "serial" },
        { title: "Initials", field: "title" },
        { title: "Lanaguage", field: "language_name" },
    ]

    const handleEdit = async (rowData) => {
        setInititlId(rowData.id)
        setTitle(rowData.title)
        setLanguageName(rowData.language_id)

        inputRef.current.focus();
        const input = document.getElementById('boardInput');
        input.classList.add('glow');
        input.focus();
        setTimeout(() => {
            input.classList.remove('glow');
        }, 1000);
    }

    const handleFilter = async (e) => {
        e.preventDefault();
        setLoading(true)
        const formData = new FormData(formRef.current);
        const entries = Array.from(formData.entries());
        const formValues = entries.reduce(
            (prev, [name, value]) => ({
                ...prev,
                [name]: value,
            }),
            {}
        );
        const res = await postApiCall("initials/get/byLanguage", formValues)
        setLoading(false)
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
    }

    return (

        <div>
            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
            <div className='content-wrapper'>

                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">Initials</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Initials</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>


                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* left column */}
                            <div className="col-md-12 mt-4">
                                {/* general form elements */}
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">{initialId === "" ? "Add" : "Edit"} Initials</h3>
                                    </div>
                                    <form onSubmit={handleSubmit} enctype="multipart/form-data">
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{initialId === "" ? "Select" : "Edit"} Language</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setLanguageName(e.target.value)
                                                            }}
                                                            required
                                                            value={languageName}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {languageList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{initialId === "" ? "Add" : "Edit"} Initial Alphabet</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setTitle(e.target.value)}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Enter Initial"
                                                            ref={inputRef}
                                                            value={title}
                                                            name='title'
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary">{initialId === "" ? "Submit" : "Update"}</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (right) */}
                    </div>
                    {/* /.row */}
                </section >


                <section className="content mt-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 mt-4">

                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4 >Filter</h4>
                                    </div>

                                    <form onSubmit={handleFilter} ref={formRef}>
                                        <div className="card-body">
                                            <div className='row'>

                                                <div className="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{initialId === "" ? "Select" : "Edit"} Language</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setLanguageName(e.target.value)
                                                            }}
                                                            required
                                                            value={languageName}
                                                            name='language_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {languageList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-1 col-sm-12 d-flex justify-content-center align-items-center">
                                                    <button type="submit" className="btn btn-primary">Filter</button>
                                                </div>

                                                <div className="col-md-1 col-sm-12">
                                                </div>

                                            </div>
                                        </div>

                                    </form>

                                    <ThemeProvider theme={defaultMaterialTheme}>
                                        <MaterialTable
                                            icons={tableIcons}
                                            title="E-Books"
                                            columns={columns}
                                            data={data}
                                            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                            options={{
                                                exportButton: true,
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    backgroundColor: 'rgb(0 123 255)',
                                                    color: '#FFF',
                                                    fontSize: 18
                                                },
                                                rowStyle: (rowData, index) => ({
                                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                                }),
                                            }}
                                            actions={[
                                                {
                                                    icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                                                    tooltip: 'Edit',
                                                    onClick: (event, rowData) => handleEdit(rowData),
                                                },
                                                // rowData => ({
                                                //   icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                                                //   tooltip: 'Delete',
                                                //   onClick: (event, rowData) => handleDelete(rowData),
                                                // })
                                            ]}
                                        />
                                    </ThemeProvider>

                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </div>
        </div>
    )
}

export default Initials