import React, { useEffect, useRef, useState } from 'react'
import { MAIN_URL, getApiCall, postApiCall, postFormApiCall } from '../../utils/api';
import MaterialTable from 'material-table';
import tableIcons from '../component/MaterialIcon';
import { Button, ThemeProvider, createTheme } from '@mui/material';
import { Add, Edit, Warning } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

const Packages = () => {
    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();

    const [data, setData] = useState([])
    const [packageID, setPackageID] = useState("")

    const [packageName, setPackageName] = useState("")
    const [packageDescription, setPackageDescription] = useState("")
    const [price, setPrice] = useState("")
    const [discount, setDiscount] = useState("")
    const [isDefault, setIsDefault] = useState(false)
    const [boardName, setBoardName] = useState("")

    const [isChecked, setIsChecked] = useState(true);
    const [isSpokenEnglish, setIsSpokenEnglish] = useState(false);
    const [isDictionary, setIsDictionary] = useState(false);

    const [isEbook, setIsEbook] = useState(true);
    const [isVideo, setIsVideo] = useState(true);
    const [isMCQ, setIsMCQ] = useState(true);
    const [isModelQA, setIsModelQA] = useState(true);
    const [isEnglishGrammar, setIsEnglishGrammar] = useState(true);
    const [isMath, setIsMath] = useState(true);

    const [isDemo, setIsDemo] = useState(false);
    const [actualPackageId, setActualPackageId] = useState("")
    const [packageList, setPackageList] = useState([])

    const [imageFile, setImageFile] = useState(null)

    const [languageName, setLanguageName] = useState("")
    const [languageList, setLanguageList] = useState([])
    const [boardList, setBoardList] = useState([])

    const [languageListTerminology1, setLanguageListTerminology1] = useState([])
    const [languageListTerminology2, setLanguageListTerminology2] = useState([])
    const [languageListSpokenEnglish, setLanguageListSpokenEnglish] = useState([])

    const [languageNameTerminology1, setLanguageNameTerminology1] = useState("")
    const [languageNameTerminology2, setLanguageNameTerminology2] = useState("")
    const [languageNameSpokenEnglish, setLanguageNameSpokenEnglish] = useState("")

    useEffect(() => {
        fetchData()
    }, [])
    const [isDefaultPackagePresent, setIsDefaultPackagePresent] = useState(false)

    const fetchData = async () => {
        setLoading(true)
        const languageTerminology = await getApiCall("dictionary/get/language")
        setLanguageListTerminology1(languageTerminology.data1);
        setLanguageListTerminology2(languageTerminology.data2);

        const boardres = await getApiCall("board/get/all")
        setBoardList(boardres.data.length !== 0 && boardres.data);

        const languageSpokenEnglish = await getApiCall("spokenAlphabet/get/all/spoken/language")
        setLanguageListSpokenEnglish(languageSpokenEnglish.data);

        const res = await getApiCall("packages/get/all")
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });

        // const languageResponse = await getApiCall("language/get/all")
        // setLanguageList(languageResponse.data);

        // default
        res.data.forEach(item => {
            if (item.is_default === 1)
                setIsDefaultPackagePresent(true)
        });

        const packageListResponse = await getApiCall("packages/get/packageList")
        setPackageList(packageListResponse.data)

        setLoading(false)
    }
    const imageFileInputRef = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true)
        const formData = new FormData();

        formData.append('id', packageID);
        formData.append('name', packageName);
        formData.append('medium_id', languageName);
        formData.append('board_id', boardName);
        formData.append('description', packageDescription);
        formData.append('actual_price', price);
        formData.append('discount', discount);
        formData.append('is_spoken_english', isSpokenEnglish);
        formData.append('spoken_english_language_id', languageNameSpokenEnglish);
        formData.append('is_terminology', isDictionary);
        formData.append('terminology_language1_id', languageNameTerminology1);
        formData.append('terminology_language2_id', languageNameTerminology2);
        formData.append('status', isChecked);
        formData.append('image', imageFile);
        formData.append('is_video', isVideo);
        formData.append('is_ebook', isEbook);
        formData.append('is_mcq', isMCQ);
        formData.append('is_modelqa', isModelQA);
        formData.append('is_english_grammar', isEnglishGrammar);
        formData.append('is_math', isMath);
        formData.append('is_default', isDefault);
        formData.append('is_demo', isDemo);
        formData.append('actual_package_id', actualPackageId);

        let res = {}
        if (packageID === "") {
            res = await postFormApiCall("packages/add", formData)
        } else {
            res = await postFormApiCall("packages/update", formData)
        }
        setLoading(false)
        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });
        if (imageFileInputRef.current) {
            imageFileInputRef.current.value = '';
        }
        setPackageID("")
        setPackageName("")
        setPackageDescription("")
        setPrice("")
        setDiscount("")

        setIsEbook(true);
        setIsVideo(true);
        setIsMCQ(true);
        setIsModelQA(true);
        setIsEnglishGrammar(true);
        setIsMath(true);


        // setIsChecked(false)
        // setIsSpokenEnglish(false)
        // setIsDictionary(false)
        fetchData()
    }

    const onBoardChange = async (val) => {
        setLoading(true)

        const mediumRes = await postApiCall("language/get/byBoard", { "id": val })
        setLanguageList(mediumRes.data);

        setLoading(false)
    }


    const handleEdit = async (rowData) => {
        setPackageID(rowData.id)
        setPackageName(rowData.name)
        setPackageDescription(rowData.description)
        setPrice(rowData.actual_price)
        setDiscount(rowData.discount)

        setIsSpokenEnglish(rowData.is_spoken_english === 1 ? true : false)
        setLanguageNameSpokenEnglish(rowData.spoken_english_language_id)
        setBoardName(rowData.board_id)

        const mediumRes = await postApiCall("language/get/byBoard", { "id": rowData.board_id })
        setLanguageList(mediumRes.data);

        setLanguageName(rowData.medium_id)


        setIsDictionary(rowData.is_terminology === 1 ? true : false)
        setLanguageNameTerminology1(rowData.terminology_language1_id)
        setLanguageNameTerminology2(rowData.terminology_language2_id)

        setIsVideo(rowData.is_video === 1 ? true : false)
        setIsEbook(rowData.is_ebook === 1 ? true : false)
        setIsMCQ(rowData.is_mcq === 1 ? true : false)
        setIsModelQA(rowData.is_modelqa === 1 ? true : false)
        setIsEnglishGrammar(rowData.is_english_grammar === 1 ? true : false)
        setIsMath(rowData.is_math === 1 ? true : false)
        setIsDefault(rowData.is_default === 1 ? true : false)

        setIsDemo(rowData.is_demo === 1 ? true : false)

        if (rowData.is_demo === 1) {
            setActualPackageId(rowData.actual_package_id)
        }

        setIsChecked(rowData.status)

        inputRef.current.focus();
        const input = document.getElementById('boardInput');
        input.classList.add('glow');
        input.focus();
        setTimeout(() => {
            input.classList.remove('glow');
        }, 1000);
    }

    const generateOfflineData = async (packageId) => {
        setLoading(true)
        const response = await postApiCall("packageData/generateOfflineFile", { "package_id": packageId })
        setLoading(false)

        if (response.error === true) {
            return Swal.fire({
                title: 'Error',
                text: response.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: response.message,
            icon: 'success'
        });
    }

    const defaultChangeFunc = async (id) => {
        new Swal({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                console.log("asdasd", id);
                const res = await postApiCall("packages/update/default", { "id": id })
                if (res.error === true) {
                    return Swal.fire({
                        title: 'Error',
                        text: res.message,
                        icon: 'error'
                    });
                }
                Swal.fire({
                    title: 'Successful',
                    text: res.message,
                    icon: 'success'
                });
                fetchData()
            }
        })
    }

    const columns = [
        {
            title: "Sl. no", field: "serial",
            cellStyle: {
                width: "5%"
            }
        },
        {
            title: "Image", field: "image_path",
            render: (rowData) => {
                return (
                    <>
                        <img src={MAIN_URL + rowData.image_path} height={50} width={50} /></>
                )
            }
        },
        {
            title: "Name", field: "name",
            render: (rowData) => {
                return (
                    <>
                        <div>{rowData.name}</div>
                        {/* {rowData.is_default === 1 &&
                            <div style={{ color: 'red', fontSize: 14 }}>
                                Default
                            </div>
                        } */}
                    </>
                )
            }
        },
        {
            title: "Is Default?", field: "name",
            render: (rowData) => {
                return (
                    <>
                        {rowData.is_default === 1 &&
                            <div style={{ color: 'red', fontSize: 14 }}>
                                Default
                            </div>
                        }
                        {rowData.is_default !== 1 && <div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={false}
                                    onChange={(e) => defaultChangeFunc(rowData.id)}
                                />
                                <span className='active-status'>Make it a Default?</span>
                            </label>
                        </div>}
                    </>
                )
            }
        },
        {
            title: "Board", field: "board_name",
            // cellStyle: {
            //     width: "5%"
            // }
        },
        {
            title: "Module", field: "module",
            render: (rowData) => {
                return (
                    <>
                        {rowData.is_video === 1 && <div>Video</div>}
                        {rowData.is_ebook === 1 && <div>EBook</div>}
                        {rowData.is_mcq === 1 && <div>MCQ</div>}
                        {rowData.is_math === 1 && <div>Math</div>}
                        {rowData.is_english_grammar === 1 && <div>English Grammar</div>}
                        {rowData.is_modelqa === 1 && <div>Model Q/A</div>}
                    </>
                )
            }
        },
        {
            title: "Terminology", field: "terminology",
            // cellStyle: {
            //     width: "40%"
            // },
            render: (rowData) => {
                return (
                    <>
                        {rowData.is_terminology === 1
                            ?
                            <>
                                <div>{rowData.terminology1_language_name} - {rowData.terminology2_language_name}</div>
                            </>
                            :
                            <div>Not Added</div>
                        }
                    </>
                )
            }
        },
        {
            title: "Spoken English", field: "description",
            // cellStyle: {
            //     width: "20%"
            // },
            render: (rowData) => {
                return (
                    <>
                        {rowData.is_spoken_english === 1
                            ?
                            <div>
                                {rowData.spoken_language_name}
                            </div>
                            :
                            <div>Not Added</div>
                        }
                    </>
                )
            }
        },
        {
            title: "Price", field: "actual_price",
            cellStyle: {
                width: "40%"
            },
            render: (rowData) => {
                return (
                    <>
                        Actual Price: {rowData.actual_price} <br />
                        Discount: {rowData.discount}<br />
                        Discounted Price: {rowData.discounted_price}<br />
                    </>
                )
            }
        },

        {
            title: "Status", field: "status", cellStyle: {
                width: "10%"
            },
            render: (rowData) => {
                return (
                    <>
                        {rowData.status === 1 ? "Active" : "InActive"}
                    </>
                )
            }
        },
        {
            title: "Add Data", field: "data",
            render: (rowData) => {
                return (
                    <>
                        <span onClick={() => {
                            navigate(`/${rowData.id}`);
                        }} style={{ color: '#5656ce', fontWeight: 'bold' }}>Add Data<Add /></span>
                    </>
                )
            }
        },
        {
            title: "Offline Data", field: "data",
            render: (rowData) => {
                return (
                    <>
                        <Button onClick={() => generateOfflineData(rowData.id)} variant='sm' style={{ fontSize: "12px", padding: '5px', backgroundColor: 'blue', color: 'white' }}>Generate</Button>
                        {/* <span onClick={() => {
                            navigate(`/${rowData.id}`);
                        }} style={{ color: '#5656ce', fontWeight: 'bold' }}>Generate Offline Data.</span> */}
                    </>
                )
            }
        },
    ]

    return (
        <div>
            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
            <div className='content-wrapper'>

                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">Add Package Data</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Package Data</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>

                <section className="content">
                    <div className="container-fluid">

                        {isDefaultPackagePresent === false &&
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginTop: 5
                            }}>
                                <Warning style={{ color: 'red', marginRight: 8, marginBottom: 5 }} />
                                <p className="text-danger mt-0 mb-0">Please, Provide atleast one default Package!</p>
                            </div>}
                        <div className="row">

                            <div className="col-md-12 mt-4">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">{packageID === "" ? "Add" : "Edit"} Board</h3>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{packageID === "" ? "Add" : "Edit"} Package Name</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => { setPackageName(e.target.value) }}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Enter Package Name"
                                                            value={packageName}
                                                            ref={inputRef}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{packageID === "" ? "Select" : "Edit"} Board</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setBoardName(e.target.value)
                                                                setLanguageName("")
                                                                onBoardChange(e.target.value)
                                                            }}
                                                            required
                                                            value={boardName}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {boardList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{packageID === "" ? "Select" : "Edit"} Medium</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setLanguageName(e.target.value)
                                                            }}
                                                            required
                                                            value={languageName}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {languageList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{packageID === "" ? "Add" : "Edit"} Package Description</label>
                                                        <textarea
                                                            type="text"
                                                            onChange={(e) => setPackageDescription(e.target.value)}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Enter Package Description"
                                                            value={packageDescription}
                                                            ref={inputRef}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{packageID === "" ? "Add" : "Edit"} Price</label>
                                                        <input
                                                            type="number"
                                                            onChange={(e) => setPrice(e.target.value)}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Enter Price"
                                                            value={price}
                                                            ref={inputRef}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{packageID === "" ? "Add" : "Edit"} Discount</label>
                                                        <input
                                                            type="number"
                                                            onChange={(e) => setDiscount(e.target.value)}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Enter Discount"
                                                            value={discount}
                                                            ref={inputRef}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div class="form-group">
                                                        <label htmlFor="classInput">{packageID === "" ? "Add" : "Edit"} package icon</label>
                                                        <div class="input-group">
                                                            <input type="file" accept="image/*" ref={imageFileInputRef} onChange={(e) => setImageFile(e.target.files[0])} required={packageID === "" ? true : false} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isDictionary}
                                                            onChange={(e) => setIsDictionary(e.target.checked)}
                                                        />
                                                        <span className='active-status'>Terminology</span>
                                                    </label>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isSpokenEnglish}
                                                            onChange={(e) => setIsSpokenEnglish(e.target.checked)}
                                                        />
                                                        <span className='active-status'>Spoken English</span>
                                                    </label>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isChecked}
                                                            onChange={(e) => setIsChecked(e.target.checked)}
                                                        />
                                                        <span className='active-status'>Active</span>
                                                    </label>
                                                </div>

                                                {isDefaultPackagePresent === false && <div className="col-md-12 col-sm-12">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isDefault}
                                                            onChange={(e) => setIsDefault(e.target.checked)}
                                                        />
                                                        <span className='active-status'>Default Package</span>
                                                    </label>
                                                </div>}


                                                {isDictionary === true &&
                                                    <>
                                                        <div className="col-md-12 col-sm-12">
                                                            <label for="selectInput" style={{
                                                                fontSize: 18,
                                                                textTransform: 'uppercase',
                                                                textDecoration: 'underline'
                                                            }}>Select for Terminology</label>
                                                        </div>
                                                        <div className="col-md-4 col-sm-12">
                                                            <div class="form-group">
                                                                <label for="selectInput">{packageID === "" ? "Select" : "Edit"} First Language</label>
                                                                <select
                                                                    class="custom-select form-select rounded-0"
                                                                    id="selectInput"
                                                                    onChange={(e) => {
                                                                        setLanguageNameTerminology1(e.target.value)
                                                                    }}
                                                                    required
                                                                    value={languageNameTerminology1}
                                                                >
                                                                    <option value="" disabled selected>
                                                                        Select an option
                                                                    </option>
                                                                    {languageListTerminology1.map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-sm-12">
                                                            <div class="form-group">
                                                                <label for="selectInput">{packageID === "" ? "Select" : "Edit"} Second Language</label>
                                                                <select
                                                                    class="custom-select form-select rounded-0"
                                                                    id="selectInput"
                                                                    onChange={(e) => {
                                                                        setLanguageNameTerminology2(e.target.value)
                                                                    }}
                                                                    required
                                                                    value={languageNameTerminology2}
                                                                >
                                                                    <option value="" disabled selected>
                                                                        Select an option
                                                                    </option>
                                                                    {languageListTerminology2.map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {isSpokenEnglish === true &&
                                                    <>
                                                        <div className="col-md-12 col-sm-12">
                                                            <label for="selectInput" style={{
                                                                fontSize: 18,
                                                                textTransform: 'uppercase',
                                                                textDecoration: 'underline'
                                                            }}>Select for Spoken English</label>

                                                        </div>
                                                        <div className="col-md-4 col-sm-12">
                                                            <div class="form-group">
                                                                <label for="selectInput">{packageID === "" ? "Select" : "Edit"} Language</label>
                                                                <select
                                                                    class="custom-select form-select rounded-0"
                                                                    id="selectInput"
                                                                    onChange={(e) => {
                                                                        setLanguageNameSpokenEnglish(e.target.value)
                                                                    }}
                                                                    required
                                                                    value={languageNameSpokenEnglish}
                                                                >
                                                                    <option value="" disabled selected>
                                                                        Select an option
                                                                    </option>
                                                                    {languageListSpokenEnglish.map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                <div className="col-md-12 col-sm-12 mt-5">
                                                    <label htmlFor="boardInput">{packageID === "" ? "Select" : "Edit"} Module to include in a Package</label>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isEbook}
                                                            onChange={(e) => setIsEbook(e.target.checked)}
                                                        />
                                                        <span className='active-status'>E-Book</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isVideo}
                                                            onChange={(e) => setIsVideo(e.target.checked)}
                                                        />
                                                        <span className='active-status'>Video</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isMCQ}
                                                            onChange={(e) => setIsMCQ(e.target.checked)}
                                                        />
                                                        <span className='active-status'>MCQ</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isMath}
                                                            onChange={(e) => setIsMath(e.target.checked)}
                                                        />
                                                        <span className='active-status'>Math</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isEnglishGrammar}
                                                            onChange={(e) => setIsEnglishGrammar(e.target.checked)}
                                                        />
                                                        <span className='active-status'>English Grammar</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isModelQA}
                                                            onChange={(e) => setIsModelQA(e.target.checked)}
                                                        />
                                                        <span className='active-status'>Model Q/A</span>
                                                    </label>
                                                </div>

                                                <hr style={{
                                                    border: '1px solid green'
                                                }} />
                                                <div className="col-md-12 mt-1 col-sm-12">
                                                    <label htmlFor="boardInput">{packageID === "" ? "Add" : "Edit"} Select Demo</label>
                                                </div>

                                                <div className="col-md-12 col-sm-12">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isDemo}
                                                            onChange={(e) => setIsDemo(e.target.checked)}
                                                        />
                                                        <span className='active-status'>Is it a Demo</span>
                                                    </label>
                                                </div>



                                                {isDemo && <div className="col-md-12 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{packageID === "" ? "Select" : "Edit"} Module</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setActualPackageId(e.target.value)
                                                            }}
                                                            required
                                                            value={actualPackageId}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {packageList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>}

                                            </div>
                                        </div>

                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary">{packageID === "" ? "Submit" : "Update"}</button>
                                        </div>
                                    </form>

                                </div>

                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (right) */}
                    </div>
                    {/* /.row */}
                </section >



                <section className="content mt-4">
                    <div className="container-fluid">
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable
                                style={{
                                    fontSize: 14
                                }}
                                icons={tableIcons}
                                title="Packages"
                                columns={columns}
                                data={data}
                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                options={{
                                    exportButton: true,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        backgroundColor: 'rgb(0 123 255)',
                                        color: '#FFF',
                                        fontSize: 18
                                    },
                                    rowStyle: (rowData, index) => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                    }),
                                }}
                                actions={[
                                    {
                                        icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                                        tooltip: 'Edit',
                                        onClick: (event, rowData) => handleEdit(rowData),
                                    },
                                    // rowData => ({
                                    //     icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                                    //     tooltip: 'Delete',
                                    //     onClick: (event, rowData) => handleDelete(rowData),
                                    // })
                                ]}
                            />
                        </ThemeProvider>
                    </div>
                </section>

            </div>
        </div>
    )
}

export default Packages