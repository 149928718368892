import React from 'react'
import Dashboard from './Dashboard'
import { Route, Routes } from 'react-router-dom'
import User from '../pages/User'
import Board from '../pages/basicContent/Board'
import Classes from '../pages/basicContent/Classes'
import '../pages/css/index.css'
import Language from '../pages/basicContent/Language'
import Stream from '../pages/basicContent/Stream'
import State from '../pages/basicContent/State'
import Subject from '../pages/basicContent/Subject'
import Chapter from '../pages/basicContent/Chapter'
import Videos from '../pages/mainContent/Videos'
import Dictionary from '../pages/mainContent/Dictionary'
import SpokenSentence from '../pages/mainContent/SpokenSentence'
import SpokenWord from '../pages/mainContent/SpokenWord'
import EBook from '../pages/mainContent/EBook'
import SpokenAlphabet from '../pages/mainContent/SpokenAlphabet'
import ViewSpokenWord from '../pages/component/ViewSpokenWord'
import Packages from '../pages/packages/Packages'
import Module from '../pages/packages/Module'
import LiveClass from '../pages/liveclass/LiveClass'
import Mcq from '../pages/mainContent/Mcq'
import PackageData from '../pages/packages/PackageData'
import Math from '../pages/mainContent/Math'
import ModelQA from '../pages/mainContent/ModelQA'
import Gallery from '../pages/mainContent/Gallery'
import Initials from '../pages/mainContent/Initials'
import App from '../App'
import Banner from '../pages/Banner'
import Feeds from '../pages/feed/Feeds'
import Discount from '../pages/Discount'
import Notification from '../pages/Notification'
import Quiz from '../pages/mainContent/Quiz'
import QuizImage from '../pages/mainContent/QuizImage'

const Content = () => {
    return (
        <>
            <Routes>
                <Route path='/' element={<Dashboard />} />
                {/* <Route path='/dashboard' element={<Dashboard />} /> */}
                <Route path='/users' element={<User />} />
                <Route path='/notification' element={<Notification />} />
                <Route path='/modules' element={<Module />} />
                <Route path='/packages' element={<Packages />} />
                <Route path='/gallery' element={<Gallery />} />
                <Route path='/banner' element={<Banner />} />
                <Route path=':id' element={<PackageData />} />
                <Route path='/discount' element={<Discount />} />

                <Route path='/liveclass' element={<LiveClass />} />
                <Route path='/feeds' element={<Feeds />} />
                <Route path='/language' element={<Language />} />
                <Route path='/state' element={<State />} />
                <Route path='/board' element={<Board />} />
                <Route path='/stream' element={<Stream />} />
                <Route path='/class' element={<Classes />} />
                <Route path='/subject' element={<Subject />} />
                <Route path='/chapter' element={<Chapter />} />
                <Route path='/ebook' element={<EBook />} />

                <Route path='/videos' element={<Videos />} />
                <Route path='/initials' element={<Initials />} />
                <Route path='/dictionary' element={<Dictionary />} />
                <Route path='/spoken-alphabet' element={<SpokenAlphabet />} />
                <Route path='/spoken-sentence' element={<SpokenSentence />} />
                <Route path='/spoken-word' element={<SpokenWord />} />

                <Route path='/mcq' element={<Mcq />} />
                <Route path='/modelqa' element={<ModelQA />} />
                <Route path='/quiz' element={<Quiz />} />
                <Route path='/quizimage' element={<QuizImage />} />

            </Routes>
        </>
    )
}

export default Content