import React, { useEffect, useRef, useState } from 'react'
import { getApiCall, postApiCall, postFormApiCall } from '../../utils/api';
import MaterialTable from 'material-table';
import tableIcons from '../component/MaterialIcon';
import { ThemeProvider, createTheme } from '@mui/material';
import { Delete, Edit } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { Select } from 'antd';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

const Board = () => {
    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const [isChecked, setIsChecked] = useState(false);
    const [data, setData] = useState([])
    const [stateList, setStateList] = useState([])
    const [boardName, setBoardName] = useState("")
    const [boardCode, setBoardCode] = useState("")
    const [stateName, setStateName] = useState("")

    const [languageList, setLanguageList] = useState([])
    const [mediumName, setMediumName] = useState([])

    const [boardID, setBoardID] = useState("")
    const [imageFile, setImageFile] = useState(null)
    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        setLoading(true)
        const stateRes = await getApiCall("state/get/all")
        setStateList(stateRes.data);

        const langRes = await getApiCall("language/get/all")
        setLanguageList(langRes.data);

        const res = await getApiCall("board/get/all")
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
        setLoading(false)
    }
    
    const imageFileInputRef = useRef(null);
    const handleSubmit = async () => {

        if (boardName === "")
            return Swal.fire({
                title: 'Error',
                text: 'Please enter a Board Name',
                icon: 'error'
            });

        if (boardCode === "")
            return Swal.fire({
                title: 'Error',
                text: 'Please enter a Board Code',
                icon: 'error'
            });

        if (stateName === "")
            return Swal.fire({
                title: 'Error',
                text: 'Please select a state',
                icon: 'error'
            });

        if (mediumName.length === 0)
            return Swal.fire({
                title: 'Error',
                text: 'Please select Medium',
                icon: 'error'
            });

        setLoading(true)

        const formData = new FormData();

        formData.append("state_id", stateName);
        formData.append("name", boardName);
        formData.append("board_code", boardCode);
        formData.append("medium", JSON.stringify(mediumName));
        formData.append("status", isChecked);
        formData.append("id", boardID);
        formData.append('image', imageFile);
        let res = {}
        if (boardID === "") {
            res = await postFormApiCall("board/add", formData)
        } else {
            res = await postFormApiCall("board/update", formData)
        }

        setLoading(false)

        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });

        if (imageFileInputRef.current) {
            imageFileInputRef.current.value = '';
        }

        setBoardName("")
        setBoardCode("")
        setMediumName([])
        setBoardID("")
        setStateName("")
        setIsChecked(false)
        setImageFile(null)
        fetchData()
    }

    const columns = [
        { title: "Sl. no", field: "serial" },
        { title: "Board", field: "name" },
        { title: "Code", field: "board_code" },
        { title: "State", field: "state_name" },
        {
            title: "Medium", field: "mediumData",
            render: rowData => <>{rowData.mediumData.map((item, index) => (
                <li key={item.id}>{item.name}</li>
            ))}</>
        },
        {
            title: "Status", field: "status",
            render: (rowData) => {
                return (
                    <>
                        {rowData.status === 1 ? "Active" : "InActive"}
                    </>
                )
            }
        },
    ]

    const handleEdit = async (rowData) => {
        setBoardName(rowData.name)
        setBoardCode(rowData.board_code)
        setBoardID(rowData.id)
        setStateName(rowData.state_id)
        setIsChecked(rowData.status)
        setMediumName(rowData.mediumData.map((item, index) => (
            item.id
        )))

        inputRef.current.focus();
        const input = document.getElementById('boardInput');
        input.classList.add('glow');
        input.focus();
        setTimeout(() => {
            input.classList.remove('glow');
        }, 1000);
    }

    const handleDelete = async (rowData) => {
        new Swal({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await postApiCall("board/delete", { "id": rowData.id })
                if (res.error === true) {
                    return Swal.fire({
                        title: 'Error',
                        text: res.message,
                        icon: 'error'
                    });
                }
                Swal.fire({
                    title: 'Successful',
                    text: res.message,
                    icon: 'success'
                });
                fetchData()
            }
        })

    }

    const handleSelectChange = (event) => {
        const options = event.target.options;
        const values = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                values.push(options[i].value);
            }
        }
        setMediumName(values);
    }


    return (
        <>
            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
            <div className='content-wrapper'>

                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">Board</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Board</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* left column */}
                            <div className="col-md-12 mt-4">
                                {/* general form elements */}
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">{boardID === "" ? "Add" : "Edit"} Board</h3>
                                    </div>
                                    {/* /.card-header */}
                                    {/* form start */}
                                    {/* <form> */}
                                    <div className="card-body">
                                        <div className="row">

                                            <div className="col-md-6 col-sm-12">
                                                <div class="form-group">
                                                    <label for="selectInput">{boardID === "" ? "Select" : "Edit"} State</label>
                                                    <select
                                                        class="custom-select form-select rounded-0"
                                                        id="selectInput"
                                                        onChange={(e) => setStateName(e.target.value)}
                                                        required
                                                        value={stateName}
                                                    >
                                                        <option value="" disabled selected>
                                                            Select an option
                                                        </option>
                                                        {stateList.map((option) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="boardInput">{boardID === "" ? "Add" : "Edit"} Board Name</label>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => setBoardName(e.target.value)}
                                                        className="form-control"
                                                        id="boardInput"
                                                        placeholder="Board Full Name"
                                                        value={boardName}
                                                        ref={inputRef}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="boardInput">{boardID === "" ? "Add" : "Edit"} Board Code</label>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => setBoardCode(e.target.value)}
                                                        className="form-control"
                                                        id="boardInput"
                                                        placeholder="Enter Board Code"
                                                        value={boardCode}
                                                        ref={inputRef}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div class="form-group">
                                                    <label for="selectInput">{boardID === "" ? "Select" : "Edit"} Medium</label>
                                                    <Select
                                                        mode="multiple"
                                                        allowClear
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        value={mediumName}
                                                        placeholder="Please select medium"
                                                        onChange={(val) => setMediumName(val)}
                                                        options={languageList.map((option) => (
                                                            {
                                                                label: option.name,
                                                                value: option.id,
                                                            }
                                                        ))}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-12 col-sm-12">
                                                <div class="form-group">
                                                    <label htmlFor="classInput">{boardID === "" ? "Add" : "Edit"} Board Logo</label>
                                                    <div class="input-group">
                                                        <input type="file" accept="image/*" ref={imageFileInputRef} onChange={(e) => setImageFile(e.target.files[0])} required={boardID === "" ? true : false} />
                                                    </div>
                                                </div>
                                            </div>

                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    onChange={(e) => setIsChecked(e.target.checked)}
                                                />
                                                <span className='active-status'>Active</span>
                                            </label>


                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary">{boardID === "" ? "Submit" : "Update"}</button>
                                    </div>
                                </div>
                                {/* /.card-body */}

                                {/* </form> */}
                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (right) */}
                    </div>
                    {/* /.row */}
                </section >

                <section className="content mt-4">
                    <div className="container-fluid">
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable
                                icons={tableIcons}
                                title="Boards"
                                columns={columns}
                                data={data}
                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                options={{
                                    exportButton: true,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        backgroundColor: 'rgb(0 123 255)',
                                        color: '#FFF',
                                        fontSize: 18
                                    },
                                    rowStyle: (rowData, index) => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                    }),
                                }}
                                actions={[
                                    {
                                        icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                                        tooltip: 'Edit',
                                        onClick: (event, rowData) => handleEdit(rowData),
                                    },
                                    // rowData => ({
                                    //     icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                                    //     tooltip: 'Delete',
                                    //     onClick: (event, rowData) => handleDelete(rowData),
                                    // })
                                ]}
                            />
                        </ThemeProvider>
                    </div>
                </section>

            </div >
        </>
    )
}

export default Board