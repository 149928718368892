import React, { useEffect, useRef, useState } from 'react'
import { getApiCall, postApiCall } from '../../utils/api';
import MaterialTable from 'material-table';
import { ThemeProvider, createTheme } from '@mui/material';
import { Delete, Edit } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import tableIcons from '../component/MaterialIcon';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

const State = () => {
    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [isChecked, setIsChecked] = useState(false);

    const [data, setData] = useState([])
    const [stateName, setStateName] = useState("")

    const [stateID, setStateID] = useState("")

    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        setLoading(true)

        const res = await getApiCall("state/get/all")
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
        setLoading(false)
    }

    const handleSubmit = async () => {
        if (stateName === "")
            return Swal.fire({
                title: 'Error',
                text: 'Please enter a State Name',
                icon: 'error'
            });

        setLoading(true)

        let res = {}
        if (stateID === "") {
            res = await postApiCall("state/add", { "name": stateName, "status": isChecked })
        } else {
            res = await postApiCall("state/update", { "id": stateID, "name": stateName, "status": isChecked })
        }

        setLoading(false)
        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });
        setStateName("")
        setStateID("")
        setIsChecked(false)
        fetchData()
    }

    const columns = [
        { title: "Sl. no", field: "serial" },
        { title: "State", field: "name" },
        {
            title: "Status", field: "status",
            render: (rowData) => {
                return (
                    <>
                        {rowData.status === 1 ? "Active" : "InActive"}
                    </>
                )
            }
        },
    ]

    const handleEdit = async (rowData) => {
        setStateName(rowData.name)
        setStateID(rowData.id)
        setIsChecked(rowData.status)

        inputRef.current.focus();
        const input = document.getElementById('stateInput');
        input.classList.add('glow');
        input.focus();
        setTimeout(() => {
            input.classList.remove('glow');
        }, 1000);
    }
    const handleDelete = async (rowData) => {
        new Swal({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await postApiCall("state/delete", { "id": rowData.id })
                if (res.error === true) {
                    return Swal.fire({
                        title: 'Error',
                        text: res.message,
                        icon: 'error'
                    });
                }
                Swal.fire({
                    title: 'Successful',
                    text: res.message,
                    icon: 'success'
                });
                fetchData()
            }
        })

    }

    return (
        <>
            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
            <div className='content-wrapper'>

                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">State</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">State</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* left column */}
                            <div className="col-md-12 mt-4">
                                {/* general form elements */}
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">{stateID === "" ? "Add" : "Edit"} State</h3>
                                    </div>
                                    {/* /.card-header */}
                                    {/* form start */}
                                    {/* <form> */}
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="stateInput">{stateID === "" ? "Add" : "Edit"} State</label>
                                            <input
                                                type="text"
                                                onChange={(e) => setStateName(e.target.value)}
                                                className="form-control"
                                                id="stateInput"
                                                placeholder="Enter State"
                                                value={stateName}
                                                ref={inputRef}
                                                required
                                            />
                                        </div>

                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={(e) => setIsChecked(e.target.checked)}
                                            />
                                            <span className='active-status'>Active</span>
                                        </label>

                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary">{stateID === "" ? "Submit" : "Update"}</button>
                                    </div>
                                    {/* </form> */}
                                </div>
                                {/* /.card */}
                            </div>
                            {/*/.col (right) */}
                        </div>
                        {/* /.row */}
                    </div>{/* /.container-fluid */}
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable
                                icons={tableIcons}
                                title="States"
                                columns={columns}
                                data={data}
                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                options={{
                                    exportButton: true,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        backgroundColor: 'rgb(0 123 255)',
                                        color: '#FFF',
                                        fontSize: 18
                                    },
                                    rowStyle: (rowData, index) => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                    }),
                                }}
                                actions={[
                                    {
                                        icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                                        tooltip: 'Edit',
                                        onClick: (event, rowData) => handleEdit(rowData),
                                    },
                                    // rowData => ({
                                    //     icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                                    //     tooltip: 'Delete',
                                    //     onClick: (event, rowData) => handleDelete(rowData),
                                    // })
                                ]}
                            />
                        </ThemeProvider>
                    </div>
                </section>
            </div>
        </>
    )
}

export default State