import React, { useEffect, useRef, useState } from 'react'
import { getApiCall, postApiCall, postFormApiCall } from '../../utils/api';
import MaterialTable from 'material-table';
import tableIcons from '../component/MaterialIcon';
import { Box, Modal, ThemeProvider, Typography, createTheme } from '@mui/material';
import { CloudDownloadOutlined, Edit } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import { Button, Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "75%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
};

const Dictionary = () => {
    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false)

    const [data, setData] = useState([])
    const [dictionaryID, setDictionaryID] = useState("")

    const [term1, setTerm1] = useState("")
    const [term2, setTerm2] = useState("")

    const [excelData, setExcelData] = useState([]);
    const [excelFile, setExcelFile] = useState(null);

    const [open, setOpen] = useState(false)

    const [languageList, setLanguageList] = useState([])
    const [languageName1, setLanguageName1] = useState("")
    const [languageName2, setLanguageName2] = useState("")

    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        setLoading(true)
        const langRes = await getApiCall("language/get/all")
        setLanguageList(langRes.data);

        const res = await getApiCall("dictionary/get/all")
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
        setLoading(false)

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        let res = {}
        if (dictionaryID === "") {
            res = await postApiCall("dictionary/add", {
                "terminology_1": term1,
                "terminology_2": term2,
                "language1_id": languageName1,
                "language2_id": languageName2
            })
        } else {
            res = await postApiCall("dictionary/update", {
                "id": dictionaryID,
                "terminology_1": term1,
                "terminology_2": term2,
                "language1_id": languageName1,
                "language2_id": languageName2
            })
        }
        setLoading(false)

        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });

        setDictionaryID("")
        setTerm1("")
        setTerm2("")

        fetchData()

    }

    const handleUploadExcel = async (e) => {
        e.preventDefault();
        setLoading(true)
        const formData = new FormData();

        formData.append('file', excelFile);
        formData.append('language1_id', languageName1);
        formData.append('language2_id', languageName2);

        const res = await postFormApiCall("dictionary/add/excel", formData)
        setLoading(false)

        setExcelFile(null)

        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }

        setOpen(false)

        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });

        fetchData()

    }

    const handleFileUpload = (e) => {

        setExcelFile(e.target.files[0])

        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const jsonData = XLSX.utils.sheet_to_json(ws, { header: 1 });
            setExcelData(jsonData);
        };
        reader.readAsBinaryString(file);
    };


    const columns = [
        { title: "Sl. no", field: "serial" },
        {
            title: "Word From", field: "terminology_1",
            render: (rowData) => {
                return (
                    <>
                        <div>Language: {rowData.language1_name}</div>
                        <div>{rowData.terminology_1}</div>
                    </>
                )
            }
        },
        {
            title: "Word To", field: "terminology_2",
            render: (rowData) => {
                return (
                    <>
                        <div>Language: {rowData.language2_name}</div>
                        <div>{rowData.terminology_2}</div>
                    </>
                )
            }
        },
    ]

    const handleEdit = async (rowData) => {
        setDictionaryID(rowData.id)
        setTerm1(rowData.terminology_1)
        setTerm2(rowData.terminology_2)
        setLanguageName1(rowData.language1_id)
        setLanguageName2(rowData.language2_id)

        inputRef.current.focus();
        const input = document.getElementById('boardInput');
        input.classList.add('glow');
        input.focus();
        setTimeout(() => {
            input.classList.remove('glow');
        }, 1000);
    }

    return (
        <>

<CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
            <Modal
                open={open}
                onClose={() => {
                    setOpen(false)
                    setExcelData([])
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{
                        backgroundColor: "#007bff",
                        padding: "10px",
                        color: "white",
                        borderRadius: 2
                    }}>
                        <Typography className='font-weight-bold' id="modal-modal-title" variant="p" component="h5">
                            UPLOAD
                        </Typography>
                    </Box>

                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <label for="selectInput">Download Sample file</label>

                            <div class="form-group">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CloudDownloadOutlined />}
                                    onClick={(e) => {
                                        const link = document.createElement('a');
                                        link.href = '/sample/terminology.xlsx';
                                        link.download = 'terminology.xlsx';

                                        // Simulate a click on the link
                                        link.click();
                                    }}
                                >
                                    Download sample file
                                </Button>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="selectInput">{dictionaryID === "" ? "Select" : "Edit"} First Word Language</label>
                                <select
                                    class="custom-select form-select rounded-0"
                                    id="selectInput"
                                    onChange={(e) => {
                                        setLanguageName1(e.target.value)
                                    }}
                                    required
                                    value={languageName1}
                                >
                                    <option value="" disabled selected>
                                        Select an option
                                    </option>
                                    {languageList.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="selectInput">{dictionaryID === "" ? "Select" : "Edit"} Second Word Language</label>
                                <select
                                    class="custom-select form-select rounded-0"
                                    id="selectInput"
                                    onChange={(e) => {
                                        setLanguageName2(e.target.value)
                                    }}
                                    required
                                    value={languageName2}
                                >
                                    <option value="" disabled selected>
                                        Select an option
                                    </option>
                                    {languageList.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12 mt-4">
                            <div className="form-group">
                                <label htmlFor="boardInput">Select an excel File</label>
                                <br />
                                <input type="file" onChange={handleFileUpload} />
                            </div>
                        </div>
                    </div>

                    <hr />

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {excelData[0] &&
                                        excelData[0].map((header, index) => (
                                            <TableCell style={{ fontWeight: 'bold' }} key={index}>{header}</TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {excelData.slice(1).map((row, index) => (
                                    <TableRow key={index}>
                                        {row.map((cell, index) => (
                                            <TableCell key={index}>{cell}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <Button
                                variant="contained"
                                disabled={excelData.length !== 0 ? (languageName1 !== "" ? (languageName2 !== "" ? false : true) : true) : true}
                                color="primary"
                                startIcon={<FileUploadRoundedIcon />}
                                onClick={(e) => handleUploadExcel(e)}
                            >
                                Upload
                            </Button>
                        </div>
                    </div>

                </Box>
            </Modal>

            <div className='content-wrapper'>

                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">Terminology</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Terminology</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* left column */}
                            <div className="col-md-12 mt-4">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<FileUploadRoundedIcon />}
                                    onClick={() => setOpen(true)}
                                >
                                    Upload Excel
                                </Button>
                            </div>
                            <div className="col-md-12 mt-4">
                                {/* general form elements */}
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">{dictionaryID === "" ? "Add" : "Edit"} Terminology</h3>
                                    </div>
                                    <form onSubmit={handleSubmit} enctype="multipart/form-data">
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{dictionaryID === "" ? "Select" : "Edit"} First Word Language</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setLanguageName1(e.target.value)
                                                            }}
                                                            required
                                                            value={languageName1}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {languageList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{dictionaryID === "" ? "Add" : "Edit"} From Word</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setTerm1(e.target.value)}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Enter First Word"
                                                            ref={inputRef}
                                                            value={term1}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{dictionaryID === "" ? "Select" : "Edit"} Second Word Language</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setLanguageName2(e.target.value)
                                                            }}
                                                            required
                                                            value={languageName2}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {languageList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{dictionaryID === "" ? "Add" : "Edit"} To Word</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setTerm2(e.target.value)}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Enter Second Word"
                                                            ref={inputRef}
                                                            value={term2}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{dictionaryID === "" ? "Add" : "Edit"} Assamese meaning</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setAssameseMeaning(e.target.value)}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Enter Assamese meaning"
                                                            ref={inputRef}
                                                            value={assameseMeaning}
                                                            name='title'
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{dictionaryID === "" ? "Add" : "Edit"} Hindi meaning</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setHindiMeaning(e.target.value)}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Enter Hindi meaning"
                                                            ref={inputRef}
                                                            value={hindiMeaning}
                                                            name='title'
                                                            required
                                                        />
                                                    </div>
                                                </div> */}

                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary">{dictionaryID === "" ? "Submit" : "Update"}</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (right) */}
                    </div>
                    {/* /.row */}
                </section >

                <section className="content mt-4">
                    <div className="container-fluid">
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable
                                icons={tableIcons}
                                title="Terminology Data"
                                columns={columns}
                                data={data}
                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                options={{
                                    exportButton: true,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        backgroundColor: 'rgb(0 123 255)',
                                        color: '#FFF',
                                        fontSize: 18
                                    },
                                    rowStyle: (rowData, index) => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                    }),
                                }}
                                actions={[
                                    {
                                        icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                                        tooltip: 'Edit',
                                        onClick: (event, rowData) => handleEdit(rowData),
                                    },
                                    // rowData => ({
                                    //     icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                                    //     tooltip: 'Delete',
                                    //     onClick: (event, rowData) => handleDelete(rowData),
                                    // })
                                ]}
                            />
                        </ThemeProvider>
                    </div>
                </section>

            </div>

        </>
    )
}

export default Dictionary