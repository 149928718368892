import React from 'react'
import { Blocks } from 'react-loader-spinner'

const Loader = (props) => {
    return (
        <div>
            <Blocks
                visible={props.loading}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{
                    position: "absolute",
                    bottom: 20,
                }}
                wrapperClass="blocks-wrapper"
            />
        </div>
    )
}

export default Loader