import React, { useEffect, useRef, useState } from 'react'
import MaterialTable from 'material-table';
import { ThemeProvider, createTheme } from '@mui/material';
import { Edit } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { MAIN_URL, getApiCall, postApiCall, postFormApiCall } from '../utils/api';
import tableIcons from './component/MaterialIcon';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { CountertopsOutlined } from '@mui/icons-material';
import { Button } from '@material-ui/core';

const Discount = () => {
    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false)

    const [isChecked, setIsChecked] = useState(true);

    const [data, setData] = useState([])

    const [discountCode, setDiscountCode] = useState("")
    const [packageId, setPackageId] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [validFrom, setValidFrom] = useState("")
    const [validTill, setValidTill] = useState("")
    const [discription, setDiscription] = useState("")

    const [packageList, setPackageList] = useState([])


    const [discountId, setDiscountId] = useState("")

    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        setLoading(true)
        const res = await getApiCall("discount/get/all")
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });

        const response = await getApiCall("packages/get/all")
        setPackageList(response.data)
        setLoading(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const formData = new FormData();

        formData.append('id', discountId);
        formData.append('discount_code', discountCode);
        formData.append('package_id', packageId);
        formData.append('phone_number', phoneNumber);
        formData.append('valid_from', validFrom);
        formData.append('valid_till', validTill);
        formData.append('description', discription);
        formData.append('status', isChecked);

        let res = {}
        if (discountId === "") {
            res = await postFormApiCall("discount/add", formData)
        } else {
            res = await postFormApiCall("discount/update", formData)
        }
        setLoading(false)
        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });

        setDiscountCode("")
        setDiscountId("")
        setPackageId("")
        setPhoneNumber("")
        setValidFrom("")
        setValidTill("")
        setDiscription("")

        fetchData()
    }

    const columns = [
        { title: "Sl. no", field: "serial" },
        { title: "Discount", field: "discount_code" },
        { title: "Package", field: "package_name" },
        { title: "Phone", field: "phone_number" },
        {
            title: "Status", field: "status",
            render: (rowData) => {
                return (
                    <>
                        {"Validity: " + rowData.valid_from + " -to- " +
                            rowData.valid_till === "9999-12-31T00:00:00.000Z" ? "Forever" : rowData.valid_till}
                    </>
                )
            }
        },
        {
            title: "Status", field: "status",
            render: (rowData) => {
                return (
                    <>
                        {rowData.status === 1 ? "Active" : "InActive"}
                    </>
                )
            }
        },
    ]

    const handleEdit = async (rowData) => {
        setDiscountId(rowData.id)
        setValidFrom(rowData.valid_from)
        setValidTill(rowData.valid_till)
        setDiscription(rowData.description)
        setDiscountCode(rowData.discount_code)
        setPhoneNumber(rowData.phone_number)
        setPackageId(rowData.package_id)

        setIsChecked(rowData.status)

        inputRef.current.focus();
        const input = document.getElementById('stateInput');
        input.classList.add('glow');
        input.focus();
        setTimeout(() => {
            input.classList.remove('glow');
        }, 1000);
    }

    const generateDiscountCode = async () => {
        setLoading(true)
        const disountRes = await randomCouponCode()

        const res = await postApiCall("discount/compare/discount/code", {
            "discount_code": disountRes
        })
        setLoading(false)
        if (res.data.length === 0) {
            setDiscountCode(disountRes)
        } else {
            await generateDiscountCode();
        }
    }


    async function randomCouponCode() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let couponCode = '';

        for (let i = 0; i < 10; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            const randomCharacter = characters.charAt(randomIndex).toUpperCase();
            couponCode += randomCharacter;
        }

        return couponCode;
    }

    return (
        <div>
            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
            <div className='content-wrapper'>

                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">Discount</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Discount</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">{discountId === "" ? "Add" : "Edit"} Discount</h3>
                                    </div>

                                    <form onSubmit={handleSubmit}>
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-md-6 col-sm-12 mb-4">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{discountId === "" ? "Add" : "Edit"} Discount Code</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => { setDiscountCode(e.target.value) }}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Enter Discount Code"
                                                            value={discountCode}
                                                            required
                                                        />
                                                    </div>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        // startIcon={<CountertopsOutlined />}
                                                        onClick={(e) => {
                                                            generateDiscountCode()
                                                        }}
                                                    >
                                                        Generate DiscountCode
                                                    </Button>
                                                </div>

                                                {/* <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            // startIcon={<CountertopsOutlined />}
                                                            onClick={(e) => {
                                                               
                                                            }}
                                                        >
                                                            Generate DiscountCode
                                                        </Button>
                                                    </div>
                                                </div> */}

                                                <div className="col-md-5 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{discountId === "" ? "Add" : "Edit"} Discription</label>
                                                        <textarea
                                                            onChange={(e) => { setDiscription(e.target.value) }}
                                                            className="form-control"
                                                            placeholder="Enter Discription"
                                                            value={discription}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{discountId === "" ? "Select" : "Edit"} Package</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setPackageId(e.target.value)
                                                            }}
                                                            value={packageId}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {packageList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>


                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{discountId === "" ? "Add" : "Edit"} Phone Number</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => { setPhoneNumber(e.target.value) }}
                                                            className="form-control"
                                                            placeholder="Enter phone Number"
                                                            value={phoneNumber}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{discountId === "" ? "Add" : "Edit"} Valid From:</label>
                                                        <input
                                                            type="date"
                                                            onChange={(e) => { setValidFrom(e.target.value) }}
                                                            className="form-control"
                                                            value={validFrom}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{discountId === "" ? "Add" : "Edit"} Valid Till:</label>
                                                        <input
                                                            type="date"
                                                            onChange={(e) => { setValidTill(e.target.value) }}
                                                            className="form-control"
                                                            value={validTill}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12 col-sm-12">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isChecked}
                                                            onChange={(e) => setIsChecked(e.target.checked)}
                                                        />
                                                        <span className='active-status'>Active</span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary">{discountId === "" ? "Submit" : "Update"}</button>
                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>
                </section >

                <section className="content">
                    <div className="container-fluid">
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable
                                icons={tableIcons}
                                title="States"
                                columns={columns}
                                data={data}
                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                options={{
                                    exportButton: true,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        backgroundColor: 'rgb(0 123 255)',
                                        color: '#FFF',
                                        fontSize: 18
                                    },
                                    rowStyle: (rowData, index) => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                    }),
                                }}
                                actions={[
                                    {
                                        icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                                        tooltip: 'Edit',
                                        onClick: (event, rowData) => handleEdit(rowData),
                                    },
                                    // rowData => ({
                                    //     icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                                    //     tooltip: 'Delete',
                                    //     onClick: (event, rowData) => handleDelete(rowData),
                                    // })
                                ]}
                            />
                        </ThemeProvider>
                    </div>
                </section>

            </div>
        </div>
    )
}

export default Discount