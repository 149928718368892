import React, { useEffect, useRef, useState } from 'react'
import { MAIN_URL, getApiCall, postApiCall, postFormApiCall } from '../../utils/api';
import MaterialTable from 'material-table';
import tableIcons from '../component/MaterialIcon';
import { ThemeProvider, createTheme } from '@mui/material';
import { Delete, Edit, FileCopy } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

const Gallery = () => {
    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const [loading, setLoading] = useState(false)

    const [selectedImages, setSelectedImages] = useState([]);
    const [data, setData] = useState([])

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        setLoading(true)
        const res = await getApiCall("gallery/get/all")
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
        setLoading(false)
    }

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        const imageFiles = files.filter((file) => file.type.startsWith('image/'));
        setSelectedImages((prevImages) => [...prevImages, ...imageFiles]);
    };

    const imageFileInputRef = useRef(null);

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (selectedImages.length === 0) {
            return Swal.fire({
                title: 'Error',
                text: "Please include files",
                icon: 'error'
            });
        }
        setLoading(true)
        const res = {}
        for (let i = 0; i < selectedImages.length; i++) {
            const imageFile = selectedImages[i];

            try {
                const formData = new FormData();
                formData.append('image', imageFile);

                // Make API call for each image file
                await postFormApiCall("gallery/add", formData)

            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
        setLoading(false)
        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });
        if (imageFileInputRef.current) {
            imageFileInputRef.current.value = '';
        }
        setSelectedImages([])

        fetchData()
    }

    const copyToClipboard = (path) => {
        navigator.clipboard.writeText(path);
    };

    const columns = [
        { title: "Sl. no", field: "serial" },
        {
            title: "Image", field: "gallery_path",
            render: (rowData) => {
                return (
                    <>
                        <img src={MAIN_URL + rowData.gallery_path} height={70} width={70} /></>
                )
            }
        },
        {
            title: "Link", field: "gallery_path",
            render: (rowData) => {
                return (
                    <>
                        <p>{MAIN_URL + rowData.gallery_path}</p>
                        <IconButton onClick={() => copyToClipboard(MAIN_URL + rowData.gallery_path)}>
                            <FileCopy />
                        </IconButton>
                    </>
                )
            }
        },
    ]

    return (

        <div>
            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
            <div className='content-wrapper'>

                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">Gallery</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Gallery</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>


                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* left column */}
                            <div className="col-md-12 mt-4">
                                {/* general form elements */}
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Add Multiple Images</h3>
                                    </div>
                                    <form onSubmit={handleSubmit} enctype="multipart/form-data">
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-md-4 col-sm-12">
                                                    <div>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            multiple
                                                            ref={imageFileInputRef}
                                                            onChange={handleImageChange}
                                                        />
                                                        {selectedImages.length > 0 && (
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-around',
                                                                alignItems: 'center',
                                                                flexDirection: 'row'
                                                            }}>

                                                                {selectedImages.map((image, index) => (
                                                                    <div key={index}>
                                                                        <img src={URL.createObjectURL(image)} alt={`Image ${index + 1}`} width={100} height={100} />
                                                                        <div>{image.name}</div>
                                                                        {/* <div>File size: {image.size} bytes</div> */}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (right) */}
                    </div>
                    {/* /.row */}
                </section >



                <section className="content mt-4">
                    <div className="container-fluid">
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable
                                icons={tableIcons}
                                title="Gallery List"
                                columns={columns}
                                data={data}
                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                options={{
                                    exportButton: true,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        backgroundColor: 'rgb(0 123 255)',
                                        color: '#FFF',
                                        fontSize: 18
                                    },
                                    rowStyle: (rowData, index) => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                    }),
                                }}
                            // actions={[
                            //     {
                            //         icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                            //         tooltip: 'Edit',
                            //         onClick: (event, rowData) => handleEdit(rowData),
                            //     },
                            //     rowData => ({
                            //         icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                            //         tooltip: 'Delete',
                            //         onClick: (event, rowData) => handleDelete(rowData),
                            //     })
                            // ]}
                            />
                        </ThemeProvider>
                    </div>
                </section>

            </div>
        </div>
    )
}

export default Gallery