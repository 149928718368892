import { Link, useParams } from "react-router-dom"
import jwt_decode from 'jwt-decode';

const SideNav = () => {

    const { id } = useParams();


    const logCheck = localStorage.getItem("data");
    let decoded_data = ""
    if (logCheck != null) {
        decoded_data = jwt_decode(logCheck)
    }

    return (
        <div>
            {/* Main Sidebar Container */}
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                {/* Brand Logo */}
                <Link to="/" className="brand-link" style={{
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <img src="logo.png" alt="AdminLTE Logo" className="elevation-1" style={{ width: 80, height: 80, padding: 5, borderRadius: "10%" }} />
                    <div className="brand-text font-weight-light ml-2">Gyansrota</div>
                </Link>
                {/* Sidebar */}
                <div className="sidebar" style={{
                    height: `calc(100vh - (10.5rem + 1px))`
                }}>
                    {/* Sidebar user panel (optional) */}
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="image">
                            <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
                        </div>
                        <div className="info">
                            <a href="#" className="d-block">{decoded_data.user.name}</a>
                        </div>
                    </div>

                    

                    {/* Sidebar Menu */}
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li className="nav-item">
                                <Link to='/' className="nav-link">
                                    <i className="nav-icon fas fa-tachometer-alt" />
                                    <p>
                                        Dashboard
                                    </p>
                                </Link>
                            </li>

                            <li className="nav-header">Users</li>
                            <li className="nav-item">
                                <Link to='/users' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        Registered
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/notification' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        Notification
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            {/* MCQs */}
                            {/* <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon fas fa-book-open" />
                                    <p>
                                        Users
                                        <i className="right fas fa-angle-left" />
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to='/users' className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>
                                                Registered
                                            </p>
                                        </Link>
                                    </li>
                                </ul>
                            </li> */}

                            <li className="nav-header">Package/Module</li>

                            <li className="nav-item">
                                <Link to='/modules' className="nav-link">
                                    <i className="nav-icon fas fa-cube" />
                                    <p>
                                        Module
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/packages' className="nav-link">
                                    <i className="nav-icon fas fa-box" />
                                    <p>
                                        Packages
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item d-none">
                                <Link to={`/packageData/${id}`} className="nav-link">
                                    <i className="nav-icon fas fa-box" />
                                    <p>
                                        Packages Data
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/gallery' className="nav-link">
                                    <i className="nav-icon fas fa-box" />
                                    <p>
                                        Gallery
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/banner' className="nav-link">
                                    <i className="nav-icon fas fa-box" />
                                    <p>
                                        Banner
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/discount' className="nav-link">
                                    <i className="nav-icon fas fa-box" />
                                    <p>
                                        Discount
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>

                            <li className="nav-header">Classes/Feeds</li>
                            <li className="nav-item">
                                <Link to='/liveclass' className="nav-link">
                                    <i className="nav-icon fas fa-circle" />
                                    <p>
                                        Live Classes
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/feeds' className="nav-link">
                                    <i className="nav-icon fas fa-circle" />
                                    <p>
                                        Feeds
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>


                            {/* Basic Content */}
                            <li className="nav-header">Basic Contents</li>
                            <li className="nav-item">
                                <Link to='/language' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        Language
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/state' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        State
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/board' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        Board
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/stream' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        Stream
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/class' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        Class
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/subject' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        Subject
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/chapter' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        Chapter
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>

                            {/* <li className="nav-item">
                                <div className="nav-link " style={{
                                    color: 'white',
                                    // backgroundColor: "#7d572f8f"
                                }}>
                                    <i className="nav-icon fas fa-book-open" />
                                    <p>
                                        Basic
                                        <i className="right fas fa-angle-left" />
                                    </p>
                                </div>
                                <ul className="nav nav-treeview">
                                    
                                </ul>
                            </li> */}


                            {/* Main Content */}
                            <li className="nav-header">Main Contents</li>

                            <li className="nav-item">
                                <Link to='/ebook' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        E-Book
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/videos' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        videos
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to='/initials' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        Initials
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/dictionary' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        Dictionary
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/spoken-alphabet' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        Spoken Alphabet
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/spoken-sentence' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        Spoken Sentence
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/spoken-word' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        Spoken Word
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>

                            {/* <li className="nav-item">
                                <div className="nav-link" style={{
                                    color: 'white',
                                    // backgroundColor: "#7d572f8f"
                                }}>
                                    <i className="nav-icon fas fa-book-open" />
                                    <p>
                                        Main
                                        <i className="right fas fa-angle-left" />
                                    </p>
                                </div>
                                <ul className="nav nav-treeview">
                                   
                                </ul>
                            </li> */}

                            {/* <li className="nav-header">Questions</li> */}

                            {/* MCQs */}
                            <li className="nav-header">MCQs</li>
                            <li className="nav-item">
                                <Link to='/mcq' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        MCQ
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/modelqa' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        Model Q/A
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/quiz' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        Quiz
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/quizimage' className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>
                                        Quiz Image
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>

                            {/* <li className="nav-item">
                                <div className="nav-link" style={{
                                    color: 'white',
                                    // backgroundColor: "#7d572f8f"
                                }}>
                                    <i className="nav-icon fas fa-book-open" />
                                    <p>
                                        MCQs
                                        <i className="right fas fa-angle-left" />
                                    </p>
                                </div>
                                <ul className="nav nav-treeview">
                                    
                                </ul>
                            </li> */}

                            {/* not used */}
                            {/* <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon fas fa-copy" />
                                    <p>
                                        Layout Options
                                        <i className="fas fa-angle-left right" />
                                        <span className="badge badge-info right">6</span>
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a href="pages/layout/top-nav.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Top Navigation</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/layout/top-nav-sidebar.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Top Navigation + Sidebar</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/layout/boxed.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Boxed</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/layout/fixed-sidebar.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Fixed Sidebar</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/layout/fixed-sidebar-custom.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Fixed Sidebar <small>+ Custom Area</small></p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/layout/fixed-topnav.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Fixed Navbar</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/layout/fixed-footer.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Fixed Footer</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/layout/collapsed-sidebar.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Collapsed Sidebar</p>
                                        </a>
                                    </li>
                                </ul>
                            </li> */}

                            {/*  <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon fas fa-tree" />
                                    <p>
                                        UI Elements
                                        <i className="fas fa-angle-left right" />
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a href="pages/UI/general.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>General</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/UI/icons.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Icons</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/UI/buttons.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Buttons</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/UI/sliders.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Sliders</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/UI/modals.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Modals &amp; Alerts</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/UI/navbar.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Navbar &amp; Tabs</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/UI/timeline.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Timeline</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/UI/ribbons.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Ribbons</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon fas fa-edit" />
                                    <p>
                                        Forms
                                        <i className="fas fa-angle-left right" />
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a href="pages/forms/general.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>General Elements</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/forms/advanced.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Advanced Elements</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/forms/editors.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Editors</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/forms/validation.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Validation</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon fas fa-table" />
                                    <p>
                                        Tables
                                        <i className="fas fa-angle-left right" />
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a href="pages/tables/simple.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Simple Tables</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/tables/data.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>DataTables</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/tables/jsgrid.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>jsGrid</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-header">EXAMPLES</li>
                            <li className="nav-item">
                                <a href="pages/calendar.html" className="nav-link">
                                    <i className="nav-icon far fa-calendar-alt" />
                                    <p>
                                        Calendar
                                        <span className="badge badge-info right">2</span>
                                    </p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="pages/gallery.html" className="nav-link">
                                    <i className="nav-icon far fa-image" />
                                    <p>
                                        Gallery
                                    </p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="pages/kanban.html" className="nav-link">
                                    <i className="nav-icon fas fa-columns" />
                                    <p>
                                        Kanban Board
                                    </p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon far fa-envelope" />
                                    <p>
                                        Mailbox
                                        <i className="fas fa-angle-left right" />
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a href="pages/mailbox/mailbox.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Inbox</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/mailbox/compose.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Compose</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/mailbox/read-mail.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Read</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon fas fa-book" />
                                    <p>
                                        Pages
                                        <i className="fas fa-angle-left right" />
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a href="pages/examples/invoice.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Invoice</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/examples/profile.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Profile</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/examples/e-commerce.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>E-commerce</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/examples/projects.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Projects</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/examples/project-add.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Project Add</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/examples/project-edit.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Project Edit</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/examples/project-detail.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Project Detail</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/examples/contacts.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Contacts</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/examples/faq.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>FAQ</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/examples/contact-us.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Contact us</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon far fa-plus-square" />
                                    <p>
                                        Extras
                                        <i className="fas fa-angle-left right" />
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a href="#" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>
                                                Login &amp; Register v1
                                                <i className="fas fa-angle-left right" />
                                            </p>
                                        </a>
                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                <a href="pages/examples/login.html" className="nav-link">
                                                    <i className="far fa-circle nav-icon" />
                                                    <p>Login v1</p>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="pages/examples/register.html" className="nav-link">
                                                    <i className="far fa-circle nav-icon" />
                                                    <p>Register v1</p>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="pages/examples/forgot-password.html" className="nav-link">
                                                    <i className="far fa-circle nav-icon" />
                                                    <p>Forgot Password v1</p>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="pages/examples/recover-password.html" className="nav-link">
                                                    <i className="far fa-circle nav-icon" />
                                                    <p>Recover Password v1</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>
                                                Login &amp; Register v2
                                                <i className="fas fa-angle-left right" />
                                            </p>
                                        </a>
                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                <a href="pages/examples/login-v2.html" className="nav-link">
                                                    <i className="far fa-circle nav-icon" />
                                                    <p>Login v2</p>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="pages/examples/register-v2.html" className="nav-link">
                                                    <i className="far fa-circle nav-icon" />
                                                    <p>Register v2</p>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="pages/examples/forgot-password-v2.html" className="nav-link">
                                                    <i className="far fa-circle nav-icon" />
                                                    <p>Forgot Password v2</p>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="pages/examples/recover-password-v2.html" className="nav-link">
                                                    <i className="far fa-circle nav-icon" />
                                                    <p>Recover Password v2</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/examples/lockscreen.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Lockscreen</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/examples/legacy-user-menu.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Legacy User Menu</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/examples/language-menu.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Language Menu</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/examples/404.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Error 404</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/examples/500.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Error 500</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/examples/pace.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Pace</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/examples/blank.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Blank Page</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="starter.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Starter Page</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon fas fa-search" />
                                    <p>
                                        Search
                                        <i className="fas fa-angle-left right" />
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a href="pages/search/simple.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Simple Search</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="pages/search/enhanced.html" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Enhanced</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-header">MISCELLANEOUS</li>
                            <li className="nav-item">
                                <a href="iframe.html" className="nav-link">
                                    <i className="nav-icon fas fa-ellipsis-h" />
                                    <p>Tabbed IFrame Plugin</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="https://adminlte.io/docs/3.1/" className="nav-link">
                                    <i className="nav-icon fas fa-file" />
                                    <p>Documentation</p>
                                </a>
                            </li>
                            <li className="nav-header">MULTI LEVEL EXAMPLE</li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="fas fa-circle nav-icon" />
                                    <p>Level 1</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon fas fa-circle" />
                                    <p>
                                        Level 1
                                        <i className="right fas fa-angle-left" />
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a href="#" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Level 2</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>
                                                Level 2
                                                <i className="right fas fa-angle-left" />
                                            </p>
                                        </a>
                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                <a href="#" className="nav-link">
                                                    <i className="far fa-dot-circle nav-icon" />
                                                    <p>Level 3</p>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link">
                                                    <i className="far fa-dot-circle nav-icon" />
                                                    <p>Level 3</p>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link">
                                                    <i className="far fa-dot-circle nav-icon" />
                                                    <p>Level 3</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#" className="nav-link">
                                            <i className="far fa-circle nav-icon" />
                                            <p>Level 2</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="fas fa-circle nav-icon" />
                                    <p>Level 1</p>
                                </a>
                            </li>
                            <li className="nav-header">LABELS</li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon far fa-circle text-danger" />
                                    <p className="text">Important</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon far fa-circle text-warning" />
                                    <p>Warning</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon far fa-circle text-info" />
                                    <p>Informational</p>
                                </a>
                            </li> */}
                        </ul>
                    </nav>
                    {/* /.sidebar-menu */}
                </div>
                {/* /.sidebar */}
            </aside>

        </div>
    )
}

export default SideNav