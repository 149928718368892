import React, { useEffect, useRef, useState } from 'react'
import { MAIN_URL, getApiCall, getApiCallForVideo, postApiCall, postFormApiCall } from '../../utils/api';
import MaterialTable from 'material-table';
import tableIcons from '../component/MaterialIcon';
import { ThemeProvider, createTheme } from '@mui/material';
import { Delete, Edit } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { Modal } from '@material-ui/core';
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'

const Videos = () => {
    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);

    const [data, setData] = useState([])
    const [videoID, setVideoID] = useState("")

    const [loading, setLoading] = useState(false)


    const [boardList, setBoardList] = useState([])
    const [languageList, setLanguageList] = useState([])
    const [classList, setClassList] = useState([])
    const [subjectList, setSubjectList] = useState([])
    const [chapterList, setChapterList] = useState([])

    const [boardName, setBoardName] = useState("")
    const [languageName, setLanguageName] = useState("")
    const [className, setClassName] = useState("")
    const [subjectName, setSubjectName] = useState("")
    const [chapterName, setChapterName] = useState("")
    const [videoTitle, setVideoTitle] = useState("")

    const [imageFile, setImageFile] = useState(null)
    const [videoFile, setVideoFile] = useState(null)
    const [includeVideo, setIncludeVideo] = useState(true)



    useEffect(() => {
        fetchData()
    }, [])

    const formRef = useRef();
    const fetchData = async () => {
        setLoading(true)
        const boardRes = await getApiCall("board/get/all")
        setBoardList(boardRes.data);

        const res = await getApiCall("video/get/all")
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
        setLoading(false)
    }

    const handleThumbnailChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setImageFile(event.target.files[0]);
        } else {
            alert("Something went wrong")
        }
    };


    const fileInputRef = useRef(null);
    const imageFileInputRef = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true)

        const formData = new FormData();

        formData.append('id', videoID);
        formData.append('title', videoTitle);
        formData.append('board_id', boardName);
        formData.append('language_id', languageName);
        formData.append('class_id', className);
        formData.append('subject_id', subjectName);
        formData.append('chapter_id', chapterName);
        formData.append('is_video', includeVideo);
        formData.append('video_path', videoFile);
        formData.append('thumbnail_path', imageFile);

        let res = {}
        if (videoID === "") {
            res = await postFormApiCall("video/add", formData)
        } else {
            res = await postFormApiCall("video/update", formData)

            // const filterResponse = await postApiCall("video/get/byBoardMediumAndClass", {
            //     "board_id": boardNameFilter,
            //     "language_id": languageNameFilter,
            //     "class_id": classNameFilter,
            // })
            // setData(filterResponse.data);
            // filterResponse.data.forEach((data, index) => { data.serial = index + 1; });
        }
        setLoading(false)
        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });

        setVideoID("")

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        if (imageFileInputRef.current) {
            imageFileInputRef.current.value = '';
        }

        // setBoardName("")
        // setLanguageName("")
        // setClassName("")

        setVideoTitle("")
        setSubjectName("")
        setChapterName("")

        setImageFile(null)
        setVideoFile(null)

        setIncludeVideo(true)

        setLoading(false);

        fetchData()
    }

    const onBoardChange = async (val) => {
        setLoading(true)
        const boardRes = await postApiCall("language/get/byBoard", { "id": val })
        setLanguageList(boardRes.data);
        setLoading(false)
    }
    const onLanguageChange = async (val) => {
        setLoading(true)
        const langResponse = await postApiCall("class/get/byBoardAndMedium", { "board_id": boardName, "medium_id": val, })
        setClassList(langResponse.data);
        setLoading(false)
    }
    const onClassChange = async (val) => {
        setLoading(true)
        const classRes = await postApiCall("subject/get/byBoardClassAndMedium", { "board_id": boardName, "class_id": val, "medium_id": languageName, })
        setSubjectList(classRes.data);
        setLoading(false)
    }
    const onSubjectChange = async (val) => {
        setLoading(true)
        const chapterRes = await postApiCall("chapter/get/name/byBoardMediumClassAndSubject", { "board_id": boardName, "class_id": className, "language_id": languageName, "subject_id": val })
        setChapterList(chapterRes.data);
        setLoading(false)
    }


    const [isOpen, setIsOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    const playVideo = async (event, id) => {
        event.preventDefault();
        setLoading(true)
        const res = await getApiCallForVideo(`video/getById/${id}`)
        setLoading(false)
        const videoBlob = await res.blob();
        const videoUrl = URL.createObjectURL(videoBlob);
        setVideoUrl(videoUrl);
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
        setVideoUrl('');
    };


    const columns = [
        { title: "Sl. no", field: "serial" },
        {
            title: "Image", field: "thumbnail_path",
            render: (rowData) => {
                return (
                    <>
                        <img src={MAIN_URL + rowData.thumbnail_path} height={70} width={70} alt='thumbnail' /></>
                )
            }
        },
        {
            title: "Title", field: "title",
            render: (rowData) => {
                return (
                    <>
                        {rowData.is_video === 0 ?
                            <p>{rowData.title}</p>
                            :
                            <a href='' onClick={(e) => playVideo(e, rowData.id)}>{rowData.title}</a>}
                    </>
                )
            }
        },
        { title: "Subject", field: "subject_name" },
        { title: "Unit", field: "chapter_name" },

    ]

    const [boardNameFilter, setBoardNameFilter] = useState("")
    const [languageNameFilter, setLanguageNameFilter] = useState("")
    const [classNameFilter, setClassNameFilter] = useState("")


    const handleFilter = async (e) => {
        e.preventDefault();

        const formData = new FormData(formRef.current);
        const entries = Array.from(formData.entries());
        const formValues = entries.reduce(
            (prev, [name, value]) => ({
                ...prev,
                [name]: value,
            }),
            {}
        );
        setLoading(true)
        const res = await postApiCall("video/get/byBoardMediumAndClass", formValues)
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
        setLoading(false)
    }

    const handleEdit = async (rowData) => {
        setVideoID(rowData.id)

        setBoardName(rowData.board_id)
        setLanguageName(rowData.language_id)
        setClassName(rowData.class_id)

        setVideoTitle(rowData.title)
        setSubjectName(rowData.subject_id)

        setChapterName(rowData.chapter_id)

        inputRef.current.focus();
        const input = document.getElementById('boardInput');
        input.classList.add('glow');
        input.focus();
        setTimeout(() => {
            input.classList.remove('glow');
        }, 1000);
    }


    const handleDelete = async (rowData) => {
        new Swal({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await postApiCall("video/delete", { "id": rowData.id })
                if (res.error === true) {
                    return Swal.fire({
                        title: 'Error',
                        text: res.message,
                        icon: 'error'
                    });
                }
                Swal.fire({
                    title: 'Successful',
                    text: res.message,
                    icon: 'success'
                });
                fetchData()
            }
        })

    }

    return (

        <div>

            <Modal
                open={isOpen}
                onClose={() => {
                    setIsOpen(false)
                    setVideoUrl('')
                }}
            >
                {/* <button onClick={closeModal}>Close</button> */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {videoUrl && (
                        <video controls width="500" height="300">
                            <source src={videoUrl} type="video/mp4" />
                        </video>
                    )}
                </div>
            </Modal>
            <div className='content-wrapper'>

                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">Video</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Video</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* left column */}
                            <div className="col-md-12 mt-4">
                                {/* general form elements */}
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">{videoID === "" ? "Add" : "Edit"} Video</h3>
                                    </div>
                                    <form onSubmit={handleSubmit} enctype="multipart/form-data">
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-md-4 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{videoID === "" ? "Select" : "Edit"} Board</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setBoardName(e.target.value)
                                                                setLanguageName("")
                                                                setClassName("")
                                                                setSubjectName("")
                                                                setChapterName("")
                                                                onBoardChange(e.target.value)
                                                            }}
                                                            required
                                                            value={boardName}
                                                            name='board_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {boardList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{videoID === "" ? "Select" : "Edit"} Language</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setLanguageName(e.target.value)
                                                                setClassName("")
                                                                setSubjectName("")
                                                                setChapterName("")
                                                                onLanguageChange(e.target.value)
                                                            }}
                                                            required
                                                            value={languageName}
                                                            name='language_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {languageList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{videoID === "" ? "Select" : "Edit"} Class</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setClassName(e.target.value)
                                                                setSubjectName("")
                                                                setChapterName("")
                                                                onClassChange(e.target.value)
                                                            }}
                                                            required
                                                            value={className}
                                                            name='class_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {classList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 col-sm-12">
                                                    <h3>Add Video</h3>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{videoID === "" ? "Add" : "Edit"} Title</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setVideoTitle(e.target.value)}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Enter title"
                                                            ref={inputRef}
                                                            value={videoTitle}
                                                            name='title'
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{videoID === "" ? "Select" : "Edit"} Subject</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setSubjectName(e.target.value)
                                                                setChapterName("")
                                                                onSubjectChange(e.target.value)
                                                            }}
                                                            required
                                                            value={subjectName}
                                                            name='subject_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {subjectList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{videoID === "" ? "Select" : "Edit"} Chapter</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setChapterName(e.target.value)
                                                            }}
                                                            required
                                                            value={chapterName}
                                                            name='chapter_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {chapterList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 col-sm-12">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={includeVideo}
                                                            onChange={(e) => setIncludeVideo(e.target.checked)}
                                                        />
                                                        <span className='active-status'>Include Video?</span>
                                                    </label>
                                                </div>

                                                {includeVideo && <div className="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label htmlFor="classInput">{videoID === "" ? "upload" : "Edit"} video</label>
                                                        <div class="input-group">
                                                            <input type="file" accept="video/*" ref={fileInputRef} onChange={(e) => setVideoFile(e.target.files[0])} required={videoID === "" ? true : false} />
                                                        </div>
                                                    </div>
                                                </div>}

                                                <div className="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label htmlFor="classInput">{videoID === "" ? "Add" : "Edit"} Thumbnail</label>
                                                        <div class="input-group">
                                                            <input type="file" accept="image/*" ref={imageFileInputRef} onChange={(e) => handleThumbnailChange(e)} required={videoID === "" ? true : false} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary">{videoID === "" ? "Submit" : "Update"}</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (right) */}
                    </div>
                    {/* /.row */}
                </section >

                <section className="content mt-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 mt-4">

                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4 >Filter</h4>
                                    </div>

                                    <form onSubmit={handleFilter} ref={formRef}>
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className="col-md-1 col-sm-12">
                                                </div>
                                                <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{videoID === "" ? "Select" : "Edit"} Board</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setBoardName(e.target.value)
                                                                setBoardNameFilter(e.target.value)
                                                                setLanguageName("")
                                                                setClassName("")
                                                                onBoardChange(e.target.value)
                                                            }}
                                                            required
                                                            value={boardNameFilter}
                                                            name='board_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {boardList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{videoID === "" ? "Select" : "Edit"} Language</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setLanguageName(e.target.value)
                                                                setLanguageNameFilter(e.target.value)
                                                                setClassName("")
                                                                onLanguageChange(e.target.value)
                                                            }}
                                                            required
                                                            value={languageNameFilter}
                                                            name='language_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {languageList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{videoID === "" ? "Select" : "Edit"} Class</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setClassName(e.target.value)
                                                                setClassNameFilter(e.target.value)
                                                                onClassChange(e.target.value)
                                                            }}
                                                            required
                                                            value={classNameFilter}
                                                            name='class_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {classList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-1 col-sm-12 d-flex justify-content-center align-items-center">
                                                    <button type="submit" className="btn btn-primary">Filter</button>
                                                </div>
                                                <div className="col-md-1 col-sm-12">
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <ThemeProvider theme={defaultMaterialTheme}>
                                        <MaterialTable
                                            icons={tableIcons}
                                            title="Videos"
                                            columns={columns}
                                            data={data}
                                            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                            options={{
                                                exportButton: true,
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    backgroundColor: 'rgb(0 123 255)',
                                                    color: '#FFF',
                                                    fontSize: 18
                                                },
                                                rowStyle: (rowData, index) => ({
                                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                                }),
                                            }}
                                            actions={[
                                                {
                                                    icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                                                    tooltip: 'Edit',
                                                    onClick: (event, rowData) => handleEdit(rowData),
                                                },
                                                rowData => ({
                                                    icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                                                    tooltip: 'Delete',
                                                    onClick: (event, rowData) => handleDelete(rowData),
                                                })
                                            ]}
                                        />
                                    </ThemeProvider>

                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </div>

            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
        </div>
    )
}

export default Videos