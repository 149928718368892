import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { getApiCall, postFormApiCall } from '../utils/api';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import tableIcons from './component/MaterialIcon';

const Notification = () => {
    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false)

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [imageLink, setImageLink] = useState("")
    const [data, setData] = useState([])

    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        setLoading(true)
        const res = await getApiCall("notification/get/all")
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
        setLoading(false)
    }

    const columns = [
        { title: "Sl. no", field: "serial" },
        {
            title: "Image", field: "image_link",
            render: (rowData) => {
                return (
                    <>
                        <img src={rowData.image_link} height={50} width={50} /></>
                )
            }
        },
        { title: "Title", field: "title" },
        { title: "Description", field: "description" },
    ]

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const formData = new FormData();        
        formData.append('title', title);
        formData.append('description', description);
        formData.append('link', imageLink);

        let res = await postFormApiCall("user/notification", formData)
        setLoading(false)

        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });

        setTitle("")
        setDescription("")
        setImageLink("")
    }
    return (
        <div>
            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
            <div className='content-wrapper'>

                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">Notification</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Notification</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Send Notification</h3>
                                    </div>

                                    <form onSubmit={handleSubmit}>
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">Title</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => { setTitle(e.target.value) }}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Enter Title"
                                                            value={title}
                                                            ref={inputRef}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-8 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">Description</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => { setDescription(e.target.value) }}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Enter Description"
                                                            value={description}
                                                            ref={inputRef}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-8 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">Image Link</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => { setImageLink(e.target.value) }}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Enter Link"
                                                            value={imageLink}
                                                            ref={inputRef}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary">Send</button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                <section className="content">
                    <div className="container-fluid">
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable
                                icons={tableIcons}
                                title="States"
                                columns={columns}
                                data={data}
                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                options={{
                                    exportButton: true,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        backgroundColor: 'rgb(0 123 255)',
                                        color: '#FFF',
                                        fontSize: 18
                                    },
                                    rowStyle: (rowData, index) => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                    }),
                                }}
                                actions={[
                                    // {
                                    //     icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                                    //     tooltip: 'Edit',
                                    //     onClick: (event, rowData) => handleEdit(rowData),
                                    // },
                                    // rowData => ({
                                    //     icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                                    //     tooltip: 'Delete',
                                    //     onClick: (event, rowData) => handleDelete(rowData),
                                    // })
                                ]}
                            />
                        </ThemeProvider>
                    </div>
                </section>

            </div>
        </div >

    )
}

export default Notification