import React from 'react'

const DeleteAccount = () => {
    return (
        <div>
            <h1>Delete Your Account</h1>

            <ol>
                <li><strong>Login to Your Account:</strong> Access your account by entering your credentials on the login page.</li>

                <li><strong>Navigate to Your Profile:</strong> Once logged in, go to your profile section. This is typically located in the upper-right corner of the screen.</li>

                <li><strong>Click "Delete My Account":</strong> Within your profile settings, look for the option labeled "Delete My Account." Click on this option to initiate the account deletion process.</li>

                <li><strong>Confirm Deletion:</strong> A confirmation prompt will appear. Review the information provided and confirm your decision to delete your account.</li>

                <li><strong>Hurray, Your Account is Deleted:</strong> After confirming, your account will be successfully deleted. You will no longer have access to the platform using that account.</li>
            </ol>

            <p>Please note that the steps may vary slightly depending on the platform or service you are using. If you encounter any difficulties, refer to the platform's official help or support documentation for more specific instructions.</p>


        </div >
    )
}

export default DeleteAccount