import React, { useEffect, useRef, useState } from 'react'
import { BASE_URl, MAIN_URL, getApiCall, postApiCall, postFormApiCall } from '../../utils/api';
import MaterialTable from 'material-table';
import { ThemeProvider, createTheme } from '@mui/material';
import { Delete, Edit } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import tableIcons from '../component/MaterialIcon';
import { Checkbox } from '@material-ui/core';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

const Module = () => {
    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false)

    const [isChecked, setIsChecked] = useState(false);

    const [data, setData] = useState([])
    const [moduleName, setModuleName] = useState("")

    const [moduleID, setmoduleID] = useState("")

    const [file, setFile] = useState(null);

    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        setLoading(true)

        const res = await getApiCall("module/get/all")
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
        setLoading(false)
    }

    const fileData = (event) => {
        setFile(event.target.files[0]);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        let formData = new FormData();

        formData.append('name', moduleName);
        formData.append('id', moduleID);
        formData.append('status', isChecked);
        formData.append('icon', file);
        let res = {}
        if (moduleID === "") {
            res = await postFormApiCall("module/add", formData)
        } else {
            res = await postFormApiCall("module/update", formData)
        }
        setLoading(false)
        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });
        setModuleName("")
        setmoduleID("")
        setIsChecked(false)
        setFile(null)
        fetchData()
    }

    const handleStatusChange = async (id, status) => {
        setLoading(true)
        const res = await postApiCall("module/status/update", { "id": id, "status": status })
        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        setLoading(false)
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });
        fetchData()
    }

    const columns = [
        { title: "Sl. no", field: "serial" },
        {
            title: "Icon", field: "module_icon",
            render: (rowData) => {
                return (
                    <>
                        <img src={MAIN_URL + rowData.module_icon} height="80" width="80" alt='ICON' />
                    </>
                )
            }
        },
        { title: "Name", field: "name" },
        {
            title: "Status", field: "status",
            render: (rowData) => (
                <label>
                    <span>{rowData.status === true ? "Active" : "InActive"}</span>
                </label>
            )
        },
        // {
        //     title: "Status", field: "status",
        //     render: (rowData) => (
        //         <label>
        //             <input
        //                 type="checkbox"
        //                 checked={rowData.status}
        //                 onChange={(e) => handleStatusChange(rowData.id, e.target.checked)}
        //             />
        //             <span className='active-status'>{rowData.status === true ? "Active" : "InActive"}</span>
        //         </label>
        //     )
        // },
    ]

    const handleEdit = async (rowData) => {
        setModuleName(rowData.name)
        setmoduleID(rowData.id)
        setIsChecked(rowData.status)

        inputRef.current.focus();
        const input = document.getElementById('stateInput');
        input.classList.add('glow');
        input.focus();
        setTimeout(() => {
            input.classList.remove('glow');
        }, 1000);
    }
    const handleDelete = async (rowData) => {
        new Swal({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await postApiCall("state/delete", { "id": rowData.id })
                if (res.error === true) {
                    return Swal.fire({
                        title: 'Error',
                        text: res.message,
                        icon: 'error'
                    });
                }
                Swal.fire({
                    title: 'Successful',
                    text: res.message,
                    icon: 'success'
                });
                fetchData()
            }
        })

    }


    return (
        <div>
            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
            <div className='content-wrapper'>

                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">Module</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Module</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>

                {/* <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">{moduleID === "" ? "Add" : "Edit"} Module</h3>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="card-body row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="stateInput">{moduleID === "" ? "Add" : "Edit"} Module</label>
                                                <input
                                                    type="text"
                                                    onChange={(e) => setModuleName(e.target.value)}
                                                    className="form-control"
                                                    id="stateInput"
                                                    placeholder="Enter State"
                                                    value={moduleName}
                                                    ref={inputRef}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label htmlFor="classInput">{moduleID === "" ? "Add" : "Edit"} Icon</label>
                                                <div class="input-group">
                                                    <input type="file" onChange={fileData} />
                                                </div>
                                            </div>
                                        </div>

                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={(e) => setIsChecked(e.target.checked)}
                                            />
                                            <span className='active-status'>Active</span>
                                        </label>

                                    </div>
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary">{moduleID === "" ? "Submit" : "Update"}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

                <section className="content mt-5">
                    <div className="container-fluid">
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable
                                icons={tableIcons}
                                title="Modules"
                                columns={columns}
                                data={data}
                                // onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                options={{
                                    exportButton: true,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        backgroundColor: 'rgb(0 123 255)',
                                        color: '#FFF',
                                        fontSize: 18
                                    },
                                    // rowStyle: (rowData, index) => ({
                                    //     backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                    // }),
                                }}
                                actions={[
                                    // {
                                    //     icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                                    //     tooltip: 'Edit',
                                    //     onClick: (event, rowData) => handleEdit(rowData),
                                    // },
                                    // rowData => ({
                                    //     icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                                    //     tooltip: 'Delete',
                                    //     onClick: (event, rowData) => handleDelete(rowData),
                                    // })
                                ]}
                            />
                        </ThemeProvider>
                    </div>
                </section>

            </div>
        </div>
    )
}

export default Module