import React, { useEffect, useRef, useState } from 'react'
import { MAIN_URL, getApiCall, postFormApiCall } from '../../utils/api';
import { Typography } from '@mui/material';
import { ChevronRight } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link, useSearchParams } from 'react-router-dom';
import { IconButton, Card, CardMedia, CardContent } from '@material-ui/core';
import ViewSpokenSentence from '../component/ViewSpokenSentence';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

const SpokenSentence = () => {

  const inputRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const viewprofile = searchParams.get("category_id") || false
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState([])
  const [sentenceCategoryID, setSentenceCategoryID] = useState("")

  const [name, setName] = useState("")
  const [file, setFile] = useState(null);
  useEffect(() => {
    fetchData()
  }, [])
  const fetchData = async () => {
    setLoading(true)
    const res = await getApiCall("sentenceCategory/get/all")
    setData(res.data);
    res.data.forEach((data, index) => { data.serial = index + 1; });
    setLoading(false)
  }
  const imageFileInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const formData = new FormData();
    formData.append('id', sentenceCategoryID);
    formData.append('name', name);
    formData.append('thumbnail_path', file);

    let res = {}
    if (sentenceCategoryID === "") {
      res = await postFormApiCall("sentenceCategory/add", formData)
    } else {
      res = await postFormApiCall("sentenceCategory/update", formData)
    }
    setLoading(false)
    if (res.error === true) {
      return Swal.fire({
        title: 'Error',
        text: res.message,
        icon: 'error'
      });
    }
    Swal.fire({
      title: 'Successful',
      text: res.message,
      icon: 'success'
    });
    if (imageFileInputRef.current) {
      imageFileInputRef.current.value = '';
    }
    setSentenceCategoryID("")
    setName("")
    setFile(null)

    fetchData()
  }


  return !viewprofile ? (
    <div>
      <CircleSpinnerOverlay
        loading={loading}
        overlayColor="rgba(0,153,255,0.2)"
      />
      <div className='content-wrapper'>

        <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h4 className="m-0">Spoken Sentence</h4>
              </div>{/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item active">Spoken Sentence</li>
                </ol>
              </div>{/* /.col */}
            </div>{/* /.row */}
          </div>{/* /.container-fluid */}
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12 mt-4">
                {/* general form elements */}
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">{sentenceCategoryID === "" ? "Add" : "Edit"} Category</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="row">

                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="classInput">{sentenceCategoryID === "" ? "Add" : "Edit"} Category</label>
                            <input
                              type="text"
                              onChange={(e) => setName(e.target.value)}
                              className="form-control"
                              id="classInput"
                              placeholder="Enter Category"
                              value={name}
                              ref={inputRef}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div class="form-group">
                            <label htmlFor="classInput">{sentenceCategoryID === "" ? "Add" : "Edit"} Thumbnail</label>
                            <div class="input-group">
                              <input type="file" accept="image/*" ref={imageFileInputRef} onChange={(e) => setFile(e.target.files[0])} />
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">{sentenceCategoryID === "" ? "Submit" : "Update"}</button>
                    </div>
                  </form>

                </div>

              </div>
              {/* /.card */}
            </div>
            {/*/.col (right) */}
          </div>
          {/* /.row */}
        </section >

        <section className="content">
          <div className="container-fluid">
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
              {data.map((card) => (
                <Card key={card.id} style={{ marginBottom: '1rem', width: "200px", marginLeft: 7 }} onClick={() => {
                  window.location =
                    "?category_id=" + card.id
                }}>
                  <CardMedia
                    component="img"
                    height="200px"
                    image={MAIN_URL + card.thumbnail_path}
                    alt={card.name}
                  />
                  <CardContent style={{ backgroundColor: 'rebeccapurple', color: "white" }}>
                    <Typography gutterBottom variant="p" component="p" sx={{ fontSize: 20 }}>
                      {card.name}
                    </Typography>
                    <Typography gutterBottom variant="p" component="p" sx={{ fontSize: 15 }}>
                      {card.name}
                    </Typography>
                    <IconButton
                      // onClick={()=> console.log("asd")}
                      style={{ color: "white", padding: 0, float: "right", bottom: 5 }}
                    >
                      <ChevronRight />
                    </IconButton>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

      </div>
    </div>
  )
    :
    <ViewSpokenSentence category_id={viewprofile} />
}

export default SpokenSentence