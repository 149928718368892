import React, { useEffect, useRef, useState } from 'react'
import MaterialTable from 'material-table';
import { Box, Modal, ThemeProvider, Typography, createTheme } from '@mui/material';
import { Delete, Edit, ViewArray } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { MAIN_URL, getApiCall, postApiCall, postFormApiCall } from '../../utils/api';
import tableIcons from '../component/MaterialIcon';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Subject = () => {
    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false)

    const [isChecked, setIsChecked] = useState(true);
    const [data, setData] = useState([]);
    const [subjectName, setSubjectName] = useState("")
    const [subjectID, setSubjectID] = useState("")
    const [scriptName, setScriptName] = useState("")
    const [boardList, setBoardList] = useState([])
    const [classList, setClassList] = useState([])
    const [mediumList, setMediumList] = useState([])
    const [boardName, setBoardName] = useState("")

    const [mediumName, setMediumName] = useState("")
    const [className, setClassName] = useState("")
    const [file, setFile] = useState(null);

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        setLoading(true)

        const boardres = await getApiCall("board/get/all")
        setBoardList(boardres.data.length !== 0 && boardres.data);

        const res = await getApiCall("subject/get/all")
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
        setLoading(false)
    }

    const onBoardChange = async (val) => {
        setLoading(true)

        // const classRes = await postApiCall("class/get/byBoard", { "id": val })
        // setClassList(classRes.data);

        const mediumRes = await postApiCall("language/get/byBoard", { "id": val })
        setMediumList(mediumRes.data);

        setLoading(false)
    }

    const onMediumChange = async (val) => {
        setLoading(true)

        const classRes = await postApiCall("class/get/byBoardAndMedium", {
            "board_id": boardName,
            "medium_id": val
        })
        setClassList(classRes.data);

        setLoading(false)
    }

    const fileData = (event) => {
        setFile(event.target.files[0]);
    }
    const imageFileInputRef = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true)

        const formData = new FormData();

        formData.append('name', subjectName);
        formData.append('in_script', scriptName);
        formData.append('board_id', boardName);
        formData.append('class_id', className);
        formData.append('medium_id', mediumName);
        formData.append('status', isChecked);
        formData.append('id', subjectID);
        formData.append('image', file);

        let res = {}
        if (subjectID === "") {
            res = await postFormApiCall("subject/add", formData)
        } else {
            res = await postFormApiCall("subject/update", formData)
        }

        setLoading(false)

        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });

        e.target.reset();
        if (imageFileInputRef.current) {
            imageFileInputRef.current.value = '';
        }
        setSubjectName("")
        setSubjectID("")
        // setBoardName("")
        // setMediumName("")
        // setClassName("")
        setScriptName("")
        setIsChecked(false)
        setFile(null)
        fetchData()
    }

    const [open, setOpen] = useState(false)
    const [tableClassList, setTableClassList] = useState([])
    const [tableBoardList, setTableBoardList] = useState([])
    const [tableBoardName, setTableBoardName] = useState("")
    const [tableMediumList, setTableMediumList] = useState([])
    const [tableMediumdName, setTableMediumdName] = useState("")
    const [tableSubjectName, setTableSubjectName] = useState("")

    const columns = [
        {
            title: "Sl. no", field: "serial", cellStyle: {
                width: "10%"
            }
        },
        {
            title: "Image", field: "sub_image",
            render: (rowData) => {
                return (
                    <>
                        <img src={MAIN_URL + rowData.sub_image} height={70} width={70} /></>
                )
            }
        },
        {
            title: "Subject", field: "name",
            render: (rowData) => {
                return (
                    <>
                        <p
                        // className="clickable"
                        // style={{
                        //     color: "blue"
                        // }}
                        // onClick={() => {
                        //     setTableSubjectName(rowData.name)
                        //     handleBoardClick(rowData.name)
                        // }}
                        >
                            {rowData.name}
                        </p>
                    </>
                );
            },
        },
        { title: "Board", field: "board_name" },
        { title: "Class", field: "class_name" },
        { title: "Language", field: "language_name" },
        {
            title: "Status", field: "status",
            render: (rowData) => {
                return (
                    <>
                        {rowData.status === 1 ? "Active" : "InActive"}
                    </>
                )
            }
        },

    ];

    // Handle click on board name
    const handleBoardClick = async (subName) => {
        const classRes = await postApiCall("board/get/getBoardBySubjectName", { subject_name: subName });
        setTableBoardList(classRes.data);
        setOpen(true)
    };

    const onTableBoardChange = async (val) => {
        setLoading(true)

        const classRes = await postApiCall("language/get/byBoard", { "id": val })
        setTableMediumList(classRes.data);

        setLoading(false)
    }

    const onTableMediumChange = async (val) => {
        setLoading(true)

        const classRes = await postApiCall("class/get/byBoardAndSubjectAndMedium", { "medium_id": val, "board_id": tableBoardName, "subject_name": tableSubjectName })
        setTableClassList(classRes.data)

        setLoading(false)
    }

    const handleEdit = async (rowData) => {
        setSubjectID(rowData.id)

        setSubjectName(rowData.name)
        setBoardName(rowData.board_id)
        setIsChecked(rowData.status)

        setScriptName(rowData.in_script)

        onBoardChange(rowData.board_id)

        setMediumName(rowData.medium_id)
        setClassName(rowData.class_id)

        inputRef.current.focus();
        const input = document.getElementById('boardInput');
        input.classList.add('glow');
        input.focus();
        setTimeout(() => {
            input.classList.remove('glow');
        }, 1000);
    }

    const handleDelete = async (rowData) => {
        new Swal({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await postApiCall("board/delete", { "id": rowData.id })
                if (res.error === true) {
                    return Swal.fire({
                        title: 'Error',
                        text: res.message,
                        icon: 'error'
                    });
                }
                Swal.fire({
                    title: 'Successful',
                    text: res.message,
                    icon: 'success'
                });
                fetchData()
            }
        })

    }


    return (
        <>
            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
            <Modal
                open={open}
                onClose={() => {
                    setOpen(false)
                    setTableBoardList([])
                    setTableMediumList([])
                    setTableClassList([])
                    setTableBoardName("")
                    setTableMediumdName("")
                    setTableSubjectName("")
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography className='font-weight-bold' id="modal-modal-title" variant="h6" component="h2">
                        Details
                    </Typography>
                    <div className="row">

                        <div className="col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="selectInput">{subjectID === "" ? "Select" : "Edit"} Board</label>
                                <select
                                    class="custom-select form-select rounded-0"
                                    id="selectInput"
                                    onChange={(e) => {
                                        setTableBoardName(e.target.value)
                                        setTableMediumdName("")
                                        setTableClassList([])
                                        onTableBoardChange(e.target.value)
                                    }}
                                    required
                                    value={tableBoardName}
                                >
                                    <option value="" disabled selected>
                                        Select an option
                                    </option>
                                    {tableBoardList.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="selectInput">{subjectID === "" ? "Select" : "Edit"} Board</label>
                                <select
                                    class="custom-select form-select rounded-0"
                                    id="selectInput"
                                    onChange={(e) => {
                                        setTableMediumdName(e.target.value)
                                        setTableClassList([])

                                        onTableMediumChange(e.target.value)
                                    }}
                                    required
                                    value={tableMediumdName}
                                >
                                    <option value="" disabled selected>
                                        Select an option
                                    </option>
                                    {tableMediumList.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <h4>Classes</h4>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>No.</TableCell>
                                    <TableCell align="right">Class</TableCell>
                                    <TableCell align="right">Image</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableClassList.map((item, index) => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align="right">
                                            {item.newData.name}
                                        </TableCell>
                                        <TableCell align="right">
                                            <img src={MAIN_URL + item.sub_image} height={80} width={80} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Box>
            </Modal>
            <div className='content-wrapper'>
                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">Subjects</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Subject</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* left column */}
                            <div className="col-md-12 mt-4">
                                {/* general form elements */}
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">{subjectID === "" ? "Add" : "Edit"} class</h3>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-md-4 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{subjectID === "" ? "Select" : "Edit"} Board</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setBoardName(e.target.value)
                                                                setMediumName("")
                                                                setClassName("")
                                                                onBoardChange(e.target.value)
                                                            }}
                                                            required
                                                            value={boardName}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {boardList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{subjectID === "" ? "Select" : "Edit"} Medium</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setMediumName(e.target.value)
                                                                setClassName("")
                                                                onMediumChange(e.target.value)
                                                            }}
                                                            required
                                                            value={mediumName}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {mediumList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{subjectID === "" ? "Select" : "Edit"} Class</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setClassName(e.target.value)
                                                            }}
                                                            required
                                                            value={className}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {classList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>




                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="classInput">{subjectID === "" ? "Add" : "Edit"} Subject Name</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setSubjectName(e.target.value)}
                                                            className="form-control"
                                                            id="classInput"
                                                            placeholder="Enter Subject Name"
                                                            value={subjectName}
                                                            ref={inputRef}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="classInput">{subjectID === "" ? "Add" : "Edit"} Subject Name (In Script)</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setScriptName(e.target.value)}
                                                            className="form-control"
                                                            id="classInput"
                                                            placeholder="In Script"
                                                            value={scriptName}
                                                            ref={inputRef}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div class="form-group">
                                                        <label htmlFor="classInput">{subjectID === "" ? "Add" : "Edit"} image</label>
                                                        <div class="input-group">
                                                            <input type="file" accept="image/*" ref={imageFileInputRef} onChange={fileData} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={(e) => setIsChecked(e.target.checked)}
                                                    />
                                                    <span className='active-status'>Active</span>
                                                </label>

                                            </div>


                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary">{subjectID === "" ? "Submit" : "Update"}</button>
                                        </div>
                                    </form>

                                </div>

                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (right) */}
                    </div>
                    {/* /.row */}
                </section >

                <section className="content mt-4">
                    <div className="container-fluid">
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable
                                icons={tableIcons}
                                title="Subjects"
                                columns={columns}
                                data={data}
                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                options={{
                                    exportButton: true,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        backgroundColor: 'rgb(0 123 255)',
                                        color: '#FFF',
                                        fontSize: 18
                                    },
                                    rowStyle: (rowData, index) => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                    }),
                                }}
                                actions={[
                                    {
                                        icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                                        tooltip: 'Edit',
                                        onClick: (event, rowData) => handleEdit(rowData),
                                    },
                                    // rowData => ({
                                    //     icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                                    //     tooltip: 'Delete',
                                    //     onClick: (event, rowData) => handleDelete(rowData),
                                    // })
                                ]}

                            />
                        </ThemeProvider>
                    </div>
                </section>

            </div>
        </>
    )
}

export default Subject