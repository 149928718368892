import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table';
import tableIcons from './component/MaterialIcon';
import { ThemeProvider, createTheme } from '@mui/material';
import { getApiCall, postApiCall } from '../utils/api';
import { Link } from 'react-router-dom';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import Swal from 'sweetalert2';

const User = () => {
  const defaultMaterialTheme = createTheme();
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const [searchData, setSearchData] = useState("")


  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    const res = await getApiCall("user/get/users")
    setData(res.data);
    res.data.forEach((data, index) => { data.serial = index + 1; });
    setLoading(false)
  }

  const [state, setState] = useState("");

  const handleToggleActivation = async (status, item) => {

    new Swal({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await postApiCall("user/package/active/update", {
          "id": item.id,
          "status": status,
        })
        if (res.error === true) {
          return Swal.fire({
            title: 'Error',
            text: res.message,
            icon: 'error'
          });
        }
        Swal.fire({
          title: 'Successful',
          text: res.message,
          icon: 'success'
        });
        fetchData()
      }
    })

    // const res = await postApiCall("user/package/active/update", {
    //   "id": item.id,
    //   "status": status.toString(),
    // })

    // if (res.error === true) {
    //   return Swal.fire({
    //     title: 'Error',
    //     text: res.message,
    //     icon: 'error'
    //   });
    // }
    // Swal.fire({
    //   title: 'Successful',
    //   text: res.message,
    //   icon: 'success'
    // });

  };

  const columns = [
    // { title: "Sl. no", field: "serial" },
    { title: "Name", field: "name" },
    { title: "Phone", field: "phone" },
    { title: "Email", field: "email" },
    {
      title: "Board", field: "profileData", render: (rowData) => {
        return (
          <>
            <div>{rowData.profileData && rowData.profileData.board_name}</div>
          </>
        )
      }
    },
    {
      title: "Language", field: "language_name", render: (rowData) => {
        return (
          <>
            {rowData.profileData && rowData.profileData.language_name}
          </>
        )
      }
    },
    {
      title: "Package", field: "packageData", cellStyle: {
        width: "50%"
      }, render: (rowData) => {
        return (
          <>
            {rowData.packageData && rowData.packageData.map((item, index) => (
              <div key={index}>
                {index + 1}:{item.package_name}
                <button style={{ width: "100px", marginLeft: "20px", fontSize: "12px" }} className={item.original_payment_id !== null ? 'btn btn-sm bg-success' : 'btn btn-sm bg-danger'} onClick={() => handleToggleActivation(item.original_payment_id === null ? '0' : '1', item)}>
                  {item.original_payment_id === null ? 'Active' : 'Deactive'}
                </button>
                <br />
                <br />
              </div>
            ))}
          </>
        )
      }
    },
  ]

  const handleSearch = async () => {
    if (searchData === "")
      return Swal.fire({
        title: 'Error',
        text: 'Please enter a Phone number to search',
        icon: 'error'
      });
    setLoading(true)
    const res = await postApiCall("user/get/search/users", {
      "phone": searchData,
    })
    setLoading(false)
    if (res.error === true)
      return Swal.fire({
        title: 'Error',
        text: res.message,
        icon: 'error'
      });
    setData(res.data);
    res.data.forEach((data, index) => { data.serial = index + 1; });
  }
  return (
    <div>
      <CircleSpinnerOverlay
        loading={loading}
        overlayColor="rgba(0,153,255,0.2)"
      />
      <div className="content-wrapper">

        <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h4 className="m-0">Users</h4>
              </div>{/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item active">Users</li>
                </ol>
              </div>{/* /.col */}
            </div>{/* /.row */}
          </div>{/* /.container-fluid */}
        </div>




        <section className="content mt-4">
          <div className="container-fluid">

            <div className="row">
              <div className="col-md-4 mt-4">
                {/* <label htmlFor="searchData">Search By Phone Number</label> */}
                <input
                  type="text"
                  onChange={(e) => setSearchData(e.target.value)}
                  className="form-control"
                  id="searchData"
                  placeholder="Enter Phone number"
                  value={searchData}
                />
              </div>
              <div className="col-md-4 mt-4">
                <button type="submit" onClick={() => handleSearch()} className="btn btn-primary">Search</button>
              </div>
            </div>

            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                icons={tableIcons}
                title="Users"
                columns={columns}
                data={data}
                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                options={{
                  exportButton: true,
                  actionsColumnIndex: -1,
                  headerStyle: {
                    backgroundColor: 'rgb(0 123 255)',
                    color: '#FFF',
                    fontSize: 18
                  },
                  rowStyle: (rowData, index) => ({
                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                  }),
                }}
              />
            </ThemeProvider>
          </div>
        </section>
      </div >
    </div >
  )
}

export default User



