import React, { useEffect, useRef, useState } from 'react'
import { getApiCall, postApiCall, postFormApiCall } from '../../utils/api';
import MaterialTable from 'material-table';
import tableIcons from '../component/MaterialIcon';
import { Box, Modal, ThemeProvider, Typography, createTheme } from '@mui/material';
import { Add, CheckCircleOutline, CloudDownloadOutlined, Delete, DeleteOutline, Edit, StoreOutlined, VideoLabel, ViewColumn, Visibility } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { Button, Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import { style } from './SpokenAlphabet';
import TextComponent from '../component/mcqComponent/TextComponent';
import LinkComponent from '../component/mcqComponent/LinkComponent';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import moment from 'moment';

export const addStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    height: "97%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
};

const typeList = [
    { 'id': "1", "value": "Text" },
    { 'id': "2", "value": "Link" },
    { 'id': "3", "value": "Text/Image" },
]
const answersList = [
    { 'id': "1", "value": "option1" },
    { 'id': "2", "value": "option2" },
    { 'id': "3", "value": "option3" },
    { 'id': "4", "value": "option4" },
]

const Quiz = () => {

    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false)

    const [data, setData] = useState([])

    const [boardList, setBoardList] = useState([])
    const [languageList, setLanguageList] = useState([])
    const [classList, setClassList] = useState([])
    const [subjectList, setSubjectList] = useState([])
    const [chapterList, setChapterList] = useState([])

    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")

    const [boardName, setBoardName] = useState("")
    const [languageName, setLanguageName] = useState("")
    const [className, setClassName] = useState("")
    const [subjectName, setSubjectName] = useState("")
    const [chapterName, setChapterName] = useState("")

    const [selectedTypeFor, setSlectedTypeFor] = useState('');

    const [title, setTitle] = useState("")
    const [duration, setDuration] = useState("")

    const [quizId, setQuizId] = useState("")

    const [open, setOpen] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)

    const [excelData, setExcelData] = useState([]);
    const [excelFile, setExcelFile] = useState(null);

    // Main Values
    const [questions, setQuestions] = useState([]);

    const [questionType, setQuestionType] = useState("")
    const [questionTextValue, setQuestionTextValue] = useState("")
    const [questionLinkValue, setQuestionLinkValue] = useState("")

    const [option1Type, setOption1Type] = useState("")
    const [option1TextValue, setOption1TextValue] = useState("")
    const [option1LinkValue, setOption1LinkValue] = useState("")

    const [option2Type, setOption2Type] = useState("")
    const [option2TextValue, setOption2TextValue] = useState("")
    const [option2LinkValue, setOption2LinkValue] = useState("")

    const [option3Type, setOption3Type] = useState("")
    const [option3TextValue, setOption3TextValue] = useState("")
    const [option3LinkValue, setOption3LinkValue] = useState("")

    const [option4Type, setOption4Type] = useState("")
    const [option4TextValue, setOption4TextValue] = useState("")
    const [option4LinkValue, setOption4LinkValue] = useState("")

    const [answerValue, setAnswerValue] = useState("")

    const [contentList, setContentList] = useState([])
    const [checkedVideoItems, setCheckedVideoItems] = useState([]);


    const [imageFile, setImageFile] = useState(null)
    const imageFileInputRef = useRef(null);


    useEffect(() => {
        fetchData()
    }, [])

    const formRef = useRef();
    const fetchData = async () => {
        setLoading(true)
        const boardRes = await getApiCall("board/get/all")
        setBoardList(boardRes.data);

        const res = await getApiCall("quiz/get/all");

        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
        setLoading(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const formData = new FormData();
        formData.append('set_title', title);
        formData.append('board_id', boardName);
        formData.append('language_id', languageName);
        formData.append('class_id', className);
        formData.append('duration', duration);
        formData.append('starts', startTime);
        formData.append('ends', endTime);
        formData.append('quiz_set_id', quizId);
        formData.append('image', imageFile);

        let res = {}

        if (excelFile !== null) {
            formData.append('file', excelFile);
            res = await postFormApiCall("quiz/add/excel", formData)
            setExcelFile(null)
        } else {
            if (questions.length === 0) {
                setLoading(false)
                return Swal.fire({
                    title: 'Error',
                    text: "Add Question",
                    icon: 'error'
                });
            }
            formData.append('questions', JSON.stringify(questions));

            res = await postFormApiCall("quiz/add", formData)
        }

        setLoading(false)
        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });

        if (imageFileInputRef.current) {
            imageFileInputRef.current.value = '';
        }
        setQuizId("")
        setStartTime('')
        setEndTime('')

        // setBoardName("")
        // setLanguageName("")
        // setClassName("")

        setExcelFile(null)
        setExcelData([])

        setTitle("")
        setDuration("")

        setQuestions([])
        fetchData()
    }

    const handleFileUpload = (e) => {

        setExcelFile(e.target.files[0])

        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const jsonData = XLSX.utils.sheet_to_json(ws, { header: 1, defval: "" });
            setExcelData(jsonData);
        };
        reader.readAsBinaryString(file);
    };

    const onBoardChange = async (val) => {
        setLoading(true)
        const boardRes = await postApiCall("language/get/byBoard", { "id": val })
        setLanguageList(boardRes.data);
        setLoading(false)
    }
    const onLanguageChange = async (val) => {
        setLoading(true)
        const langResponse = await postApiCall("class/get/byBoardAndMedium", { "board_id": boardName, "medium_id": val, })
        setClassList(langResponse.data);
        setLoading(false)
    }
    const onClassChange = async (val) => {
        setLoading(true)
        const classRes = await postApiCall("subject/get/byBoardClassAndMedium", { "board_id": boardName, "class_id": val, "medium_id": languageName, })
        setSubjectList(classRes.data);
        setLoading(false)
    }
    const onSubjectChange = async (val) => {
        setLoading(true)
        const chapterRes = await postApiCall("chapter/get/name/byBoardMediumClassAndSubject", { "board_id": boardName, "class_id": className, "language_id": languageName, "subject_id": val })
        setChapterList(chapterRes.data);
        setLoading(false)
    }

    const [isTypeQuestionText, setIsTypeQuestionText] = useState(false)
    const [isTypeQuestionLink, setIsTypeQuestionLink] = useState(false)

    const [isTypeOption1Text, setIsTypeOption1Text] = useState(false)
    const [isTypeOption1Link, setIsTypeOption1Link] = useState(false)

    const [isTypeOption2Text, setIsTypeOption2Text] = useState(false)
    const [isTypeOption2Link, setIsTypeOption2Link] = useState(false)

    const [isTypeOption3Text, setIsTypeOption3Text] = useState(false)
    const [isTypeOption3Link, setIsTypeOption3Link] = useState(false)

    const [isTypeOption4Text, setIsTypeOption4Text] = useState(false)
    const [isTypeOption4Link, setIsTypeOption4Link] = useState(false)

    const [isQuestionType, setIsQuestionType] = useState(false)
    const [isOption1Type, setIsOption1Type] = useState(false)
    const [isOption2Type, setIsOption2Type] = useState(false)
    const [isOption3Type, setIsOption3Type] = useState(false)
    const [isOption4Type, setIsOption4Type] = useState(false)



    const onTypeChange = async (typeVal, val) => {
        if (typeVal === '1') {
            if (val === 'Q') {
                setIsTypeQuestionLink(false)
                setIsTypeQuestionText(true)
                setIsQuestionType(true)
            }
            if (val === 'O1') {
                setIsTypeOption1Link(false)
                setIsTypeOption1Text(true)
                setIsOption1Type(true)
            }
            if (val === 'O2') {
                setIsTypeOption2Link(false)
                setIsTypeOption2Text(true)
                setIsOption2Type(true)
            }
            if (val === 'O3') {
                setIsTypeOption3Link(false)
                setIsTypeOption3Text(true)
                setIsOption3Type(true)
            }
            if (val === 'O4') {
                setIsTypeOption4Link(false)
                setIsTypeOption4Text(true)
                setIsOption4Type(true)
            }
        }
        else if (typeVal === '2') {
            if (val === 'Q') {
                setIsTypeQuestionText(false)
                setIsTypeQuestionLink(true)
                setIsQuestionType(true)
            }
            if (val === 'O1') {
                setIsTypeOption1Text(false)
                setIsTypeOption1Link(true)
                setIsOption1Type(true)
            }
            if (val === 'O2') {
                setIsTypeOption2Text(false)
                setIsTypeOption2Link(true)
                setIsOption2Type(true)
            }
            if (val === 'O3') {
                setIsTypeOption3Text(false)
                setIsTypeOption3Link(true)
                setIsOption3Type(true)
            }
            if (val === 'O4') {
                setIsTypeOption4Text(false)
                setIsTypeOption4Link(true)
                setIsOption4Type(true)
            }
        }
        else if (typeVal === '3') {
            if (val === 'Q') {
                setIsTypeQuestionText(true)
                setIsTypeQuestionLink(true)
                setIsQuestionType(true)
            }
            if (val === 'O1') {
                setIsTypeOption1Link(true)
                setIsTypeOption1Text(true)
                setIsOption1Type(true)
            }
            if (val === 'O2') {
                setIsTypeOption2Link(true)
                setIsTypeOption2Text(true)
                setIsOption2Type(true)
            }
            if (val === 'O3') {
                setIsTypeOption3Link(true)
                setIsTypeOption3Text(true)
                setIsOption3Type(true)
            }
            if (val === 'O4') {
                setIsTypeOption4Link(true)
                setIsTypeOption4Text(true)
                setIsOption4Type(true)
            }
        }
    }

    const handleSetButton = () => {
        setOpenAdd(false)
        // setIsQuestionAdded(true)

        const newQuestion = {
            question: [{ question_type: questionType, question_type_text: questionTextValue, question_type_link: questionLinkValue }],
            options: [
                { option1_type: option1Type, option1_type_text: option1TextValue, option1_type_link: option1LinkValue },
                { option2_type: option2Type, option2_type_text: option2TextValue, option2_type_link: option2LinkValue },
                { option3_type: option3Type, option3_type_text: option3TextValue, option3_type_link: option3LinkValue },
                { option4_type: option4Type, option4_type_text: option4TextValue, option4_type_link: option4LinkValue },
            ],
            answer: answerValue,
        };
        setQuestions([...questions, newQuestion]);

        setQuestionType("")
        setQuestionLinkValue("")
        setQuestionTextValue("")

        setOption1Type("")
        setOption1TextValue("")
        setOption1LinkValue("")

        setOption2Type("")
        setOption2TextValue("")
        setOption2LinkValue("")

        setOption3Type("")
        setOption3TextValue("")
        setOption3LinkValue("")

        setOption4Type("")
        setOption4TextValue("")
        setOption4LinkValue("")

        setAnswerValue("")

        setIsQuestionType(false)
        setIsOption1Type(false)
        setIsOption2Type(false)
        setIsOption3Type(false)
        setIsOption4Type(false)
    }

    // view part
    const columns = [
        {
            title: "Sl. no", field: "serial", cellStyle: {
                width: "5%"
            }
        },
        { title: "Title", field: "title" },
        { title: "Board", field: "board_name" },
        { title: "Language", field: "language_name" },
        { title: "Class", field: "class_name" },
        { title: "Qestions", field: "totalQuestions" },
        {
            title: "Duration", field: "duration",
            render: (rowData) => {
                return (
                    <>
                        {rowData.duration} minutes
                    </>
                )
            }
        },
        {
            title: "Starts", field: "starts",
            render: (rowData) => {
                const dateTime = moment(rowData.starts);
                const date = dateTime.format('DD-MM-YYYY');
                const time = dateTime.format('h:mm:ss a');
                return (
                    <>
                        {date}
                        <br />
                        {time}
                    </>
                )
            }
        },
        {
            title: "Ends", field: "ends",
            render: (rowData) => {
                const dateTime = moment(rowData.ends);
                const date = dateTime.format('DD-MM-YYYY');
                const time = dateTime.format('h:mm:ss a');
                return (
                    <>
                        {date}
                        <br />
                        {time}
                    </>
                )
            }
        },
        {
            title: "Status", field: "status",
            render: (rowData) => {
                let data = ""
                const nowDT = moment(new Date());
                const starts = moment(rowData.starts);
                const ends = moment(rowData.ends);
                const now = moment(nowDT, 'DD-MM-YYYY h:mm:ss a');

                // Check if event has started
                if (now.isBefore(starts)) {
                    data = "Pending"
                }

                if (now.isAfter(ends)) {
                    data = "Ended"
                }

                if (now.isBetween(starts, ends)) {
                    data = "Ongoing"
                }

                return (
                    <>
                        {data}
                    </>
                )
            }
        },
    ]

    const [boardNameFilter, setBoardNameFilter] = useState("")
    const [languageNameFilter, setLanguageNameFilter] = useState("")
    const [classNameFilter, setClassNameFilter] = useState("")

    const handleFilter = async (e) => {
        e.preventDefault();
        setLoading(true)
        const formData = new FormData(formRef.current);
        const entries = Array.from(formData.entries());
        const formValues = entries.reduce(
            (prev, [name, value]) => ({
                ...prev,
                [name]: value,
            }),
            {}
        );

        const res = await postApiCall("quiz/get/quizByBoardAndClass", { ...formValues })
        setLoading(false)
        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }

        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });

    }

    const handleEdit = async (rowData) => {

        setMcqView(false)
        setQuestions([])
        setQuizId(rowData.id)
        setSlectedTypeFor(rowData.set_for.toString())

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        inputRef.current.focus();
        const input = document.getElementById('editpanel');
        input.classList.add('glow');
        input.focus();
        setTimeout(() => {
            input.classList.remove('glow');
        }, 2000);
    }


    const [editVideo, setEditVideo] = useState(false);
    const handleVideoEdit = async (rowData) => {
        setEditVideo(true);

        setBoardName(rowData.board_id)
        setLanguageName(rowData.language_id)
        setClassName(rowData.class_id)
        setSubjectName(rowData.subject_id)
        setChapterName(rowData.chapter_id)

        // setMcqView(false)
        // setQuestions([])
        setQuizId(rowData.id)
        // setSlectedTypeFor(rowData.set_for.toString())

        // setBoardName(rowData.board_id)
        // setLanguageName(rowData.language_id)
        // setClassName(rowData.class_id)

        // setEbookTitle(rowData.title)
        // setSubjectName(rowData.subject_id)

        // setChapterName(rowData.chapter_id)

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    const [mcqView, setMcqView] = useState(false);
    const [viewDeleteId, setViewDeleteId] = useState("");
    const viewSetQuestions = async (ids) => {
        setMcqView(true)

        setLoading(true)
        setViewDeleteId(ids)
        const res = await postApiCall("quiz/get/quizQuestionBySetId", { "id": ids })
        if (res.error === true) {
            setLoading(false)
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        setLoading(false)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        let temp = []
        for (let val of res.data) {
            // console.log("val", typeof (val.option1_type));
            const newQuestion = {
                question: [{ question_type: val.question_type.toString(), question_type_text: val.question_type_text, question_type_link: val.question_type_link }],
                options: [
                    { option1_type: val.option1_type.toString(), option1_type_text: val.option1_type_text, option1_type_link: val.option1_type_link },
                    { option2_type: val.option2_type.toString(), option2_type_text: val.option2_type_text, option2_type_link: val.option2_type_link },
                    { option3_type: val.option3_type.toString(), option3_type_text: val.option3_type_text, option3_type_link: val.option3_type_link },
                    { option4_type: val.option4_type.toString(), option4_type_text: val.option4_type_text, option4_type_link: val.option4_type_link },
                ],
                answer: val.answer,
                question_id: val.id,
            };
            temp.push(newQuestion)
            // setQuestions([...questions, newQuestion]);
        }
        setQuestions(temp);
    }

    //question delete
    const deleteQuestion = async (val) => {

        new Swal({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const res = await postApiCall("quiz/delete", { "id": val })
                if (res.error === true) {
                    return Swal.fire({
                        title: 'Error',
                        text: res.message,
                        icon: 'error'
                    });
                }
                setLoading(false)
                Swal.fire({
                    title: 'Successful',
                    text: res.message,
                    icon: 'success'
                });
                viewSetQuestions(viewDeleteId)
            }
        })

    }

    return (
        <>

            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
            {/* upload modal */}
            <Modal
                open={open}
                onClose={() => {
                    setOpen(false)
                    setExcelData([])
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{
                        backgroundColor: "#007bff",
                        padding: "10px",
                        color: "white",
                        borderRadius: 2,
                    }}>
                        <Typography className='font-weight-bold' id="modal-modal-title" variant="p" component="h5">
                            UPLOAD
                        </Typography>
                    </Box>

                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <label for="selectInput">Download Sample file</label>

                            <div class="form-group">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CloudDownloadOutlined />}
                                    onClick={(e) => {
                                        const link = document.createElement('a');
                                        link.href = '/sample/mcq.xlsx';
                                        link.download = 'mcq.xlsx';

                                        // Simulate a click on the link
                                        link.click();
                                    }}
                                >
                                    Download sample file
                                </Button>
                            </div>
                        </div>
                        <div className="col-md-12 mt-4">
                            <div className="form-group">
                                <label htmlFor="boardInput">Select an excel File</label>
                                <br />
                                <input type="file" onChange={handleFileUpload} />
                            </div>
                        </div>

                    </div>

                    <hr />

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {excelData[0] &&
                                        excelData[0].map((header, index) => (
                                            <TableCell style={{ fontWeight: 'bold' }} key={index}>{header}</TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {excelData.slice(1).map((row, index) => (
                                    <TableRow key={index}>
                                        {row.map((cell, index) => (
                                            <TableCell key={index}>{cell}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={excelData.length !== 0 ? false : true}
                                startIcon={<StoreOutlined />}
                                onClick={() => setOpen(false)}
                            >
                                Set Excel Data
                            </Button>
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-md-12 mt-4">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<FileUploadRoundedIcon />}
                                onClick={(e) => handleUploadExcel(e)}
                            >
                                Upload
                            </Button>
                        </div>
                    </div> */}

                </Box>
            </Modal>

            {/* add modal */}
            <Modal
                open={openAdd}
                onClose={() => {
                    setOpenAdd(false)
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={addStyle}>
                    <Box sx={{
                        backgroundColor: "#007bff",
                        padding: "10px",
                        color: "white",
                        borderRadius: 2,
                    }}>
                        <Typography className='font-weight-bold' id="modal-modal-title" variant="p" component="h5">
                            Add Questions
                        </Typography>
                    </Box>

                    {/* question */}
                    <h5>Question</h5>
                    <div className="col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="selectInput">Question Type</label>
                            <select
                                class="custom-select form-select rounded-0"
                                id="selectInput"
                                onChange={(e) => {
                                    setQuestionType(e.target.value)
                                    onTypeChange(e.target.value, "Q")
                                }}
                            // value={boardName}
                            >
                                <option value="" disabled selected>
                                    select type
                                </option>
                                {typeList.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.value}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {isQuestionType && (
                        <div>
                            {isTypeQuestionText && (
                                // <div className="col-md-6 col-sm-12">
                                <TextComponent textValue={questionTextValue} setTextValue={setQuestionTextValue} />
                                // </div>
                            )}
                            {isTypeQuestionLink && (
                                // <div className="col-md-6 col-sm-12">
                                <LinkComponent linkValue={questionLinkValue} setLinkValue={setQuestionLinkValue} />
                                // </div>
                            )}
                        </div>
                    )}

                    <hr style={{ backgroundColor: '#434257c4' }} />

                    {/* Option 1 */}
                    <h5>Option 1</h5>
                    <div className="col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="selectInput">Option 1 Type</label>
                            <select
                                class="custom-select form-select rounded-0"
                                id="selectInput"
                                onChange={(e) => {
                                    setOption1Type(e.target.value)
                                    onTypeChange(e.target.value, "O1")
                                }}
                            // value={boardName}
                            >
                                <option value="" disabled selected>
                                    select type
                                </option>
                                {typeList.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.value}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {isOption1Type && (
                        <div>
                            {isTypeOption1Text && (
                                <TextComponent textValue={option1TextValue} setTextValue={setOption1TextValue} />
                            )}
                            {isTypeOption1Link && (
                                <LinkComponent linkValue={option1LinkValue} setLinkValue={setOption1LinkValue} />
                            )}
                        </div>
                    )}

                    <hr style={{ backgroundColor: '#434257c4' }} />

                    {/* Option 2 */}
                    <h5>Option 2</h5>
                    <div className="col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="selectInput">Option 2 Type</label>
                            <select
                                class="custom-select form-select rounded-0"
                                id="selectInput"
                                onChange={(e) => {
                                    setOption2Type(e.target.value)
                                    onTypeChange(e.target.value, "O2")
                                }}
                            // value={boardName}
                            >
                                <option value="" disabled selected>
                                    select type
                                </option>
                                {typeList.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.value}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {isOption2Type && (
                        <div>
                            {isTypeOption2Text && (
                                <TextComponent textValue={option2TextValue} setTextValue={setOption2TextValue} />
                            )}
                            {isTypeOption2Link && (
                                <LinkComponent linkValue={option2LinkValue} setLinkValue={setOption2LinkValue} />
                            )}
                        </div>
                    )}

                    <hr style={{ backgroundColor: '#434257c4' }} />

                    {/* Option 3 */}
                    <h5>Option 3</h5>
                    <div className="col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="selectInput">Option 3 Type</label>
                            <select
                                class="custom-select form-select rounded-0"
                                id="selectInput"
                                onChange={(e) => {
                                    setOption3Type(e.target.value)
                                    onTypeChange(e.target.value, "O3")
                                }}
                            // value={boardName}
                            >
                                <option value="" disabled selected>
                                    select type
                                </option>
                                {typeList.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.value}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {isOption3Type && (
                        <div>
                            {isTypeOption3Text && (
                                <TextComponent textValue={option3TextValue} setTextValue={setOption3TextValue} />
                            )}
                            {isTypeOption3Link && (
                                <LinkComponent linkValue={option3LinkValue} setLinkValue={setOption3LinkValue} />
                            )}
                        </div>
                    )}

                    <hr style={{ backgroundColor: '#434257c4' }} />

                    {/* Option 4 */}
                    <h5>Option 4</h5>
                    <div className="col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="selectInput">Option 4 Type</label>
                            <select
                                class="custom-select form-select rounded-0"
                                id="selectInput"
                                onChange={(e) => {
                                    setOption4Type(e.target.value)
                                    onTypeChange(e.target.value, "O4")
                                }}
                            // value={boardName}
                            >
                                <option value="" disabled selected>
                                    select type
                                </option>
                                {typeList.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.value}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {isOption4Type && (
                        <div>
                            {isTypeOption4Text && (
                                <TextComponent textValue={option4TextValue} setTextValue={setOption4TextValue} />
                            )}
                            {isTypeOption4Link && (
                                <LinkComponent linkValue={option4LinkValue} setLinkValue={setOption4LinkValue} />
                            )}
                        </div>
                    )}

                    <hr style={{ backgroundColor: '#434257c4' }} />

                    <h5>Answer</h5>
                    <div className="col-md-4 col-sm-12">
                        <div class="form-group">
                            <label for="selectInput">select Answer</label>
                            <select
                                class="custom-select form-select rounded-0"
                                id="selectInput"
                                onChange={(e) => {
                                    setAnswerValue(e.target.value)
                                }}
                                required
                                value={answerValue}
                            >
                                <option value="" disabled selected>
                                    Select an option
                                </option>
                                {answersList.map((option) => (
                                    <option key={option.id} value={option.value}>
                                        {option.value}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <hr style={{ backgroundColor: '#434257c4' }} />

                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<StoreOutlined />}
                                onClick={() => handleSetButton()}
                            >
                                Set MCq
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <div className='content-wrapper'>

                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">MCQ</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">MCQ</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* left column */}
                            <div className="col-md-12 mt-4">
                                {/* general form elements */}
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">{quizId === "" ? "Add" : "Edit"} Quiz</h3>
                                    </div>
                                    <form onSubmit={handleSubmit} enctype="multipart/form-data">
                                        <div className="card-body">
                                            <div className="row">

                                                {quizId === "" &&
                                                    <>
                                                        <div className="col-md-4 col-sm-12">
                                                            <div class="form-group">
                                                                <label for="selectInput">{quizId === "" ? "Select" : "Edit"} Board</label>
                                                                <select
                                                                    class="custom-select form-select rounded-0"
                                                                    id="selectInput"
                                                                    onChange={(e) => {
                                                                        setBoardName(e.target.value)
                                                                        setLanguageName("")
                                                                        setClassName("")
                                                                        onBoardChange(e.target.value)
                                                                    }}
                                                                    required
                                                                    value={boardName}
                                                                    name='board_id'
                                                                >
                                                                    <option value="" disabled selected>
                                                                        Select an option
                                                                    </option>
                                                                    {boardList.map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 col-sm-12">
                                                            <div class="form-group">
                                                                <label for="selectInput">{quizId === "" ? "Select" : "Edit"} Language</label>
                                                                <select
                                                                    class="custom-select form-select rounded-0"
                                                                    id="selectInput"
                                                                    onChange={(e) => {
                                                                        setLanguageName(e.target.value)
                                                                        setClassName("")
                                                                        onLanguageChange(e.target.value)
                                                                    }}
                                                                    required
                                                                    value={languageName}
                                                                    name='language_id'
                                                                >
                                                                    <option value="" disabled selected>
                                                                        Select an option
                                                                    </option>
                                                                    {languageList.map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 col-sm-12">
                                                            <div class="form-group">
                                                                <label for="selectInput">{quizId === "" ? "Select" : "Edit"} Class</label>
                                                                <select
                                                                    class="custom-select form-select rounded-0"
                                                                    id="selectInput"
                                                                    onChange={(e) => {
                                                                        setClassName(e.target.value)
                                                                    }}
                                                                    required
                                                                    value={className}
                                                                    name='class_id'
                                                                >
                                                                    <option value="" disabled selected>
                                                                        Select an option
                                                                    </option>
                                                                    {classList.map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12 col-sm-12">
                                                            <h3>Add Details</h3>
                                                        </div>

                                                        <div className="col-md-5 col-sm-12">
                                                            <div className="form-group">
                                                                <label htmlFor="boardInput">{quizId === "" ? "Add" : "Edit"} Title</label>
                                                                <input
                                                                    type="text"
                                                                    onChange={(e) => setTitle(e.target.value)}
                                                                    className="form-control"
                                                                    placeholder="Title"
                                                                    ref={inputRef}
                                                                    value={title}
                                                                    name='title'
                                                                    required
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-3 col-sm-12">
                                                            <div className="form-group">
                                                                <label htmlFor="boardInput">{quizId === "" ? "Add" : "Edit"} Duration(in minutes)</label>
                                                                <input
                                                                    type="number"
                                                                    onChange={(e) => setDuration(e.target.value)}
                                                                    className="form-control"
                                                                    placeholder="in minute"
                                                                    ref={inputRef}
                                                                    value={duration}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-3 col-sm-12">
                                                            <div class="form-group">
                                                                <label htmlFor="classInput">Choose an image</label>
                                                                <div class="input-group">
                                                                    <input type="file" accept="image/*" ref={imageFileInputRef} onChange={(e) => setImageFile(e.target.files[0])} required={true} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12">
                                                            <div className="form-group">
                                                                <label htmlFor="stateInput">{quizId === "" ? "Add" : "Edit"} Start Date and Time</label>
                                                                <input
                                                                    type="datetime-local"
                                                                    onChange={(e) => setStartTime(e.target.value)}
                                                                    className="form-control"
                                                                    value={startTime}
                                                                    ref={inputRef}
                                                                    min={new Date().toISOString().slice(0, -8)}
                                                                />

                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12">
                                                            <div className="form-group">
                                                                <label htmlFor="stateInput">{quizId === "" ? "Add" : "Edit"} End Date and Time</label>
                                                                <input
                                                                    type="datetime-local"
                                                                    onChange={(e) => setEndTime(e.target.value)}
                                                                    className="form-control"
                                                                    value={endTime}
                                                                    ref={inputRef}
                                                                />

                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {/* Questions */}
                                                <div className="col-md-12 col-sm-12">
                                                    <h4 id="editpanel" ref={inputRef}>Questions</h4>
                                                </div>
                                                <div className="col-md-8 col-sm-12" id="editpanel" ref={inputRef}>
                                                    {mcqView === false && (
                                                        excelFile === null && questions.length === 0 ?
                                                            (
                                                                <> <Button
                                                                    variant="contained"
                                                                    style={{
                                                                        backgroundColor: 'green',
                                                                        marginRight: 10,
                                                                        color: 'white'
                                                                    }}
                                                                    startIcon={<Add />}
                                                                    onClick={() => setOpenAdd(true)}
                                                                >
                                                                    ADD
                                                                </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        startIcon={<FileUploadRoundedIcon />}
                                                                        onClick={() => setOpen(true)}
                                                                    >
                                                                        Upload Excel
                                                                    </Button>
                                                                </>
                                                            )
                                                            :

                                                            excelFile === null ?
                                                                <Button
                                                                    variant="contained"
                                                                    style={{
                                                                        backgroundColor: 'green',
                                                                        marginRight: 10,
                                                                        color: 'white'
                                                                    }}
                                                                    startIcon={<Add />}
                                                                    onClick={() => setOpenAdd(true)}
                                                                >
                                                                    ADD
                                                                </Button>
                                                                :
                                                                <>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        startIcon={<FileUploadRoundedIcon />}
                                                                        onClick={() => setOpen(true)}
                                                                    >
                                                                        Upload Excel
                                                                    </Button>
                                                                    <CheckCircleOutline style={{ color: 'green', fontSize: 30, marginLeft: 10 }} />
                                                                </>
                                                    )
                                                    }

                                                </div>

                                                <div className="col-md-8 col-sm-12">
                                                    {questions.map((question, index) => (
                                                        <div key={index}>
                                                            <span style={{ color: '#5656ce' }}>&nbsp;{mcqView && <DeleteOutline onClick={() => deleteQuestion(question.question_id)} />}</span>
                                                            {question.question[0].question_type === '1' ? (
                                                                <div className='question-style'><span className='font-weight-bold'>{index + 1}  Question:</span> Text: {question.question[0].question_type_text}</div>
                                                            ) : question.question[0].question_type === '2' ? (
                                                                <div className='question-style'><span className='font-weight-bold'>{index + 1}  Question:</span> Link: {question.question[0].question_type_link}</div>
                                                            ) : (
                                                                <>
                                                                    <div className='question-style'><span className='font-weight-bold'>{index + 1}  Question:</span> </div>
                                                                    <div className='container ml-4'>
                                                                        <div className='question-style'> Text: {question.question[0].question_type_text}</div>
                                                                        <div className='question-style'> Link: {question.question[0].question_type_link}</div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            <div className='container'>
                                                                {question.options.map((option, optionIndex) => (
                                                                    <div key={optionIndex}>

                                                                        {option[`option${optionIndex + 1}_type`] === '1' ? (
                                                                            <div className='option-style'><span className='font-weight-bold'>Option {optionIndex + 1}</span> Text: {option[`option${optionIndex + 1}_type_text`]}</div>
                                                                        ) : option[`option${optionIndex + 1}_type`] === '2' ? (
                                                                            <div className='option-style'><span className='font-weight-bold'>Option {optionIndex + 1}</span> Link: {option[`option${optionIndex + 1}_type_link`]}</div>
                                                                        ) : (
                                                                            <>
                                                                                <div className='option-style font-weight-bold'>Option 3: </div>
                                                                                <div className='container'>
                                                                                    <div className='option-style'>Text: {option[`option${optionIndex + 1}_type_text`]}</div>
                                                                                    <div className='option-style'>Link: {option[`option${optionIndex + 1}_type_link`]}</div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className='answer-style'><span className='font-weight-bold'>Answer:</span> {question.answer}</div>
                                                            <hr />
                                                        </div>
                                                    ))}
                                                </div>


                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary">{quizId === "" ? "Submit" : "Update"}</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (right) */}
                    </div >
                    {/* /.row */}
                </section >

                <section className="content mt-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 mt-4">

                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4 >Filter</h4>
                                    </div>

                                    <form onSubmit={handleFilter} ref={formRef}>
                                        <div className="card-body">
                                            <div className='row'>

                                                <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">Select Board</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setBoardName(e.target.value)
                                                                setBoardNameFilter(e.target.value)
                                                                setLanguageName("")
                                                                setClassName("")
                                                                onBoardChange(e.target.value)
                                                            }}
                                                            required
                                                            value={boardNameFilter}
                                                            name='board_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {boardList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                 <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">Select Language</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setLanguageName(e.target.value)
                                                                setLanguageNameFilter(e.target.value)
                                                                setClassName("")
                                                                onLanguageChange(e.target.value)
                                                            }}
                                                            required
                                                            value={languageNameFilter}
                                                            name='language_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {languageList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-5 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">Select Class</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setClassName(e.target.value)
                                                                setClassNameFilter(e.target.value)
                                                            }}
                                                            required
                                                            value={classNameFilter}
                                                            name='class_id'
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {classList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-2 col-sm-12 d-flex justify-content-center align-items-center">
                                                    <button type="submit" className="btn btn-primary">Filter</button>
                                                </div>

                                            </div>
                                        </div>

                                    </form>

                                    <ThemeProvider theme={defaultMaterialTheme}>
                                        <MaterialTable
                                            icons={tableIcons}
                                            title="MCQ"
                                            columns={columns}
                                            data={data}
                                            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                            options={{
                                                exportButton: true,
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    backgroundColor: 'rgb(0 123 255)',
                                                    color: '#FFF',
                                                    fontSize: 18
                                                },
                                                rowStyle: (rowData, index) => ({
                                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                                }),
                                            }}
                                            actions={[
                                                {
                                                    icon: () => <span style={{ color: '#5656ce' }}><Add /></span>,
                                                    tooltip: 'Add',
                                                    onClick: (event, rowData) => handleEdit(rowData),
                                                },
                                                rowData => ({
                                                    icon: () => <span style={{ color: '#5656ce' }}><Visibility /></span>,
                                                    tooltip: 'View',
                                                    onClick: (event, rowData) => viewSetQuestions(rowData.id),
                                                }),
                                            ]}
                                        />
                                    </ThemeProvider>

                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </div >
        </>
    )
}

export default Quiz