import React, { useEffect, useRef, useState } from 'react'
import { getApiCall, postApiCall, postFormApiCall } from '../../utils/api';
import MaterialTable from 'material-table';
import tableIcons from '../component/MaterialIcon';
import { ThemeProvider, createTheme } from '@mui/material';
import { Delete, Edit } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link, useParams } from 'react-router-dom';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

const PackageData = () => {

    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);
    const { id } = useParams();
    const [data, setData] = useState([])
    const [packageID, setPackageID] = useState("")
    const [loading, setLoading] = useState(false)

    const [boardName, setBoardName] = useState("")
    const [className, setClassName] = useState("")
    const [subjectName, setSubjectName] = useState("")
    const [chapterName, setChapterName] = useState("")
    const [medium, setMedium] = useState("")
    const [moduleName, setModuleName] = useState("")

    // list items
    const [boardList, setBoardList] = useState([])
    const [mediumList, setMediumList] = useState([])
    const [classList, setClassList] = useState([])
    const [subjectList, setSubjectList] = useState([])
    const [chapterList, setChapterList] = useState([])
    const [modulesList, setModuleList] = useState([])


    const [contentList, setContentList] = useState([])
    const [checkedItems, setCheckedItems] = useState([]);

    const [packageName, setPackageName] = useState([]);

    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        setLoading(true)
        const packageName = await postApiCall("packages/get/byId", { "id": id })
        setPackageName(packageName.data[0].name);

        const boardres = await getApiCall("board/get/all")
        setBoardList(boardres.data);

        const res = await postApiCall("packageData/get/byId", { "package_id": id })
        setData(res.data);
        
        if(data.length !== 0){
            res.data.forEach((data, index) => { data.serial = index + 1; });
        }
        
        setLoading(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        let res = {}
        if (packageID === "") {
            for (let i = 0; i < checkedItems.length; i++) {
                res = await postApiCall("packageData/add", {
                    'package_id': id,
                    'board_id': boardName,
                    'medium_id': medium,
                    'class_id': className,
                    'subject_id': subjectName,
                    'chapter_id': chapterName,
                    'module_type': moduleName,
                    'data_id': checkedItems[i],
                })
            }
        } else {
            res = await postFormApiCall("packages/update", {
                'id': packageID,
            })

        }
        setLoading(false)
        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });

        setPackageID("")
        setCheckedItems([])
        setModuleName("")
        setContentList([])
        fetchData()
    }

    // set medium
    const onBoardChange = async (val) => {
        setLoading(true)
        const mediumRes = await postApiCall("language/get/byBoard", { "id": val })
        setMediumList(mediumRes.data);
        setLoading(false)
    }

    //set class
    const onMediumChange = async (val) => {
        setLoading(true)
        const classResponse = await postApiCall("class/get/byBoardAndMedium", { "board_id": boardName, "medium_id": val, })
        setClassList(classResponse.data);
        setLoading(false)
    }

    //set Subject
    const onClassChange = async (val) => {
        setLoading(true)
        const subjectResponse = await postApiCall("subject/get/byBoardClassAndMedium", { "board_id": boardName, "class_id": val, "medium_id": medium, })
        setSubjectList(subjectResponse.data);
        setLoading(false)
    }

    //set chapter
    const onSubjectChange = async (val) => {
        setLoading(true)
        const chapterRes = await postApiCall("chapter/get/name/byBoardMediumClassAndSubject", { "board_id": boardName, "class_id": className, "language_id": medium, "subject_id": val })
        setChapterList(chapterRes.data);
        setLoading(false)
    }

    //get modules
    const getModulesOnCLassChange = async (val) => {
        setLoading(true)

        const moduleResponse = await postApiCall("module/get/forPackage", {
            "package_id": id
        })
        setModuleList(moduleResponse.data);
        setLoading(false)
    }

    //get dataList
    const onModuleChange = async (val) => {
        // console.log(boardName, medium, className, subjectName, chapterName, val);
        setLoading(true)

        let response = {}
        if (val === '2') {
            response = await postApiCall("ebook/get/byBoradMediumClassSubjectAndChapter", {
                "board_id": boardName,
                "language_id": medium,
                "class_id": className,
                "subject_id": subjectName,
                "chapter_id": chapterName,
            })
        } else if (val === '3') {
            response = await postApiCall("video/get/byBoradMediumClassSubjectAndChapter", {
                "board_id": boardName,
                "language_id": medium,
                "class_id": className,
                "subject_id": subjectName,
                "chapter_id": chapterName,
            })
        }
        else if (val === '4') { //mcq mcq
            response = await postApiCall("mcq/get/mcqSet/byBoradMediumClassSubjectAndChapter", {
                "set_for": '1',
                "board_id": boardName,
                "language_id": medium,
                "class_id": className,
                "subject_id": subjectName,
                "chapter_id": chapterName,
            })
        }
        else if (val === '6') { //math mcq
            response = await postApiCall("mcq/get/mcqSet/byBoradMediumClassSubjectAndChapter", {
                "board_id": boardName,
                "language_id": medium,
                "class_id": className,
                "subject_id": subjectName,
                "chapter_id": chapterName,
                "set_for": '2',
            })
        }
        else if (val === '7') { //grammer mcq
            response = await postApiCall("mcq/get/mcqSet/byBoradMediumClassSubjectAndChapter", {
                "board_id": boardName,
                "language_id": medium,
                "class_id": className,
                "subject_id": subjectName,
                "chapter_id": chapterName,
                "set_for": '3',
            })
        }
        else if (val === '8') { //grammer mcq
            response = await postApiCall("modelQA/get/model/byBoradMediumClassSubjectAndChapter", {
                "board_id": boardName,
                "language_id": medium,
                "class_id": className,
                "subject_id": subjectName,
                "chapter_id": chapterName,
            })
        }
        setLoading(false)
        if (response.error === true) {
            return Swal.fire({
                title: 'Error',
                text: response.message,
                icon: 'error'
            });
        }

        setContentList(response.data);

    }

    const handleCheckboxChange = (event, itemId) => {
        if (event.target.checked) {
            setCheckedItems([...checkedItems, itemId]);
        } else {
            setCheckedItems(checkedItems.filter((id) => id !== itemId));
        }
    };

    const handleEdit = async (rowData) => {
        setPackageID(rowData.id)
        setBoardName(rowData.board_name)
        setMedium(rowData.medium_id)
        setClassName(rowData.class_id)
        setSubjectName(rowData.subject_id)
        setChapterName(rowData.chapter_id)

        inputRef.current.focus();
        const input = document.getElementById('boardInput');
        input.classList.add('glow');
        input.focus();
        setTimeout(() => {
            input.classList.remove('glow');
        }, 1000);
    }

    const columns = [
        { title: "Sl. no", field: "serial" },
        {
            title: "Module", field: "module_name",
            render: (rowData) => {
                return (
                    <>
                        <div>{rowData.module_name}</div>
                        <div>{rowData.includesEBook === false ? <span style={{color: "red"}}>No Data</span> : null}</div>
                    </>
                )
            }
        },
        { title: "Board", field: "board_name" },
        { title: "Medium", field: "language_name" },
        { title: "Class", field: "class_name" },
        { title: "Subject", field: "subject_name" },
        { title: "Chapter", field: "chapter_name" },
    ]

    const handleDelete = async (rowData) => {
        new Swal({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await postApiCall("packageData/delete", { "id": rowData.id })
                if (res.error === true) {
                    return Swal.fire({
                        title: 'Error',
                        text: res.message,
                        icon: 'error'
                    });
                }
                Swal.fire({
                    title: 'Successful',
                    text: res.message,
                    icon: 'success'
                });
                fetchData()
            }
        })

    }

    return (

        <div>
            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
            <div className='content-wrapper'>

                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">Package: {packageName}</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Package</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* left column */}
                            <div className="col-md-12 mt-4">
                                {/* general form elements */}
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">{packageID === "" ? "Add" : "Edit"} Package Data</h3>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{packageID === "" ? "Select" : "Edit"} Board</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setBoardName(e.target.value)
                                                                onBoardChange(e.target.value)

                                                                setMedium("")
                                                                setClassName("")
                                                                setSubjectName("")
                                                                setChapterName("")

                                                            }}
                                                            required
                                                            value={boardName}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {boardList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{packageID === "" ? "Select" : "Edit"} Medium</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setMedium(e.target.value)
                                                                onMediumChange(e.target.value)

                                                                setClassName("")
                                                                setSubjectName("")
                                                                setChapterName("")
                                                            }}
                                                            required
                                                            value={medium}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {mediumList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{packageID === "" ? "Select" : "Edit"} Class</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setClassName(e.target.value)
                                                                onClassChange(e.target.value)

                                                                setSubjectName("")
                                                                setChapterName("")
                                                            }}
                                                            required
                                                            value={className}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {classList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{packageID === "" ? "Select" : "Edit"} Subject</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setSubjectName(e.target.value)
                                                                onSubjectChange(e.target.value)

                                                                setChapterName("")
                                                            }}
                                                            required
                                                            value={subjectName}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {subjectList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{packageID === "" ? "Select" : "Edit"} Chapter</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setChapterName(e.target.value)
                                                                getModulesOnCLassChange(e.target.value)
                                                            }}
                                                            required
                                                            value={chapterName}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {chapterList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="selectInput">{packageID === "" ? "Select" : "Edit"} Module</label>
                                                        <select
                                                            class="custom-select form-select rounded-0"
                                                            id="selectInput"
                                                            onChange={(e) => {
                                                                setModuleName(e.target.value)
                                                                onModuleChange(e.target.value)
                                                            }}
                                                            required
                                                            value={moduleName}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select an option
                                                            </option>
                                                            {modulesList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                {/* data list */}
                                                {contentList.length !== 0 && (
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className='content-data-heading'>Select the content to add:</div>
                                                        <div className='container-content-list'>

                                                            {contentList.map((item) => (
                                                                <div key={item.id}>
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={checkedItems.includes(item.id)}
                                                                            onChange={(event) => handleCheckboxChange(event, item.id)}
                                                                        />
                                                                    </label>
                                                                    <span className='data-list'>{item.title}</span>
                                                                    <hr />
                                                                </div>
                                                            ))}
                                                        </div>

                                                        <div>
                                                            Checked items: {checkedItems.join(', ')}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="card-footer">
                                            <button disabled={checkedItems.length !== 0 ? false : true} type="submit" className="btn btn-primary">{packageID === "" ? "Submit" : "Update"}</button>
                                        </div>
                                    </form>

                                </div>

                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (right) */}
                    </div>
                    {/* /.row */}
                </section >

                <section className="content mt-4">
                    <div className="container-fluid">
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable
                                icons={tableIcons}
                                title="Packages"
                                columns={columns}
                                data={data}
                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                options={{
                                    exportButton: true,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        backgroundColor: 'rgb(0 123 255)',
                                        color: '#FFF',
                                        fontSize: 18
                                    },
                                    rowStyle: (rowData, index) => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                    }),
                                }}
                                actions={[
                                    // {
                                    //     icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                                    //     tooltip: 'Edit',
                                    //     onClick: (event, rowData) => handleEdit(rowData),
                                    // },
                                    rowData => ({
                                        icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                                        tooltip: 'Delete',
                                        onClick: (event, rowData) => handleDelete(rowData),
                                    })
                                ]}
                            />
                        </ThemeProvider>
                    </div>
                </section>

            </div>
        </div>
    )
}

export default PackageData