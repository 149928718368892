import React from 'react'

const TextComponent = ({ textValue, setTextValue }) => {
    const handleOnChange = (event) => {
        setTextValue(event.target.value);
    };

    return (
        <div className="row">
            <div className="col-md-7 col-sm-12">
                <div className="form-group">
                    <textarea
                        onChange={handleOnChange}
                        className="form-control"
                        placeholder="Enter Text"
                        value={textValue}
                    />
                </div>
            </div>
        </div>
    )
}

export default TextComponent