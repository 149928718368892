
import { Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import User from "./pages/User";
import LoggeIn from "./components/LoggeIn";
import jwt_decode from 'jwt-decode';
import Module from "./pages/packages/Module";
import Packages from "./pages/packages/Packages";
import Gallery from "./pages/mainContent/Gallery";
import PackageData from "./pages/packages/PackageData";
import LiveClass from "./pages/liveclass/LiveClass";
import Language from "./pages/basicContent/Language";
import State from "./pages/basicContent/State";
import Board from "./pages/basicContent/Board";
import Stream from "./pages/basicContent/Stream";
import Classes from "./pages/basicContent/Classes";
import Subject from "./pages/basicContent/Subject";
import Chapter from "./pages/basicContent/Chapter";
import EBook from "./pages/mainContent/EBook";
import Videos from "./pages/mainContent/Videos";
import Initials from "./pages/mainContent/Initials";
import Dictionary from "./pages/mainContent/Dictionary";
import SpokenAlphabet from "./pages/mainContent/SpokenAlphabet";
import SpokenSentence from "./pages/mainContent/SpokenSentence";
import SpokenWord from "./pages/mainContent/SpokenWord";
import Mcq from "./pages/mainContent/Mcq";
import ModelQA from "./pages/mainContent/ModelQA";
import Page404 from "./pages/Page404";
import Banner from "./pages/Banner";
import Login from "./components/adminLogin/Login";
import Feeds from "./pages/feed/Feeds";
import Discount from "./pages/Discount";
import Notification from "./pages/Notification";
import Quiz from "./pages/mainContent/Quiz";
import QuizImage from "./pages/mainContent/QuizImage";
import Privacy from "./pages/Privacy";
import DeleteAccount from "./pages/DeleteAccount";

function App() {

  const logCheck = localStorage.getItem("data");
  let decoded_data = ""
  if (logCheck != null) {
    decoded_data = jwt_decode(logCheck)
  }
  return (
    <>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/deletemyaccount' element={<DeleteAccount />} />
        {logCheck !== null ?
          <>
            <Route path="/*" element={<LoggeIn />}>
              {/* Add your nested routes inside the LoggeIn component here */}
              <Route index element={<Dashboard />} />
              <Route path="users" element={<User />} />
              <Route path="notification" element={<Notification />} />
              <Route path="modules" element={<Module />} />
              <Route path="packages" element={<Packages />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="banner" element={<Banner />} />
              <Route path=":id" element={<PackageData />} />
              <Route path="discount" element={<Discount />} />

              <Route path='liveclass' element={<LiveClass />} />
              <Route path='feeds' element={<Feeds />} />

              <Route path='language' element={<Language />} />
              <Route path='state' element={<State />} />
              <Route path='board' element={<Board />} />
              <Route path='stream' element={<Stream />} />
              <Route path='class' element={<Classes />} />
              <Route path='subject' element={<Subject />} />
              <Route path='chapter' element={<Chapter />} />
              <Route path='ebook' element={<EBook />} />

              <Route path='videos' element={<Videos />} />
              <Route path='initials' element={<Initials />} />
              <Route path='dictionary' element={<Dictionary />} />
              <Route path='spoken-alphabet' element={<SpokenAlphabet />} />
              <Route path='spoken-sentence' element={<SpokenSentence />} />
              <Route path='spoken-word' element={<SpokenWord />} />

              <Route path='mcq' element={<Mcq />} />
              <Route path='modelqa' element={<ModelQA />} />
              <Route path='quiz' element={<Quiz />} />
              <Route path='quizimage' element={<QuizImage />} />
            </Route>
          </>
          :
          <Route path='/' element={<Login />} />

        }

        <Route path="*" element={<Page404 />} />
      </Routes>

    </>
  );
}

export default App;
