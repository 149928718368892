import React, { useEffect, useRef, useState } from 'react'
import MaterialTable from 'material-table';
import { ThemeProvider, createTheme } from '@mui/material';
import { Edit } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { MAIN_URL, getApiCall, postFormApiCall } from '../utils/api';
import tableIcons from './component/MaterialIcon';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

const Banner = () => {

    const defaultMaterialTheme = createTheme();
    const [selectedRow, setSelectedRow] = useState(null);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false)

    const [isChecked, setIsChecked] = useState(true);

    const [data, setData] = useState([])
    const [bannerName, setBannerName] = useState("")

    const [bannerId, setBannerId] = useState("")
    const [imageFile, setImageFile] = useState(null)

    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        setLoading(true)
        const res = await getApiCall("banner/get/all")
        setData(res.data);
        res.data.forEach((data, index) => { data.serial = index + 1; });
        setLoading(false)
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const formData = new FormData();

        formData.append('id', bannerId);
        formData.append('name', bannerName);
        formData.append('status', isChecked);
        formData.append('banner_img', imageFile);

        let res = {}
        if (bannerId === "") {
            res = await postFormApiCall("banner/add", formData)
        } else {
            res = await postFormApiCall("banner/update", formData)
        }
        setLoading(false)

        if (res.error === true) {
            return Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
        Swal.fire({
            title: 'Successful',
            text: res.message,
            icon: 'success'
        });

        setBannerId("")
        setBannerName("")
        setImageFile(null)
        fetchData()
    }

    const columns = [
        { title: "Sl. no", field: "serial" },
        {
            title: "Image", field: "banner_img",
            render: (rowData) => {
                return (
                    <>
                        <img src={MAIN_URL + rowData.banner_img} height={50} width={50} /></>
                )
            }
        },
        { title: "Name", field: "name" },
        {
            title: "Status", field: "status",
            render: (rowData) => {
                return (
                    <>
                        {rowData.status === true ? "Active" : "InActive"}
                    </>
                )
            }
        },
    ]

    const handleEdit = async (rowData) => {
        setBannerId(rowData.id)
        setBannerName(rowData.name)
        setIsChecked(rowData.status)

        inputRef.current.focus();
        const input = document.getElementById('stateInput');
        input.classList.add('glow');
        input.focus();
        setTimeout(() => {
            input.classList.remove('glow');
        }, 1000);
    }

    return (
        <div>
            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
            <div className='content-wrapper'>

                <div className="content-header" style={{ backgroundColor: "#faebd7" }}>
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="m-0">Banner</h4>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Banner</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>


                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">{bannerId === "" ? "Add" : "Edit"} Banner</h3>
                                    </div>

                                    <form onSubmit={handleSubmit}>
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="boardInput">{bannerId === "" ? "Add" : "Edit"} Title</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => { setBannerName(e.target.value) }}
                                                            className="form-control"
                                                            id="boardInput"
                                                            placeholder="Enter Banner Title"
                                                            value={bannerName}
                                                            ref={inputRef}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div class="form-group">
                                                        <label htmlFor="classInput">{bannerId === "" ? "Add" : "Edit"} Banner Image</label>
                                                        <div class="input-group">
                                                            <input type="file" accept="image/*" onChange={(e) => setImageFile(e.target.files[0])} required={bannerId === "" ? true : false} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 col-sm-12">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isChecked}
                                                            onChange={(e) => setIsChecked(e.target.checked)}
                                                        />
                                                        <span className='active-status'>Active</span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary">{bannerId === "" ? "Submit" : "Update"}</button>
                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>
                </section >

                <section className="content">
                    <div className="container-fluid">
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable
                                icons={tableIcons}
                                title="States"
                                columns={columns}
                                data={data}
                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                options={{
                                    exportButton: true,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        backgroundColor: 'rgb(0 123 255)',
                                        color: '#FFF',
                                        fontSize: 18
                                    },
                                    rowStyle: (rowData, index) => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#d3d4d1' : index % 2 !== 0 ? '#EEE' : '#FFF'
                                    }),
                                }}
                                actions={[
                                    {
                                        icon: () => <span style={{ color: '#5656ce' }}><Edit /></span>,
                                        tooltip: 'Edit',
                                        onClick: (event, rowData) => handleEdit(rowData),
                                    },
                                    // rowData => ({
                                    //     icon: () => <span style={{ color: '#5656ce' }}><Delete /></span>,
                                    //     tooltip: 'Delete',
                                    //     onClick: (event, rowData) => handleDelete(rowData),
                                    // })
                                ]}
                            />
                        </ThemeProvider>
                    </div>
                </section>

            </div>
        </div>
    )
}

export default Banner