
import jwt_decode from 'jwt-decode';

// export const MAIN_URL = "http://localhost:5000";

const currentPath = window.location.pathname;
const pathWithoutLeadingSlash = currentPath.startsWith('/') ? currentPath.slice(1) : currentPath;
const urlWithoutQueryParams = window.location.href.split('?')[0];
const url = urlWithoutQueryParams.replace(pathWithoutLeadingSlash, '');

export const MAIN_URL = url;


// const BASE_URl = "http://localhost:5000/api/";

const BASE_URl = window.location.href.startsWith("http://localhost")
    ? "http://localhost:5000/api/"
    : "api/";

const token = localStorage.getItem("data");

async function checkExpirationToken() {

    let decoded_data = ""
    if (token != null) {
        decoded_data = jwt_decode(token)
    }
    const currentTimestamp = Math.floor(Date.now() / 1000);

    if (decoded_data.exp < currentTimestamp) {
        localStorage.removeItem("data");
        window.location.href = "/";
    } else {
        return false;
    }

}

// Login
export const loginApiCall = async (apiEndpoints, sendData) => {

    return await fetch(BASE_URl + apiEndpoints, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(sendData)
    })
        .then(async response => {
            // console.log(response);
            if(response.status === 502){
                console.log("Status Code: 502");
            }if (response.status !== 200) {
                throw new Error("Not 200");
            } else {
                return await response.json();
            }
        })
        .then(data => {
            // console.log("DATA", data);
            return data;
        })
        .catch(err => {
            console.log(err);
            throw err;
        });
}


export const getApiCall = async (apiEndpoints) => {
    if (await checkExpirationToken() === false) {
        return await fetch(BASE_URl + apiEndpoints, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        })
            .then(async response => {
                // console.log(response);
                if(response.status === 502){
                    console.log("Status Code: 502");
                }if (response.status !== 200) {
                    throw new Error("Not 200");
                } else {
                    return await response.json();
                }
            })
            .then(data => {
                // console.log("DATA", data);
                return data;
            })
            .catch(err => {
                console.log(err);
                throw err;
            });
    }

}

export const getApiCallForVideo = async (apiEndpoints) => {
    if (await checkExpirationToken() === false) {
        return await fetch(BASE_URl + apiEndpoints, {
            method: "GET",
            headers: {
                'Content-Type': 'video/mp4',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(async response => {
                // console.log(response);
                return response;
            })
            .then(data => {
                // console.log("DATA", data);
                return data;
            })
            .catch(err => {
                console.log(err);
                throw err;
            });
    }
}

export const postApiCall = async (apiEndpoints, sendData) => {
    if (await checkExpirationToken() === false) {
        return await fetch(BASE_URl + apiEndpoints, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(sendData)
        })
            .then(async response => {
                // console.log(response);
                if(response.status === 502){
                    console.log("Status Code: 502");
                }if (response.status !== 200) {
                    throw new Error("Not 200");
                } else {
                    return await response.json();
                }
            })
            .then(data => {
                // console.log("DATA", data);
                return data;
            })
            .catch(err => {
                console.log(err);
                throw err;
            });
    }
}

export const postFormApiCall = async (apiEndpoints, sendData) => {
    if (await checkExpirationToken() === false) {
        return await fetch(BASE_URl + apiEndpoints, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token}`
            },
            body: sendData
        })
            .then(async response => {
                // console.log(response);
                if(response.status === 502){
                    console.log("Status Code: 502");
                }if (response.status !== 200) {
                    throw new Error("Not 200");
                } else {
                    return await response.json();
                }
            })
            .then(data => {
                // console.log("DATA", data);
                return data;
            })
            .catch(err => {
                console.log(err);
                throw err;
            });
    }
}